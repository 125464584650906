import React from 'react';
import './tests-upload-form.css';
import { FormControl, InputLabel, MenuItem, Select, IconButton,TextField } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const TestsUploadForm = (props) => {

    const [selectedMember, setSelectedMember] = React.useState("");
    const [testName, setTestName] = React.useState("");
    const [testResult, setTestResult] = React.useState("");
    const [testDate, setTestDate] = React.useState(null);

    const handleTestDateChange = (e) => {
        setTestDate(e);
    }

    return (
        <div className="tests-upload-form-container">
            <p className='form-header'>TESTS UPLOAD</p>
            <div className='tests-upload-form-content'>
                <section className="tests-upload-form column-section">
                    <div className="mui-form-control">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="select-family-member">Select Family Member *</InputLabel>
                            <Select
                                id="select-family-member"
                                value={selectedMember}
                                onChange={(e) => { setSelectedMember(e.target.value) }}
                                label="Select Family Member"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="1">John Smith</MenuItem>
                                <MenuItem value="2">Rose Smith</MenuItem>
                                <MenuItem value="3">Kelsey Smith</MenuItem>
                                <MenuItem value="4">Aiden Smith</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="mui-form-control">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="select-test-type">Test Name *</InputLabel>
                            <Select
                                id="select-test-type"
                                value={testName}
                                onChange={(e) => { setTestName(e.target.value) }}
                                label="Select Test"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="1">Test 1</MenuItem>
                                <MenuItem value="2">Test 2</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="mui-form-control">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="select-test-result">Test Result *</InputLabel>
                            <Select
                                id="select-test-result"
                                value={testResult}
                                onChange={(e) => { setTestResult(e.target.value) }}
                                label="Select test result"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="1">Positive</MenuItem>
                                <MenuItem value="2">Negative</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className='tests-upload-section'>
                        <span>Upload proof of tests: </span>
                        <IconButton color="primary" aria-label="upload picture" component="label">
                            <input hidden accept="*/*" type="file" />
                            <PhotoCamera />
                        </IconButton>
                    </div>
                    <div className="mui-form-control mui-date-control">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                label="Test Date (MM/DD/YYYY)*"
                                value={testDate}
                                onChange={handleTestDateChange}
                                renderInput={(params) => <TextField {...params} />}
                                closeOnSelect={true}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='action-btn right-align'>
                        <button className='default-btn'>Upload</button>
                    </div>
                </section>
                <section className="tests-uploaded-data">
                    <table className='vaccination-data-table'>
                        <thead>
                            <tr>
                                <th>Test Name</th>
                                <th>Result</th>
                                <th>Date (MM/DD/YYYY)</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Test 1</td>
                                <td>Negative</td>
                                <td>01/01/2022</td>
                                <td>
                                    <div className="icons-cell">
                                        <img src="/assets/icons/gallery.png" alt="view" />
                                        <img src="/assets/icons/edit-note.png" alt="edit" />
                                        <img src="/assets/icons/close-icon.png" alt="close" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </div>
    )
}

export default TestsUploadForm;