import React from 'react';
import HelpChat from './help-chat/help-chat';
import './help.css';

const Help = (props) => {
    const [showHelpChat, setHelpChatFlag] = React.useState(false);

    const toggleHelpChat = () => {
        setHelpChatFlag(!showHelpChat);
    }

    return (
        <div className="help-component">
            <div className="help-header-section">
                <p className='help-header'>HELP</p>
            </div>
            <div className="help-contents">
                <div className="help-column">
                    <h2 className="column-title">TUTORIAL</h2>
                    <ul className="tutorial-items-list">
                        <li>
                            <p>Create User / Create Profile / Add Family</p>
                            <img src="/assets/icons/play-btn.png" alt="icon" />
                        </li>
                        <li>
                            <p>Travel Documents</p>
                            <img src="/assets/icons/play-btn.png" alt="icon" />
                        </li>
                        <li>
                            <p>Health Documents</p>
                            <img src="/assets/icons/play-btn.png" alt="icon" />
                        </li>
                        <li>
                            <p>Travel Insurancey</p>
                            <img src="/assets/icons/play-btn.png" alt="icon" />
                        </li>
                        <li>
                            <p>Government Approval</p>
                            <img src="/assets/icons/play-btn.png" alt="icon" />
                        </li>
                        <li onClick={() => { toggleHelpChat() }}>
                            <p>Others</p>
                            <img src="/assets/icons/play-btn.png" alt="icon" />
                        </li>
                    </ul>
                    {showHelpChat ? <HelpChat onCloseBtnClick={toggleHelpChat} /> : ''}
                </div>

                <div className="help-column">
                    <h2 className="column-title">FAQS</h2>
                    
                    <ul className="faqs-items-list">
                        <li>
                            <p>Section 1</p>
                        </li>
                        <li>
                            <p>Section 2</p>
                        </li>
                        <li>
                            <p>Section 3</p>
                        </li>
                        <li>
                            <p>Section 4</p>
                        </li>
                        <li>
                            <p>Section 5</p>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

    )
}

export default Help;
