import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, Input, InputLabel } from '@mui/material';

import './shopping-items-results.css';

const ShoppingItemResults = (props) => {
    const navigate = useNavigate();
    const [searchResults, setSearchResults] = React.useState([
        {
            id: 1,
            name: "Jalepeno Cheeseburger",
            price: 12,
            count: 0,
            image: "/assets/images/market-place/shopping-app-images/Jalapeño Burger.jpg",
            desc: "Our Jalapeño Cheeseburger comes with Tomato, Onion, Special Sauce - Plus Mayo.",
            instructions: ''
        },
        {
            id: 2,
            name: "Bacon Cheeseburger",
            price: 15,
            count: 0,
            image: "/assets/images/market-place/shopping-app-images/Bacon Cheeseburger.jpg",
            desc: "Our Bacon Cheeseburger comes with Tomato, Onion, Special Sauce - Plus Mayo, Mustard or Ketchup.",
            instructions: ''
        },
        {
            id: 3,
            name: "Mushroom Burger",
            price: 15,
            count: 0,
            image: "/assets/images/market-place/shopping-app-images/Mushroom Burger.jpg",
            desc: "Our Mushroom Burger comes with Cheese and our Special Sauce - Plus Mayo, Mustard or Ketchup.",
            instructions: ''
        },
        {
            id: 4,
            name: "Barbeque Bacon Burger",
            price: 12,
            count: 0,
            image: "/assets/images/market-place/shopping-app-images/Barbeque Burger.jpg",
            desc: "Our Barbeque Bacon Burger comes is oozing with our special homemad BBQ sauce.",
            instructions: ''
        },
        {
            id: 5,
            name: "Vegetarian Burger",
            price: 14,
            count: 0,
            image: "/assets/images/market-place/shopping-app-images/Vegetarian Burger.jpg",
            desc: "Our Vegetarian Burger is made from chickpeas and lentil and is topped with blue cheese and sundried tomatoes and comes with our special sauce.",
            instructions: ''
        }
    ]
    );

    const increamentCount = (item, index) => {
        var tempSearchResults = [...searchResults];
        tempSearchResults[index]["count"] = tempSearchResults[index]["count"] + 1;
        setSearchResults(tempSearchResults);
    }

    const decreamentCount = (item, index) => {
        var tempSearchResults = [...searchResults];
        if (tempSearchResults[index]["count"] > 0) {
            tempSearchResults[index]["count"] = tempSearchResults[index]["count"] - 1;
            setSearchResults(tempSearchResults);
        }
    }

    const updateSpecialInstructions = (item, index, value) => {
        var tempSearchResults = [...searchResults];
        tempSearchResults[index]["instructions"] = value;
        setSearchResults(tempSearchResults);
    }

    return (
        <div className="shopping-item-results-component">
            <div className="shopping-item-results-contents">
                <div className="shopping-item-results-tabs all-results-tabs">
                    <h3 className="nav-tab-header left-align">SEARCH RESULTS</h3>
                    <div className="shopping-items-list">
                        {
                            searchResults.map((eachItem, index) => {
                                return (
                                    <div className="each-menu-item slide-up" key={index}>
                                        <div className="menu-img-container">
                                            <img src={eachItem.image} alt="menu-item" className="menu-img" />
                                        </div>
                                        <div className="menu-item-contents">
                                            <h3 className="menu-item-title">
                                                {eachItem.name} - ${eachItem.price}
                                            </h3>
                                            <p className="menu-item-desc">
                                                {eachItem.desc}
                                            </p>
                                            <div className="item-instructions-input">
                                                <div className="mui-form-control">
                                                    <FormControl variant="standard" fullWidth>
                                                        <InputLabel htmlFor={"special-instructions-id-" + eachItem.id}>Special Instructions</InputLabel>
                                                        <Input
                                                            id={"special-instructions-id-" + eachItem.id}
                                                            type="text"
                                                            value={eachItem.instructions}
                                                            onChange={(e) => { updateSpecialInstructions(eachItem, index, e.target.value) }}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="counter-container">
                                            <img src="/assets/icons/decreament.png" alt="dec" onClick={() => { decreamentCount(eachItem, index) }} />
                                            <span className="counter-value">{eachItem.count}</span>
                                            <img src="/assets/icons/increament.png" alt="Inc" onClick={() => { increamentCount(eachItem, index) }} />
                                            <span className="total-price">$ {eachItem.count * eachItem.price}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="action-btn-container slide-up">
                        <button className="default-btn bg-title-color" onClick={() => props.onCloseBtnClick()}>&lt;&lt; GO BACK TO SHOPS</button>
                        <button className="default-btn bg-theme-color-2" onClick={() => { navigate("/dashboard/market-place/payment") }}>SUBMIT</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ShoppingItemResults;
