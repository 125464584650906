import React from 'react';
import './evisa-payment.css';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, FormControlLabel, Input } from '@mui/material';

const EVisaPayment = (props) => {

    const [profileValues, setProfileValues] = React.useState({
        countryOfResidence: '',
        street1: '',
        street2: '',
        city: '',
        zip: '',
        state: '',
        province: '',
        addressSameAsAdminFlag: false
    });

    const handleProfileDataChange = (key) => (event) => {
        setProfileValues({ ...profileValues, [key]: event.target.value });
    };

    const [cardDetails, setCardDetails] = React.useState({
        cardHolderName: '',
        cardNumber: '',
        cardExpiryYear: '',
        cardExpiryMonth: '',
        cardCVV: ''
    });

    const handleCardDetailsChange = (key) => (event) => {
        setCardDetails({ ...cardDetails, [key]: event.target.value });
    };

    const toggleFlagChanges = (key) => (event) => {
        setProfileValues({ ...profileValues, addressSameAsAdminFlag: !profileValues.addressSameAsAdminFlag });
    }

    return (
        <div className="evisa-payment-container">
            <div className="evisa-payment-header-section">
                <p className='evisa-payment-header'>VISA PAYMENT</p>
            </div>
            <div className="evisa-payment-form-contents">
                <div className="evisa-payment-form-column">
                    <div className="form-section">
                        <h3 className="section-title">Your Shopping Cart</h3>
                        <table className="shopping-cart-table">
                            <thead>
                                <tr>
                                    <th>Itemized</th>
                                    <th>Cost</th>
                                    <th># of People</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>VISA</td>
                                    <td>$80</td>
                                    <td className="toggle-counter">
                                        <span>2</span>
                                    </td>
                                    <td className="right-align">$160</td>
                                </tr>
                                <tr>
                                    <td colSpan="3"></td>
                                    <td className="total-amt right-align">$160</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="form-section">
                        <h3 className="section-title">Credit Card Information: </h3>

                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="card-holder-name">Card Number *</InputLabel>
                                <Input
                                    id="card-number"
                                    type="text"
                                    value={cardDetails.cardNumber}
                                    onChange={handleCardDetailsChange('cardNumber')}
                                />
                            </FormControl>
                        </div>
                        <div className='two-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="card-expiry-year">Expiry Year *</InputLabel>
                                    <Input
                                        id="card-expiry-year"
                                        type="text"
                                        value={cardDetails.cardExpiryYear}
                                        onChange={handleCardDetailsChange('cardExpiryYear')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="card-expiry-month">Expiry Month *</InputLabel>
                                    <Input
                                        id="card-expiry-month"
                                        type="text"
                                        value={cardDetails.cardExpiryMonth}
                                        onChange={handleCardDetailsChange('cardExpiryMonth')}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='two-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="card-holder-name">Card Holder Name *</InputLabel>
                                    <Input
                                        id="card-holder-name"
                                        type="text"
                                        value={cardDetails.cardHolderName}
                                        onChange={handleCardDetailsChange('cardHolderName')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="card-cvv">CVV (Security Code)*</InputLabel>
                                    <Input
                                        id="card-cvv"
                                        type="text"
                                        value={cardDetails.cardCVV}
                                        onChange={handleCardDetailsChange('cardCVV')}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="right-align">
                            <button className="action-btn default-btn" >SAVE</button>
                        </div>

                    </div>
                </div>
                <div className="evisa-payment-form-column">
                    <div className="form-section">
                        <h3 className="section-title">Billing Address: </h3>
                        <div className="mui-form-control p0">
                            <FormControlLabel control={<Checkbox checked={profileValues.addressSameAsAdminFlag} onChange={toggleFlagChanges('addressSameAsAdminFlag')} />} label="Address same as Admin?" />
                        </div>
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="travel-pass-form-street1">Street1 *</InputLabel>
                                <Input
                                    id="travel-pass-form-street1"
                                    type="text"
                                    value={profileValues.street1}
                                    onChange={handleProfileDataChange('street1')}
                                />
                            </FormControl>
                        </div>
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="travel-pass-form-street2">Street 2 (Apt. / Unit Number )</InputLabel>
                                <Input
                                    id="travel-pass-form-street2"
                                    type="text"
                                    value={profileValues.street2}
                                    onChange={handleProfileDataChange('street2')}
                                />
                            </FormControl>
                        </div>
                        <div className='two-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="travel-pass-form-city">City *</InputLabel>
                                    <Input
                                        id="travel-pass-form-city"
                                        type="text"
                                        value={profileValues.city}
                                        onChange={handleProfileDataChange('city')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="travel-pass-form-zip">Zip *</InputLabel>
                                    <Input
                                        id="travel-pass-form-zip"
                                        type="text"
                                        value={profileValues.zip}
                                        onChange={handleProfileDataChange('zip')}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='two-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="travel-pass-form-state">State *</InputLabel>
                                    <Input
                                        id="travel-pass-form-state"
                                        type="text"
                                        value={profileValues.state}
                                        onChange={handleProfileDataChange('state')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="travel-pass-form-province">Province *</InputLabel>
                                    <Input
                                        id="travel-pass-form-province"
                                        type="text"
                                        value={profileValues.province}
                                        onChange={handleProfileDataChange('province')}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="travel-pass-form-country-of-residence">Country of Residence *</InputLabel>
                                <Select
                                    id="travel-pass-form-country-of-residence"
                                    value={profileValues.countryOfResidence}
                                    onChange={handleProfileDataChange('countryOfResidence')}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="1">India</MenuItem>
                                    <MenuItem value="2">United States of America</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="right-align">
                            <button className="action-btn default-btn" >CHECKOUT</button>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default EVisaPayment;
