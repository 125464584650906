import React from 'react';
import './dine-and-shop.css';
import RestaurantResults from './restaurant-results/restaurant-results';

const DineAndShop = (props) => {
    const [terminalOptions, setTerminalOptions] = React.useState([
        {
            value: "",
            displayName: "Select Terminal"
        },
        {
            value: "Terminal 1",
            displayName: "Terminal 1"
        }
    ])

    const [selectedTerminal, setSelectedTerminal] = React.useState("");
    const [selectedFavoriteItem, setSelectedFavoriteItem] = React.useState("");
    const [dineOption, setDineOption] = React.useState(0);
    const [selectedStore, setSelectedStore] = React.useState("");
    const [favouriteItems, setFavoriteItem] = React.useState([
        {
            name: "Five Guys",
            image: "/assets/images/market-place/shopping-app-images/Five Guys.jpg"
        },
        {
            name: "Don Pepe",
            image: "/assets/images/market-place/shopping-app-images/pexels-los-muertos-crew-7613568.jpg"
        },
        {
            name: "Pizza Hut",
            image: "/assets/images/market-place/shopping-app-images/pexels-horizon-content-3915857.jpg"
        },
        {
            name: "Peet's Coffee",
            image: "/assets/images/market-place/shopping-app-images/pexels-chevanon-photography-312418.jpg"
        },
        {
            name: "IHOP",
            image: "/assets/images/market-place/shopping-app-images/pexels-ash-376464.jpg"
        },
        {
            name: "FLIPP Burger",
            image: "/assets/images/market-place/shopping-app-images/Flip Burger.jpg",
            category: "FOOD",
            enableClick: true
        }
    ]);
    const [storeCategories, setStoreCategories] = React.useState([
        {
            name: "FOOD",
            image: "/assets/images/market-place/shopping-app-images/food.jpg"
        },
        {
            name: "DRINKS",
            image: "/assets/images/market-place/shopping-app-images/drinks.jpg"
        },
        {
            name: "COSMETICS",
            image: "/assets/images/market-place/shopping-app-images/cosmetics.jpg"
        },
        {
            name: "CLOTHING",
            image: "/assets/images/market-place/shopping-app-images/clothing.jpg"
        },
        {
            name: "LUGGAGE",
            image: "/assets/images/market-place/shopping-app-images/luggage.jpg"
        }
    ]);
    const [allRestaurantTypes, setAllRestaurantTypes] = React.useState({
        "FOOD": [
            {
                name: "PIZZA",
                image: "/assets/images/market-place/shopping-app-images/pexels-horizon-content-3915857.jpg"
            },
            {
                name: "BURGERS",
                image: "/assets/images/market-place/shopping-app-images/Five Guys.jpg",
                enableClick: true
            },
            {
                name: "MEXICAN",
                image: "/assets/images/market-place/shopping-app-images/pexels-los-muertos-crew-7613568.jpg"
            },
            {
                name: "CAFE",
                image: "/assets/images/market-place/shopping-app-images/pexels-chevanon-photography-312418.jpg"
            },
            {
                name: "BREAKFAST",
                image: "/assets/images/market-place/shopping-app-images/pexels-ash-376464.jpg"
            },
            {
                name: "BAR",
                image: "/assets/images/market-place/shopping-app-images/pexels-live-on-shot-2789328.jpg"
            }
        ],
        "DRINKS": [
            {
                name: "BARS",
                image: "/assets/images/market-place/shopping-app-images/Bar.jpg"
            },
            {
                name: "CAFES",
                image: "/assets/images/market-place/shopping-app-images/Cafe.jpg"
            },
            {
                name: "JUICE BARS",
                image: "/assets/images/market-place/shopping-app-images/Juice Bar.jpg"
            },
            {
                name: "RESTAURANT & BAR",
                image: "/assets/images/market-place/shopping-app-images/Restaurant & Bar.jpg"
            },
            {
                name: "STORES",
                image: "/assets/images/market-place/shopping-app-images/Store.jpg"
            }
        ]
    });
    const [showDineResults, setShowDineResults] = React.useState(false);

    const toggleDineResults = () => { setShowDineResults(!showDineResults) }

    const getButtonSelectedClass = (index) => {
        if (dineOption === index) {
            return "selected";
        }
        else {
            return "";
        }
    }

    const toggleForms = (val) => {
        setDineOption(val);
    }

    const getRestaurantTypes = (item) => {
        return (item in allRestaurantTypes) ? allRestaurantTypes[item] : [
            {
                name: "",
                image: "/assets/images/market-place/shopping-app-images/coming-soon.jpg"
            }
        ];
    }

    const openFavorites = (item) => {
        setSelectedFavoriteItem(item);
        setSelectedStore(item["category"])
        toggleDineResults();
    }

    const clearAndToggleDineResults = () => {
        setSelectedStore("");
        setSelectedFavoriteItem({});
        toggleDineResults();
    }

    return (
        <div className="dine-and-shop-component">
            <div className="dine-and-shop-component-header">
                <h2>DINE &amp; SHOP</h2>
            </div>
            <div className="dine-and-shop-options-container">
                <div className="option-btns">
                    <button className={getButtonSelectedClass(0)} onClick={() => { toggleForms(0) }}>Pick Up</button>
                    <button className={getButtonSelectedClass(1)} onClick={() => { toggleForms(1) }}>Delivery</button>
                    <button className={getButtonSelectedClass(2)} onClick={() => { toggleForms(2) }}>Duty Free</button>
                </div>
                {/* <div className="terminal-select">
                    <CustomSelectInput options={terminalOptions}
                        selectedValue={selectedTerminal}
                        onSelectionChange={setSelectedTerminal} />
                </div> */}
                {/* <div className="airport-info-icon" onClick={() => { props.history.push("/dashboard/airport-map-info") }}>
                    <img src="/assets/nav-icons/airport-info-icon.png" alt="Airport Info" />
                </div> */}
            </div>
            {
                !showDineResults ? <>
                    <div className="dine-and-shop-contents">
                        <div className="dine-and-shop-tabs my-favorites-tabs slide-right">
                            <h3 className="nav-tab-header">MY FAVORITES</h3>
                            {
                                favouriteItems.map((eachItem, index) => {
                                    return (
                                        <div className="each-navigation-tab" key={index} onClick={() => { if (eachItem.enableClick) { openFavorites(eachItem) } }}>
                                            <img src={eachItem.image} alt={eachItem.name} />
                                            <h3>{eachItem.name}</h3>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="dine-and-shop-tabs store-categories-tabs slide-right">
                            <h3 className="nav-tab-header">SHOPPING CATEGORIES</h3>
                            {
                                storeCategories.map((eachItem, index) => {
                                    return (
                                        <div className="each-navigation-tab" key={index} onClick={() => { setSelectedStore(""); setSelectedStore(eachItem.name) }}>
                                            <img src={eachItem.image} alt={eachItem.name} />
                                            <h3>{eachItem.name}</h3>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {
                            selectedStore ?
                                <div className="dine-and-shop-tabs restaurant-types-tabs slide-right">
                                    <h3 className="nav-tab-header">TYPES OF {selectedStore}</h3>
                                    {
                                        getRestaurantTypes(selectedStore).map((eachItem, index) => {
                                            return (
                                                <div className="each-navigation-tab" key={index} onClick={() => { if (eachItem.enableClick) { toggleDineResults() } }}>
                                                    <img src={eachItem.image} alt={eachItem.name} />
                                                    <h3>{eachItem.name}</h3>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                : <></>
                        }
                    </div>
                </> : <>
                    <RestaurantResults
                        onCloseBtnClick={clearAndToggleDineResults}
                        storeTypes={getRestaurantTypes(selectedStore)}
                        selectedStore={selectedStore}
                        selectedFavoriteItem={selectedFavoriteItem} />
                </>
            }
        </div>
    )

}

export default DineAndShop;