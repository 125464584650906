import React from 'react';
import './privacy-policies.css';

const PrivacyPolicies = () => {

    return (
        <div className="privacy-policies-component">
            <div className="privacy-policies-container">
                <div className="privacy-policies-header">
                    <h1>MAGELLANGO TOURISM AND IMMIGRATION MOBILE APP PRIVACY POLICY</h1>
                    <h3>Last updated: OCTOBER 20, 2021</h3>
                </div>
                <div className="privacy-policies-content">
                    <h2 className="content-subheader">Introduction</h2>
                    <p>
                        <b>MagellanGO LLC</b> ("MagellanGO" "we," or "us") owns and operates a website and mobile applications, www.magellan-go.com (the " Website") and the MagellanGO Tourism and Immigration mobile application (the “App”; together with the Website, the “Platform”). Your access and use of the Platform, any part thereof, or anything associated therewith, including its content (" Content"), any products or services provided through the Platform or otherwise by MagellanGO, and any affiliated website, software or application owned or operated by MagellanGO (collectively, including the Platform and the Content, the "Service") are subject to this Privacy Policy unless specifically stated otherwise. Capitalized terms not otherwise defined in this Privacy Policy have the same meaning as set forth in the MagellanGO Tourism and Immigration Application Terms and Conditions ("Terms and Conditions”).
                    </p>
                    <p>
                        We respect the privacy of users of our Service. We created this Privacy Policy ("Privacy Policy") to inform you how MagellanGO collects, uses and discloses information in order to provide you with the Service.
                    </p>
                    <p>
                        As with our Terms and Conditions, by creating, registering, or logging into an account through the Service, or otherwise accessing or using the Service, you are automatically accepting and acknowledging the most recent version of this Privacy Policy. If we make any changes to our Privacy Policy, we will post the revised Privacy Policy and update the "Last updated" date of the Privacy Policy.
                    </p>
                    <p>
                        If you are using the Service on behalf of an individual other than yourself, you represent that you are authorized by such individual to act on such individual's behalf and that such individual acknowledges the practices and policies outlined in this Privacy Policy.
                    </p>
                    <h2 className="content-subheader">COPPA (Children Online Privacy Protection Act) - No Use by Minors Permitted</h2>
                    <p>
                        Our Service is intended for use by individuals who are at least 18 years of age or such older age as may be required by applicable state laws in the jurisdiction in which an individual utilizes the Service. The Service is not designed or intended to attract, and is not directed to, children under eighteen (18) years of age, let alone thirteen (13) years of age. If we obtain actual knowledge that we have collected personal information through the Platform from a person under thirteen (13) years of age, we will use reasonable efforts to refrain from further using such personal information or maintaining it in retrievable form.
                    </p>
                    <p>
                        Furthermore, if you are under sixteen (16) years of age, then you (or your parent or legal guardian if you are under age 13) may at any time request that we remove content or information about you that is posted on the Platform. Please submit any such request ("Request for Removal of Minor Information") to either of the following:
                    </p>
                    <ul>
                        <li>
                            By mail: <b>MagellanGO LLC</b>, Attn: Privacy Officer, <b>MagellanGO LLC</b>, 1055 Parsippany Blvd, Suite 303, Parsippany, New Jersey 07054 USA, with a subject line of "Removal of Minor Information. If you send by mail, please send by U.S. Certified Mail, Return Receipt Requested to allow for confirmation of mailing, delivery and tracking.
                        </li>
                        <li>
                            By email: help@magellan-go.com, with a subject line of "Removal of Minor Information"
                        </li>
                        <li>
                            For each Request for Removal of Minor Information, please state "Removal of Minor Information" in the email or letter subject line, and clearly state the following in the body of the request:
                            <ul>
                                <li>The nature of your request</li>
                                <li>The identity of the content or information to be removed</li>
                                <li>The location of the content or information on the Platform (e.g. by providing the URL)</li>
                                <li>That the request is related to the "Removal of Minor Information"</li>
                                <li>Your name, street address, city, state, zip code and email address, and whether you prefer to receive a response to your request by mail or email.</li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        We will not accept any Request for Removal of Minor Information via telephone or facsimile. MagellanGO is not responsible for failing to comply with any Request for Removal of Minor Information that is incomplete, incorrectly labeled or incorrectly sent.
                    </p>
                    <p>
                        Users 18 years of age or older may append to their records information about family members, including those under the age of 18 years, but the App does not collect information directly from minors.
                    </p>
                    <p>
                        Please note that we are not required to erase or otherwise eliminate, permanently mask or enable erasure or elimination of such content or information in certain circumstances, such as, for example, when an international, federal, state, or local law, rule or regulation requires MagellanGO to maintain the content or information; when MagellanGO maintains the content or information on behalf of your Providers (as defined in our Terms and Conditions) as part of your electronic records; when the content or information is stored on or posted to the Site by a third party other than you (including any content or information posted by you that was stored, republished or reposted by the third party); when MagellanGO anonymizes the content or information, so that you cannot be individually identified; when you do not follow the aforementioned instructions for requesting the removal of the content or information; and when you have received compensation or other consideration for providing the content or information.
                    </p>
                    <p>
                        The foregoing is a description of MagellanGOs' voluntary practices concerning the collection of personal information through the Service from certain minors and is not intended to be an admission that MagellanGO is subject to the Children's Online Privacy Protection Act, the Federal Trade Commission's Children's Online Privacy Protection Rule(s), or any similar international, federal, state, or local laws, rules, or regulations.
                    </p>
                    <h2 className="content-subheader">Protected Health Information</h2>
                    <p>
                        When you set up an account with MagellanGO, you are creating a direct customer relationship with MagellanGO that enables you to access and/or utilize the various functions of the Platform and the Service as a user. As part of that relationship, you provide information to MagellanGO, including but not limited to, your name, email address, physical address, phone number and certain transactional information, that we do not consider to be "protected health information" or "medical information".
                    </p>
                    <p>
                        However, in using certain components of the Service, you may also provide certain health or medical information that may be protected under applicable laws. MagellanGO is not a "covered entity" under the Health Insurance Portability and Accountability Act of 1996, Public Law 104-191, and its related regulations and amendments from time to time (collectively, " HIPAA"). It is important to note that HIPAA does not necessarily apply to an entity or person simply because there is health information involved, and HIPAA may not apply to your transactions or communications with MagellanGO,  In addition, any medical or health information that you provide that is subject to specific protections under applicable state laws (collectively,  "Protected Information"), will be used and disclosed only in accordance with such  laws. However, any information that does not constitute Protected Information under applicable laws may be used or disclosed in any manner permitted under this Privacy Policy. Protected Information does not include information that has been de-identified in accordance with applicable laws.
                    </p>
                    <p>
                        The Providers' Notices of Privacy Practices will describe how they use and disclose Protected Information. By accessing or using any part of the Service, you are acknowledging receipt of the Notice of Privacy Practices from your Provider(s).
                    </p>
                    <p>
                        By accessing or using any part of the Service, you are agreeing that even if HIPAA does apply to MagellanGO, any information that you submit to the Service is not considered Protected Information, and will only be subject to our Privacy Policy and any applicable laws that govern the privacy and security of such information.
                    </p>
                    <h2 className="content-subheader">Collection of Information</h2>
                    <p>
                        We collect any information you provide when you use the Service, including, but not limited to:
                    </p>
                    <p>
                        •	Personally identifying information about you (and, optionally, your family members) such as name and contact data such as e-mail address, phone number, and physical address
                    </p>
                    <p>
                        •	Your login and password and other account ("Account") registration details
                    </p>
                    <p>
                        •	Demographic data about you (and, optionally, your family members) such as your gender and date of birth
                    </p>
                    <p>
                        •	Computer, mobile device and/or browser information (e.g., IP address, mobile device ID information, operating system, connection speed, bandwidth, browser type, referring/exist web pages, web page requests, cookie information, hardware attributes, software attributes)
                    </p>
                    <p>
                        •	Physical characteristics about you (and, optionally, your family members) such as height and weight
                    </p>
                    <p>
                        •	A User profile picture
                    </p>
                    <p>
                        •	Your Provider information (e.g., your primary care physician)
                    </p>
                    <p>
                        •	Health information, which can be entered into the App by you or uploaded, at your direction from Providers such as immunization status, COVID-19 test results, temperature checks, allergies, medical history, vitals and health statistics and medications
                    </p>
                    <p>
                        •	Third-party website, network, platform, server and/or application information
                    </p>
                    <p>
                        •	Usage activity concerning your interactions with the Service and/or third-party websites, networks or applications accessed through the Service (e.g., viewing habits, viewing preferences, viewing history, number of clicks on a page or feature, amount of time spent on a page or feature, identify of third-party websites, networks, etc.)
                    </p>
                    <p>
                        •	Statements or content (e.g., comments, videos, photographs, images) and information about such statements or content, which you submit or publish on or through the Service or which are accessed via your public or linked social media pages (e.g., Facebook, Twitter, Instagram)
                    </p>
                    <p>
                        •	Any other information you provide when you contact or communicate with us, including with our virtual assistant/bot “Heather.”
                    </p>
                    <p>
                        If you use your mobile device to visit, access or use the Service, then additional categories of information that we collect may include
                    </p>
                    <p>
                        •	Your name associated with your mobile device
                    </p>
                    <p>
                        •	Your telephone number associated with your mobile device
                    </p>
                    <p>
                        •	Your geolocation
                    </p>
                    <p>
                        •	Your mobile device ID information
                    </p>
                    <p>
                        •	With your express consent, information about third-party software applications on your mobile device (including, without limitation, general software apps, downloadable software apps, social media apps)
                    </p>
                    <h2 className="content-subheader">How Information Is Collected</h2>
                    <p>
                        MagellanGO might collect personal and non-personal information directly from you when you visit, access or use the Service; when you register with or subscribe to the Service or any products or services available through the Service; when you "sign in," "log in," or the like to the Service; when you allow the Service to access, upload, download, import or export content found on or through, or to otherwise interact with, your computer or mobile device (or any other device you may use to visit, access or use the Service) or online accounts with third-party websites, networks, platforms, servers or applications (e.g., your online social media accounts, your cloud drives and servers, your mobile device service provider); or whenever MagellanGO asks you for such information, such as, for example, when you answer an online survey or questionnaire. In addition, if you or a third party sends MagellanGO a comment, message or other communication (such as, by way of example only, email, letter, fax, phone call, or voice message) about you or your activities on or through the Website and/or the App, then MagellanGO may collect any personal or non-personal information provided therein or therewith.
                    </p>
                    <p>
                        In addition to the information that we collect directly from you, we may also collect certain information from Providers who provided vaccinations, test results, treatment or other services to you in connection with our Service.
                    </p>
                    <p>
                        Finally, MagellanGO might use various tracking, data aggregation and/or data analysis technologies, including, for example, the following:
                    </p>
                    <p>
                        •	Cookies, which are small data files (e.g., text files) stored on the browser or device you use to view a website or message. They may help store user preferences and activity and may allow a website to recognize a particular browser or device. There are several types of cookies, including, for example, browser cookies, session cookies, and persistent cookies. Cookies may record information you access on one page of a website to simplify subsequent interaction with that website, or to help streamline your transactions on related pages of that website. Most major browsers are set up so that they will initially accept cookies, but you might be able to adjust your browser's or device's preferences to issue you an alert when a cookie is downloaded, or to block, reject, disable, delete or manage the use of some or all cookies on your browser or device. Cookies can be set by the website owner (i.e., us), or they can be set by third parties (e.g., Facebook, Google, etc.) Cookies are used to help us speed up your future activities or to improve your experience by remembering the information that you have already provided to us. Third party cookies may also be used to enable analytics (e.g. Google Analytics) or advertising functionality (e.g., ad re-targeting on third-party websites) that enables more customized services and advertising by tracking your interaction with our Service and collecting information about how you use the Service.
                    </p>
                    <p>
                        •	Flash cookies, which are cookies written using Adobe Flash, and which may be permanently stored on your device. Like regular cookies, Flash cookies may help store user preferences and activity, and may allow a website to recognize a particular browser or device. Flash cookies are not managed by the same browser settings that are used for regular cookies.
                    </p>
                    <p>
                        •	Web beacons, which are pieces of code embedded in a website or email to monitor your activity on the website or your opening of the email, and which can pass along information such as the IP address of the computer or device you use to view the website or open the email, the URL page on which the web beacon is located, the type of browser that was used to access the website, and previously set cookie values. Web beacons are sometimes used to collect advertising data, such as counting page views, promotion views or advertising responses. Disabling your computer's, device's or browser's cookies may prevent some web beacons from tracking or recording certain information about your activities.
                    </p>
                    <p>
                        •	Scripts, which are pieces of code embedded in a website to define how the website behaves in response to certain key or click requests sent by the user. Scripts are sometimes used to collect information about the user's interactions with the website, such as the links the user clicks on. Scripts are often times temporarily downloaded to the user's computer or device from the website server, active only while the user is connected to the Site and/or the App, and deactivated or deleted when the user disconnects from the website.
                    </p>
                    <p>
                        •	Analytic tools and services, which are sometimes offered by third parties, and which track, measure and/or generate information about a website's or program's traffic, sales, audience and similar information, and which may be used for various reasons, such as, for example, statistical research, marketing research, and content ratings research, and conversion tracking. Examples of the analytic tools and services which MagellanGO might use include Google Analytics, AppAnnie and Hubspot. MagellanGO may also use other third-party analytic tools and services.
                    </p>
                    <p>
                        Please be advised that if you choose to block, reject, disable, delete or change the management settings for any or all of the aforementioned technologies and/or other tracking, data aggregation and data analysis technologies, then certain areas of the Platform might not function properly.
                    </p>
                    <p>
                        By visiting, accessing or using the Service, you acknowledge and agree in each instance that you are giving MagellanGO permission to monitor or otherwise track your activities on the Service, and that MagellanGO may use the aforementioned technologies and/or other tracking, data aggregation and data analysis technologies. Notwithstanding the foregoing, MagellanGO does not permit third parties or third-party cookies to access to any communications you have with the Providers, or medical information that you submit to the Providers for diagnosis and treatment purposes.
                    </p>
                    <h2 className="content-subheader">Use of Information</h2>
                    <p>
                        In connection with providing the Service, we and our affiliates and service providers may use your information, subject to the limitations addressed above herein, for a number of purposes, including, but not limited to:
                    </p>
                    <p>
                        •	Verifying your identity;
                    </p>
                    <p>
                        •	Confirming your location;
                    </p>
                    <p>
                        •	Administering your account;
                    </p>
                    <p>
                        •	Fulfilling your requests;
                    </p>
                    <p>
                        •	Facilitating your movement through Service;
                    </p>
                    <p>
                        •	Facilitating your use of the Service and/or products or services offered through the Service;
                    </p>
                    <p>
                        •	Communicating with you by letter, email, text, telephone or other forms of communication;
                    </p>
                    <p>
                        •	Providing you with information about MagellanGO,  and its businesses, products and services by letter, email, text, telephone or other forms of communication;
                    </p>
                    <p>
                        •	Providing you with customer support;
                    </p>
                    <p>
                        •	Providing you with information about third-party businesses, products and services by letter, email, text, telephone or other forms of communication;
                    </p>
                    <p>
                        •	Developing, testing or improving the Service and content, features and/or products or services offered via the Service;
                    </p>
                    <p>
                        •	Identifying or creating new products, services, marketing and/or promotions for MagellanGO  or the Service;
                    </p>
                    <p>
                        •	Promoting and marketing MagellanGO, the Service, and the products and/or services offered via the Service;
                    </p>
                    <p>
                        •	Improving user experiences with the Service;
                    </p>
                    <p>
                        •	Analyzing traffic to and through Service;
                    </p>
                    <p>
                        •	Analyzing user behavior and activity on or through the Service;
                    </p>
                    <p>
                        •	Conducting research and measurement activities for purposes of product and service research and development, market research, and other activities related to MagellanGO, the Service or products and services offered via the Service;
                    </p>
                    <p>
                        •	Monitoring the activities of you and others on or through the Service;
                    </p>
                    <p>
                        •	Protecting or enforcing MagellanGO's rights and properties;
                    </p>
                    <p>
                        •	Protecting or enforcing the rights and properties of others (which may include you);
                    </p>
                    <p>
                        •	When required by applicable law, court order or other governmental authority (including, without limitation and by way of example only, in response to a subpoena or other legal process); or
                    </p>
                    <p>
                        •	MagellanGO believes in good faith that such use is otherwise necessary or advisable (including, without limitation and by way of example only, to investigate, prevent, or take legal action against someone who may be causing injury to, interfering with, or threatening the rights, obligations or properties of MagellanGO, a user of the Service, which may include you, or anyone else who may be harmed by such activities or to further MagellanGO's legitimate business interests).
                    </p>
                    <p>
                        We may de-identify your information and use, create and sell such de-identified information, or any business or other purpose not prohibited by applicable law.
                    </p>
                    <h2 className="content-subheader">Disclosure of Information</h2>
                    <p>
                        Subject to the limitations described in the Protected Health Information section above, we may disclose your information to third parties in connection with the provision of our Service or as otherwise permitted or required by law. For example, we may disclose your information to:
                    </p>
                    <p>
                        •	Participating Locations (as defined in the Terms and Conditions) to enable them to provide services to you via the App (e.g., entrance into a venue), provided, however, that the App is designed to minimize the data sharing, such that, for example, if a Participating Location requires adherence to a particular protocol, the App will provide information as “Compliant”/”Non Compliant”, “Negative”/”Positive” (in each case signaled with a green light/red light) and not share unnecessary information.
                    </p>
                    <p>
                        •	Providers in order to verify health information (such as vaccinations, tests, etc.);
                    </p>
                    <p>
                        •	Our third-party and service providers (collectively "vendors") that provide services to enable us to provide the Service, such as the hosting of the Service, data analysis, IT services and infrastructure, customer service, e-mail delivery, and other similar services;
                    </p>
                    <p>
                        •	Our vendors that provide services to enable us to run our business and administrative operations, such as legal and financial advisory services, auditing services, analytics and similar services;
                    </p>
                    <p>
                        •	Our vendors that provide services to enable us to promote and advertise the Service and the products and/or services offered via the Service, as well as comply with contact removal requests or requirements, such as mailing list removal services, do not call registries, and similar services;
                    </p>
                    <p>
                        •	Vendors as we believe necessary or appropriate to comply with applicable laws; and
                    </p>
                    <p>
                        •	To a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, liquidation or other disposition of all or any portion of our business, assets or stock with such third party.
                    </p>
                    <p>
                        We may de-identify your information and disclose such de-identified information for any purpose not prohibited by applicable law.
                    </p>
                    <h2 className="content-subheader">Data Retention and Security</h2>
                    <p>
                        The Service allows you to maintain historical data, including health information in a blockchain based platform using the Ethereum protocol.  The following data is stored in a blockchain secured cryptographic format and linked via a hash key:
                    </p>
                    <p>
                        •	Name (First Name, Last Name, Middle Initial)
                    </p>
                    <p>
                        •	Street address
                    </p>
                    <p>
                        •	Phone number with area code
                    </p>
                    <p>
                        •	Date of birth
                    </p>
                    <p>
                        •	Type of test/vaccination required by destination
                    </p>
                    <p>
                        •	Date of such required test/vaccination
                    </p>
                    <p>
                        •	Provider name, address and phone number
                    </p>
                    <p>
                        •	Scanned test result (PDF / JPEG / JPG)
                    </p>
                    <p>
                        The data is stored in a cloud-based environment, using inline encryption features of Azure for data in transit and at rest. Data is made legible only to third parties authorized by the User.  Dual record level User authentication is required for sharing data with Participating Location or Providers. User Data can only be shared on MagellanGO's Platform based on user consent.  A QR Code is generated to share data utilizing Smart Contracts, and verification is managed by the User through a digital signature.  Removal of data on the blockchain is accomplished via permanent masking using a cryptographically hashed private key which makes the data unable to be viewed.
                    </p>
                    <p>
                        MagellanGO may retain your information in a readable format for as long as it believes necessary; as long as necessary to comply with its legal obligations, resolve disputes and/or enforce its agreements; and/or as long as needed to provide you with the products and/or services of the Service or MagellanGO. MagellanGO may dispose of, delete or permanently mask any such information at any time, except as set forth in any other agreement or document executed by MagellanGO or as required by law.
                    </p>
                    <p>
                        No Internet or e-mail transmission is ever fully secure or error free, however.  We therefore cannot guarantee absolute security of your data, and we are not responsible for processes and networks that we do not control.  Users assume the risk of security breaches and the consequences resulting from them.  Please be careful in deciding what information you send to us via email or over the Internet.
                    </p>
                    <h2 className="content-subheader">Jurisdictional Issues</h2>
                    <p>
                        MagellanGO is organized under United States law, and the Service is administered in the United States. Accordingly, this Privacy Policy, and our collection, use, and disclosure of your information, is governed by U.S. law.  Notwithstanding the foregoing, MagellanGO may utilizes data centers in other parts of the world to store data, and your location data may be used to determine the appropriate data center.
                    </p>
                    <h2 className="content-subheader">Third Parties</h2>
                    <p>
                        This Privacy Policy does not address or apply to, and we are not responsible for, the privacy, information or other practices of any third parties, including, without limitation, your Providers, , the manufacturer of your mobile device, and any other third-party mobile application or website to which our Service may contain a link. These third parties may at times gather information from or about you. We do not control and are not responsible for the privacy practices of these third parties. We encourage you to review all such third parties' privacy policies and the privacy policies of each website and application you visit and use.
                    </p>
                    <h2 className="content-subheader">Summary of Information Practices</h2>
                    <p>
                        The following table summarizes our personal information collection, use, and sharing practices.  As reflected in this table, we may share your personal information with a variety of outside entities.
                    </p>
                    <div className="privacy-policies-table-container">
                        <table className="privacy-policies-table">
                            <thead>
                                <tr>
                                    <th>
                                        Category of Personal Information Collected
                                    </th>
                                    <th>
                                        Examples
                                    </th>
                                    <th>
                                        Categories of Sources
                                    </th>
                                    <th>
                                        Commercial/Business Purpose
                                    </th>
                                    <th>
                                        Categories of Third Parties with Whom MagellanGO Shares Personal Information
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        Identifiers
                                    </td>
                                    <td>
                                        Full name, email address, phone number, account login and password, billing address, physical address
                                    </td>
                                    <td>
                                        You, our and third-party cookies and other tracking technologies on our website, and service providers.
                                    </td>
                                    <td>
                                        Facilitating use of Services and/or products or services, marketing, customer, or analytic services,
                                        protecting
                                        against malicious, deceptive, fraudulent or illegal activity, and enabling or effecting, directly or
                                        indirectly,
                                        a commercial transaction
                                    </td>
                                    <td>
                                        Service providers, Providers, Participating Locations, third parties that assume control over all or
                                        part of the
                                        business in connection with a merger, acquisition, bankruptcy, or similar event, affiliates,
                                        professional
                                        advisors, law enforcement authorities, and those involved in legal proceedings, with consent
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Unique identifiers or personal identifiers
                                    </td>
                                    <td>
                                        IP address, online identifiers, mobile device ID, dates of tests and vaccinations
                                    </td>
                                    <td>
                                        You, your mobile device, and our and third-party cookies and other tracking technologies on our Website
                                        or App
                                    </td>
                                    <td>
                                        Processing or performing Services, debugging to identify and repair errors that impair existing intended
                                        functionality, providing customer or analytic services, and enabling or effecting, directly or
                                        indirectly, the
                                        Services
                                    </td>
                                    <td>
                                        Service providers, Providers, Participating Locations, data analytic providers, affiliates, professional
                                        advisors, law enforcement authorities, and those involved in legal proceedings, with consent
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Internet and other network activity
                                    </td>
                                    <td>
                                        Browsing activity
                                    </td>
                                    <td>
                                        Your mobile devices and computers used to access our Website or App
                                    </td>
                                    <td>
                                        Marketing, customer, or analytic services and enabling or effecting, directly or indirectly, the
                                        Services
                                    </td>
                                    <td>
                                        Service providers, data analytic providers, affiliates
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Demographic information
                                    </td>
                                    <td>
                                        Gender, date of birth, zip code
                                    </td>
                                    <td>
                                        You
                                    </td>
                                    <td>
                                        Marketing, customer, or analytic services, performing the Services
                                    </td>
                                    <td>
                                        Service providers, Providers, Participating Locations, data analytic providers, affiliates
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Audio, electronic, visual, thermal, olfactory, or similar information
                                    </td>
                                    <td>
                                        Electronic signature, photographic or video images
                                    </td>
                                    <td>
                                        You and/or Providers
                                    </td>
                                    <td>
                                        Identification verification or non-diagnosis or treatment purposes, on behalf of Medical
                                        Groups/Providers for
                                        diagnosis or treatment purposes
                                    </td>
                                    <td>
                                        Service providers, Providers and Participating Locations
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Commercial Activity
                                    </td>
                                    <td>
                                        Information about Services purchased, obtained, or considered
                                    </td>
                                    <td>
                                        Your mobile device
                                    </td>
                                    <td>
                                        Performing Services, marketing, customer or analytic services
                                    </td>
                                    <td>
                                        Service providers, data analytic providers, affiliates
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Health and Medical Information
                                    </td>
                                    <td>
                                        Medical history and other information, height, weight, laboratory test results, vaccinations
                                    </td>
                                    <td>
                                        You and/or Providers
                                    </td>
                                    <td>
                                        Processing or fulfilling orders and transactions
                                    </td>
                                    <td>
                                        Service providers, Providers and Participating Locations
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Geolocation information
                                    </td>
                                    <td>
                                        Geolocation
                                    </td>
                                    <td>
                                        Your mobile devices and computers used to access our Website or App
                                    </td>
                                    <td>
                                        Confirming location
                                    </td>
                                    <td>
                                        Service providers and Providers
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Professional or Employment-related Information
                                    </td>
                                    <td>
                                        Job history, educational history, employer
                                    </td>
                                    <td>
                                        Applicants or non-applicant email addresses or signature blocks
                                    </td>
                                    <td>
                                        Process and evaluate applications for positions with MagellanGO
                                    </td>
                                    <td>
                                        Service providers such as HR vendors
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h2 className="content-subheader">California Residents</h2>
                    <p>
                        If you are a California resident, you have the right to know what personal information we collect, use, disclose or
                        sell about you under the California Consumer Privacy Act (“CCPA”). Additionally, you have the right to access and
                        delete your personal information. Some of this information is described above.
                    </p>
                    <p>
                        To exercise these privacy rights and choices, please follow the instructions below:
                    </p>
                    <p>
                        • How to request access to your personal information:_You may request access to your personal information twice in a
                        12-month period. To do so, please email us at help@magellan-go.com with the subject heading "California Privacy
                        Rights,”. In response, we will produce an Access Report detailing the personal information we have collected,
                        disclosed, and/or sold about you. This Access Report will be delivered by mail or electronically at your request.
                        Note, we may not always be able to fully address your request, for example, if it would impact the duty of
                        confidentiality we owe to others, or if we are legally entitled to deal with the request in a different way. Please
                        be aware that not all information sharing is covered by the California Privacy Rights requirements and only
                        information on covered sharing will be included in our response.
                    </p>
                    <p>
                        • How to request deletion of your personal information: You may request that we delete (or, in the case of data
                        retained in a blockchain, permanently mask) the personal information it has collected and/or maintained about you.
                        To do so, please email us at help@magellan-go.com Note, we may need to retain certain personal information as
                        permitted by law, such as to perform the Service for which the personal information was collected, maintain an
                        electronic medical record for a Provider, provide a requested good or service, detect security incidents, protect
                        against malicious, deceptive, fraudulent or illegal activities, comply with legal obligations or to enable solely
                        internal uses that are reasonably aligned with your expectations or lawful within the context in which you provided
                        the information.
                    </p>
                    <p>
                        We reserve the right to charge a fee when permitted by law, for instance, if your request is manifestly unfounded or
                        excessive. If we determine that the request warrants a fee, we will tell you why we made that decision and provide
                        you with a cost estimate before completing your request.
                    </p>
                    <p>
                        Verification:_Please note, we will take steps to verify your identity before fulfilling any of the above requests.
                        If you maintain an account with us, we will verify your identity through existing authentication practices for the
                        account (e.g., login and password). If you are not a registered member, we will verify your identity by matching two
                        or three data points that you provide with data points that we maintain and have determined to be reliable for the
                        purposes of verification (e.g., browser or device ID).
                    </p>
                    <p>
                        Authorized Agents:_Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer
                        request related to your or your minor child's personal information. In order to designate an authorized agent to
                        make a request on your behalf, you must provide written proof that you have consented to this designation unless the
                        agent has power of attorney pursuant to California Probate Code sections 4000-4465. You must also verify your
                        identity directly with us by providing a copy of your government issued identification.
                    </p>
                    <p>
                        Response Timing and Format: If you are a MagellanGO customer with an online account, we will deliver our written
                        response to that account online or via email. If you are not a MagellanGO customer or do not have an online account,
                        we will deliver our written response by mail or electronically, at your preference. The response will also explain
                        the reasons we cannot comply with a request, if applicable. Please note, that if you are submitting a request
                        regarding information you provided to a Provider or a Participating Location, your request should be directed to
                        that entity.
                    </p>
                    <p>
                        Anti-Discrimination Right: We will not discriminate against you for exercising any of your CCPA rights. But note
                        that some of the functionality and features available to you may change or no longer be available to you upon
                        deletion of your personal information or opt-out of sale of your personal information.
                    </p>
                    <p>
                        We use cookies and similar technologies as described in this Privacy Policy.
                    </p>
                    <p>
                        California “Shine the Light” Information-Sharing Disclosure: California residents may request a list of all third
                        parties with respect to which we have disclosed any information about you for direct marketing purposes and the
                        categories of information disclosed. If you are a California resident and want such a list, please send us a written
                        request by email to help@magellan-go.com with “California Shine The Light Rights” in the subject line.
                    </p>
                    <p>
                        Do Not Track: We do not currently respond to web browser "do not track" signals or other mechanisms that provide a
                        method to opt out of the collection of information across the networks of websites and online services in which we
                        participate. If we do so in the future, we will describe how we do so in this Privacy Policy. We do use cookies and
                        other technologies to track your usage and other information about your visit to our Platform. The App is an
                        installed mobile application and not a website delivered in browser. When DNT is enabled in the App, the server logs
                        the User's visit, however, no identifying information about the User or the device is collected.
                    </p>
                    <h2 className="content-subheader">Third-party links</h2>
                    <p>
                        If we include links to third-party products or services on our Website, please be aware that these third-party sites
                        have separate and independent privacy policies. We therefore have no responsibility or liability for the content and
                        activities of these linked sites.
                    </p>
                    <h2 className="content-subheader">Information about International Transfers</h2>
                    <p>
                        If you are visiting or using our Platform from outside the United States, please be aware that your Information will
                        be transferred to, stored, and processed in the United States. The data protection and other laws of the United
                        States and other countries might not be as comprehensive as those in your country. By visiting or using our Platform
                        or using, you understand that your information will be transferred to our facilities and those third parties with
                        whom we share it as described in this Privacy Policy.
                    </p>
                    <h2 className="content-subheader">Your European Privacy Rights</h2>
                    <p>
                        If you are a User who resides in the Europe Economic Area (the “European Union” or “EU”), we will provide
                        accommodations for the exercise of your rights provided under data protection laws of the EU, including the General
                        Data Protection Regulation (the “GDPR”). By using our Platform, you are consenting to the transfer of your
                        information from your jurisdiction to the United States.
                    </p>
                    <p>
                        If you are entitled to privacy rights under the GDPR, you are entitled to:
                    </p>
                    <p>
                        • Transparency and the right to information. Through this policy we explain how we use and share your information.
                        However, if you have questions or concerns you can contact us any time.
                    </p>
                    <p>
                        • Right of access, objection, restriction of processing, erasure, and portability. You also have the right to
                        withdraw your consent at any time when we process your personal data based on your consent. To exercise these
                        rights, please contact us.
                    </p>

                    <p>
                        • Right to opt-out to direct marketing. You have the right to opt-out at any time to receiving marketing materials
                        from us by following the opt-out instructions in our commercial emails, by contacting us, or by adjusting your
                        preferences on the Platform. Please note that we reserve the right to send you other communications, including
                        Platform announcements and administrative messages relating to your account, without offering you the opportunity to
                        opt out of receiving them.
                    </p>
                    <p>
                        • Right not to be subject to an automated decision, including profiling. We do not make automated decisions using
                        your personal data that may negatively impact you. We use cookies and similar technologies on our Platform to
                        personalize your experience on the Platform.
                    </p>

                    <p>
                        • Right to lodge a complaint with a supervisory authority. If you consider that the processing of your personal data
                        infringes your privacy rights according to the European Data Protection law, you have the right to lodge a complaint
                        with a supervisory authority, in the member state of your habitual residence, place of work, or place of the alleged
                        infringement.
                    </p>

                    <p>
                        • Grounds for Using Your Personal Data. If we process your information in any way, we rely on the following legal
                        grounds to process your personal information:
                    </p>

                    <p>
                        • Consent. Some uses of your personal data as described in this Privacy Policy are subject to your consent, such as
                        marketing email communications. To withdraw your consent, please contact us at help@magellan-go.com. You may also
                        refrain from providing, or withdraw, your consent for cookies via your browser and mobile settings.
                    </p>

                    <p>
                        • Performance of a contract. We may need to collect and use your personal information to enter into a contract with
                        you and to perform Services that you request.
                    </p>

                    <p>
                        • Legitimate Interests. We may use your personal information for our legitimate interests to provide our Services
                        and to improve our Services and the content contained thereon. We may use technical information as described in this
                        Privacy Policy and use personal information for our marketing purposes consistent with our legitimate interests and
                        any choices that we offer or consents that may be required under applicable law.
                    </p>

                    <p>
                        • Compliance with Legal Requirements. We may use your personal information as necessary to comply with our legal
                        obligations.
                    </p>

                    <h2 className="content-subheader">Miscellaneous</h2>
                    <p>
                        We strive to use reasonable physical, technical and administrative measures to protect information under our
                        control. However, you must keep your Account password secure and your Account confidential, and you are responsible
                        for any and all use of your Account. If you have reason to believe that the security of your Account has been
                        compromised, please notify us immediately in accordance with the "Contacting Us" section below.
                    </p>
                    <p>
                        When using the Service, you may choose not to provide us with certain information, but this may limit the features
                        you are able to use or may prevent you from using the Service all together. You may also choose to opt out of
                        receiving certain communications (e.g., newsletters, promotions) by emailing us your preference and or adjusting
                        your profile settings to limit communications from the app (e.g., SMS, push and emails). Please note that even if
                        you opt out, we may still send you Service-related communications.
                    </p>
                    <p>
                        Supplements, amendments and other modifications to this Privacy Policy will be posted on this or a similar page of
                        the Service, and shall be deemed effective as of the "Last Updated" date; provided, however, that MagellanGO will
                        notify you and/or require you to accept the updated Privacy Policy if the supplemented, amended or otherwise
                        modified Privacy Policy implements material changes from MagellanGO's then-current Privacy Policy. It is your
                        responsibility to carefully review this Privacy Policy each time you visit, access or use the Service.
                    </p>
                    <h2 className="content-subheader">Contacting Us</h2>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us by email at help@magellan-go.com or by
                        regular mail at:
                    </p>
                    <p>
                        MagellanGO LLC, <br />
                        1055 Parsippany Blvd,<br />
                        Suite 303, Parsippany,<br />
                        New Jersey 07054<br />
                        USA Attn: Privacy Officer
                    </p>
                </div>
            </div>
        </div>
    )
}


export default PrivacyPolicies