import React from 'react';
import './refund-policies.css';

const RefundPolicies = () => {
    return (
        <div className="refund-policies-component">
            <div className="refund-policies-container">
                <div className="refund-policies-header">
                    <h1>MagellanGo Refund Policy</h1>
                    <h3>Last Updated: 10/20/2021</h3>
                </div>
                <div className="refund-policies-content">

                    <h2 className="content-subheader">Notice for all Customers</h2>
                    <p>
                        This Refund Policy only applies to Service Fees collected by the MagellanGO platform for services provided by MagellanGO.
                    </p>
                    <p>
                        Please read this Agreement carefully because it sets forth the important terms you will need to know about the Service. In this Agreement, the terms “you” and “yours” refer to the person using the Service.  THE TERMS AND CONDITIONS ARE SUBJECT TO CHANGE AS PROVIDED HEREIN.
                    </p>
                    <h2 className="content-subheader">Interpretation</h2>
                    <p>
                        Unless otherwise defined, defined terms in this Refund Policy shall have the meaning given to them earlier in these Terms and Conditions.
                    </p>
                    <p>
                        <b>"Application":</b> means a form to be completed by a person (s) or You and/or Your family (as applicable) requesting the provision of Services.
                    </p>
                    <p>
                        <b>"Service Fees":</b> mean the fees payable by You for the Services purchased in accordance with the Services Terms and Conditions.  Service Fees apply for a request to enter a country (can be referred to as eVisa Fee, Electronic Visa Fee, Health Visa Fee, Tourist Visa Fee, Business Visa Fee, Travel Visa Fee, Transit Visa Fee, Health Pass Fee, Travel Pass, Tourist Fee, Health Fee, Travel fee and/or other travel related fees charged by the Government).
                    </p>
                    <p>
                        <b>"Visa/Approval":</b> Authorization provided by a Government for You and/or Your family (as applicable) to be granted the rights to travel to their Country.
                    </p>
                    <p>
                        <b>"Issuing Bank":</b> means the bank who has issued the utilized debit or credit card to You as card holder.
                    </p>
                    <p>
                        <b>"Refund Eligibility Criteria":</b> means the criteria set out in the below Section.
                    </p>
                    <p>
                        <b>"Services Terms and Conditions":</b> means the terms and conditions for the use of the payment portal and provision of the Services offered by MagellanGO to its customers.
                    </p>
                    <p>
                        <b>"Chargeback":</b> means where a visa customer or You (as applicable) using the online payment portal makes a complaint to his/her card issuer bank that a transaction is disputed and/or fraudulent.
                    </p>
                    <p>
                        <b>"Transaction Charges":</b> means any Service Fees levied by a bank or financial institution upon processing a valid refund request to You which may vary from case to case.
                    </p>
                    <p>
                        <b>"Us":</b> means MagellanGO or any party acting on Our implicit instructions (with "Our" and “We” being construed accordingly).
                    </p>
                    <p>
                        <b>"You"</b> includes the person requesting and/or purchasing the Services or any party acting on such person's instructions (and "Your" shall be construed accordingly).
                    </p>
                    <p>
                        <b>"App &amp; Website":</b> means the MagellanGO Android and iOS Apps and website
                    </p>
                    <p>
                        <b>"App and Website Terms and Conditions":</b> means the terms and conditions applicable to use of the App and Website set out
                    </p>
                    <h2 className="content-subheader">General Refund Conditions</h2>
                    <p>
                        1.	This Refund Policy is subject to change by MagellanGO and in the event of any revisions and amendments, MagellanGO shall make a suitable announcement on its App and Website. The changes will become effective immediately after MagellanGO has given notice. If You do not wish to accept the revised policy, You should not continue to use the Services. If You continue to use the Services after the date on which the change comes into effect, your use of the Services shall constitute your acceptance and agreement to be bound by the  revised or amended Refund Policy.
                    </p>
                    <p>
                        2.	All Service Fees Charged on MagellanGO are Non-Refundable.
                    </p>
                    <p>
                        3.	Only excess payments will be refunded in full to the customers and there will be no exception to such refunds.
                    </p>
                    <p>
                        4.	In case MAGELLANGO has charged You incorrect Service Fees, which are less than the actual amount that should have been charged, then MAGELLANGO will be responsible with no additional impact to You.
                    </p>
                    <p>
                        5.	In case MAGELLANGO have overcharged Service Fees to You, then any such excess amount will be duly refunded.
                    </p>
                    <p>
                        6.	All the above will form part of the Terms of Use of the service and will be available to You to confirm at the time of making payment for these services.
                    </p>
                    <h2 className="content-subheader">Service Fees</h2>
                    <p>
                        1.	As a general rule Service Fees shall not be refundable.
                    </p>
                    <p>
                        2.	In case You have paid incorrect Service Fees, then You will have the ability to cancel and seek refund of such Service Fees paid and allowed to pay the correct Service Fees.
                    </p>
                    <p>
                        3.	The processing times for applications may vary as the processing of your application is subject to the procedures and scheduling timescales of the respective Government and their Embassy / Consulate / High Commission. MagellanGO has no control over the Government’s application approval process, and, therefore, any such delay is not an acceptable cause  for securing a refund.
                    </p>
                    <h2 className="content-subheader">Criteria for Service Fees</h2>
                    <p>
                        1.	Refunds of Service Fees will only be considered where You satisfy the Refund Eligibility Criteria in full. If You (or Your Family as applicable) fail to meet any of the Refund Eligibility Criteria, MagellanGO shall have the right in its sole discretion to decline the request for a refund.
                    </p>
                    <p>
                        2.	The service shall be deemed to be actually delivered in full upon completion of the online/in App application.
                    </p>
                    <p>
                        3.	To apply for a refund, You must properly and fully complete and submit a Refund Request Form. All refund requests should be submitted to MAGELLANGO by You through the online form Refund Request Form.
                    </p>
                    <p>
                        4.	MagellanGO shall process your Refund Request following receipt as soon as is reasonably practicable. Response times will vary depending on the mode of payment and stated reasons for requesting a refund of the Service Fees. In any event, MagellanGO shall notify You of the outcome of the request in accordance with the timescales set out in the Refund Processing Timelines.
                    </p>
                    <p>
                        5.	By filling in and submitting the Refund Request Form, the payment of a refund for Service Fees is not guaranteed. The refund will only be approved or rejected by MagellanGO after verification based on the Refund Eligibility Criteria.
                    </p>
                    <p>
                        6.	Any Transaction Charges or loss due to exchange rate fluctuation which arise upon processing a refund of Service Fees shall be borne solely by You. Service Fees charged by the Bank for processing the original charge will be deducted from the final amount to be refunded. Loss due to bank processing charges and difference in exchange rate will be borne by You and MAGELLANGO is not liable to pay any interest on this amount.
                    </p>
                    <h2 className="content-subheader">Refund Eligibility Criteria</h2>
                    <p>
                        1.	<b>All requests for refunds or cancellation of a Service(s) will only be accepted where:</b>
                    </p>
                    <p>
                        •	It is clear a customer has been charged more than once for the same service.
                    </p>
                    <p>

                    </p>
                    <p>
                        2.	<b>Requests for refunds or cancellation of a Service(s) will not be accepted where:</b>
                    </p>
                    <p>
                        •	Your Application was not successful and a Visa/Approval was not issued.
                    </p>
                    <p>
                        •	You decide not to use the Visa/Approval issued or do not travel.
                    </p>
                    <h2 className="content-subheader">Refund Processing Timeline:</h2>
                    <p>
                        1.	You must send the Refund Request Form to MagellanGO as instructed above.
                    </p>
                    <p>
                        2.	Within five (5) working days of receipt of the Refund Request From, MagellanGO shall contact You to request any further information (if required) and You shall provide such information as soon as reasonably practicable; however, in any event no later than seven (7) working days following such request.
                    </p>
                    <p>
                        3.	Within seven (7) working days following the receipt of any additional information requested by MagellanGO (or where no further information is requested, five (5) working days from receipt of Your Refund Request Form), MagellanGO shall notify You by e-mail of the decision on whether You are eligible for a refund or not.
                    </p>
                    <p>
                        4.	Refunds of Service Fees will be processed within fifteen (15) working days from the date of notifying You of the decision.
                    </p>
                    <p>
                        5.	You will have no recourse to a refund of the Service Fees if MagellanGO suspects You have, or are, engaged in, or have in any way been involved in, fraudulent or illegal activity on the App or Website.
                    </p>
                    <h2 className="content-subheader">Receiving Your Refund</h2>
                    <p>
                        Refunds will be processed by the same method as payment was made. For example, if payment was made via use of credit or debit card, refund will also be done via the same credit/debit card. If MAGELLANGO is unable to process a refund by the same mode in which payment has been made, MAGELLANGO will notify You, of an alternate mode of payment for processing such refund. In case of payments by debit/credit card, the exact date by which refund amount will be credited in the account of the customer’s bank card shall depend on the bank card issuer but not more than Thirty (30) calendar days.
                    </p>
                    <h2 className="content-subheader">Chargebacks</h2>
                    <p>
                        Chargebacks: We expect You to contact MagellanGO using our online feedback form to resolve any problem or issue related to your application and payment, before You make any Chargeback request to your bank. This Section does not affect any rights which You may have against the issuer of a debit or credit card. MagellanGO will investigate any Chargeback requests made by You and in response will inform Your Issuing Bank whether any services have been cancelled.
                    </p>
                    <p>
                        If you reverse a payment (Chargeback) for your application:
                    </p>
                    <p>
                        •	your application might become invalid
                    </p>
                    <p>
                        •	your application might be cancelled
                    </p>
                    <p>
                        •	you might owe a debt to the Country to which you are applying.
                    </p>
                    <p>
                        If you owe a debt to the Country of Application, they as well as MagellanGO, acting on their behalf, can pursue this debt. The debt might affect the outcome of any future visa applications as well.
                    </p>
                    <h2 className="content-subheader">Have a question?  Contact Us:</h2>
                    <p>
                        If you have any questions or concerns about this Refund Policy, please contact us by email at <a href="mailto:help@magellan-go">help@magellan-go</a>.com or you may reach us at MagellanGO LLC, 1055 Parsippany Blvd, Suite 303, Parsippany, New Jersey 07054 USA.
                    </p>
                </div>
            </div>
        </div>
    )
}


export default RefundPolicies