import React, { useEffect } from 'react';
import './single-city-trip.css';

import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GLOBAL_DATA } from '../../../../../config/global-data';

const SingleCityTrip = () => {

    const [tripValue, setTripValues] = React.useState({
        travelDestination: GLOBAL_DATA.SELECTED_DESTINATION,
        travelStartDate: null,
        travelEndDate: null,
        transitLocation1: '',
        transitLocation2: ''
    })

    const [familyMembers, setFamilyMembers] = React.useState([
        {
            imgUrl: "/assets/images/users/user-0.png",
            name: "John Smith",
            selected: true
        },
        {
            imgUrl: "/assets/images/users/user-1.png",
            name: "Rose Smith",
            selected: true
        },
        {
            imgUrl: "/assets/images/users/user-2.png",
            name: "Kelsey Smith",
            selected: false
        },
        {
            imgUrl: "/assets/images/users/user-3.png",
            name: "Aiden Smith",
            selected: false
        }
    ])

    const handleTripValueChange = (key) => (event) => {
        setTripValues({ ...tripValue, [key]: event.target.value });
    };

    const handleDateInputChange = (e, key) => {
        setTripValues({ ...tripValue, [key]: e });
    }

    const updateSelectedMembers = (index) => {
        let tempSelectedMembers = familyMembers.map(
            (val, i) => {
                if (i === index) {
                    val.selected = !val.selected;
                    return val;
                }
                else {
                    return val;
                }
            }
        )
        setFamilyMembers(tempSelectedMembers);
    }

    return (
        <div className="single-city-trip-container">
            <div className="single-city-trip-content-column">
                <p className="form-header">Trip Details</p>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="create-trip-travel-destination">Travel Destination</InputLabel>
                        <Select
                            id="create-trip-travel-destination"
                            value={tripValue.travelDestination}
                            onChange={handleTripValueChange('travelDestination')}
                            label="TravelDestination"
                            readOnly={GLOBAL_DATA.SELECTED_DESTINATION ? true : false}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">India</MenuItem>
                            <MenuItem value="2">United States</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="mui-form-control mui-date-control">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                            label="Travel Start Date *"
                            value={tripValue.travelStartDate}
                            onChange={(e) => { handleDateInputChange(e, 'travelStartDate') }}
                            renderInput={(params) => <TextField {...params} />}
                            closeOnSelect={true}
                        />
                    </LocalizationProvider>
                </div>
                <div className="mui-form-control mui-date-control">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                            label="Travel End Date *"
                            value={tripValue.travelEndDate}
                            onChange={(e) => { handleDateInputChange(e, 'travelEndDate') }}
                            renderInput={(params) => <TextField {...params} />}
                            closeOnSelect={true}
                        />
                    </LocalizationProvider>
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="create-trip-transit-location-1">Transit Location 1</InputLabel>
                        <Select
                            id="create-trip-transit-location-1"
                            value={tripValue.transitLocation1}
                            onChange={handleTripValueChange('transitLocation1')}
                            label="transitLocation1"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">India</MenuItem>
                            <MenuItem value="2">United State Of America</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="create-trip-transit-location-2">Transit Location 2</InputLabel>
                        <Select
                            id="create-trip-transit-location-2"
                            value={tripValue.transitLocation2}
                            onChange={handleTripValueChange('transitLocation2')}
                            label="transitLocation2"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">India</MenuItem>
                            <MenuItem value="2">United State Of America</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="single-city-trip-content-column">
                <p className='simple-text'>Add family members traveling with you on this trip:</p>
                <div className="family-members-list">
                    {
                        familyMembers.map(
                            (eachMember, index) => {
                                return (
                                    <div className={eachMember.selected ? "each-member selected-member" : "each-member"} key={index}>
                                        <img className="member-img" src={eachMember.imgUrl} alt={eachMember.name} />
                                        <div className="member-name-row">
                                            <p>{eachMember.name}</p>
                                            {
                                                eachMember.selected ?
                                                    <img src="/assets/icons/cross.png" alt="del" onClick={() => { updateSelectedMembers(index) }} />
                                                    : <img src="/assets/icons/add.png" alt="add" onClick={() => { updateSelectedMembers(index) }} />
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
                <div className="right-align">
                    <button className="default-btn submit-btn" onClick={() => { }}>SUBMIT</button>
                </div>
            </div>
        </div>
    )
}

export default SingleCityTrip;