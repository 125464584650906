import React from 'react';
import './delete-profile.css';

import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// import { LOGGED_USER } from '../../../../../config/user-data';
import { SELECT_OPTIONS } from '../../../../../config/select-options';
import { filterFromOptions } from '../../../../../services/utilities';

const DeleteProfile = () => {
    const navigate = useNavigate();
    const [profileValues, setProfileValues] = React.useState({
        firstName: 'John',
        middleInitial: '',
        lastName: 'Smith',
        suffix: '1',
        dob: moment(new Date()),
        gender: '1',
        profilePicSrc: '/assets/images/users/user-0.png',
        profilePicName: '',
        country: '1',
        street1: 'Street 1',
        street2: 'Street 2',
        city: 'City',
        zip: 'Zip',
        state: 'State',
        province: 'Province',
        addressSameAsAdminFlag: false
    });

    const deleteUserProfile = () => {
        navigate('/dashboard/user-settings/family-profiles/all-members')
    }

    return (
        <div className="delete-profile-component">
            <Container maxWidth="lg">
                <div className="delete-profile-container">
                    <div className="delete-profile-form">
                        <Typography variant="h4">
                            Delete Member's Profile
                        </Typography>

                        <div id="confirm-details" className="stepper-sections">
                            <div className="confirmation-content">
                                <img className="profile-img" src={profileValues.profilePicSrc} alt="Upload Pic" />
                                <div className="details two-col-grid">
                                    <div>
                                        <p className='field-label'>Full Name: </p>
                                        <p>{profileValues.firstName + " " + profileValues.middleInitial + " " + profileValues.lastName}</p>
                                    </div>
                                    <div>
                                        <p className='field-label'>Suffix: </p>
                                        <p>{filterFromOptions(profileValues.suffix, SELECT_OPTIONS.SUFFIX).displayValue}</p>
                                    </div>
                                    <div>
                                        <p className='field-label'>Gender: </p>
                                        <p>{filterFromOptions(profileValues.gender, SELECT_OPTIONS.GENDER).displayValue}</p>
                                    </div>
                                    <div>
                                        <p className='field-label'>DOB: </p>
                                        <p>{profileValues.dob ? profileValues.dob.format('MM/DD/YYYY') : "N/A"}</p>
                                    </div>
                                    <div>
                                        <p className='field-label'>Street: </p>
                                        <p>{profileValues.street1 + " " + profileValues.street2}</p>
                                    </div>
                                    <div>
                                        <p className='field-label'>City: </p>
                                        <p>{profileValues.city}</p>
                                    </div>
                                    <div>
                                        <p className='field-label'>State: </p>
                                        <p>{profileValues.state}</p>
                                    </div>
                                    <div>
                                        <p className='field-label'>Province: </p>
                                        <p>{profileValues.province}</p>
                                    </div>
                                    <div>
                                        <p className='field-label'>Zip: </p>
                                        <p>{profileValues.zip}</p>
                                    </div>
                                    <div>
                                        <p className='field-label'>Country: </p>
                                        <p>{filterFromOptions(profileValues.country, SELECT_OPTIONS.RESIDENCE_COUNTRY).displayValue}</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="stepper-buttons">
                            <button className="prev-btn" onClick={() => { navigate('/dashboard/user-settings/family-profiles/all-members') }}>BACK</button>
                            <button className="next-btn" onClick={() => { deleteUserProfile() }}>DELETE PROFILE</button>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default DeleteProfile;