import React from 'react';
import { useNavigate } from 'react-router-dom';
import './home.css';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { GLOBAL_DATA } from '../../../config/global-data';

const Home = (props) => {
    const homeTabItems = [
        {
            groupHeader: "APPLY FOR VISA",
            groupItems: [
                {
                    itemHeader: "Identity Verification",
                    itemIcon: "/assets/nav-icons/evisa/identity-verification-icon.png",
                    itemLink: "/dashboard/evisa/identity-verification"
                },
                {
                    itemHeader: "Apply for Visa",
                    itemIcon: "/assets/nav-icons/evisa/apply-evisa-icon.png",
                    itemLink: "/dashboard/evisa/application-form"
                },
                // {
                //     itemHeader: "Proof of Vaccination",
                //     itemIcon: "/assets/nav-icons/evisa/vaccination-proof-icon.png",
                //     itemLink: "/dashboard/evisa/vaccination-proof"
                // },
                {
                    itemHeader: "Visa Payment",
                    itemIcon: "/assets/nav-icons/evisa/payment-icon.png",
                    itemLink: "/dashboard/evisa/shopping-cart"
                }
            ]
        },
        {
            groupHeader: "GET YOUR FASTPASS",
            groupItems: [
                {
                    itemHeader: "Create Trip",
                    itemIcon: "/assets/nav-icons/travel/create-trip-icon.png",
                    itemLink: "/dashboard/travel/create-trip"
                },
                {
                    itemHeader: "Travel Checklist",
                    itemIcon: "/assets/nav-icons/travel/travel-checklist-icon.png",
                    itemLink: "/dashboard/travel/travel-checklist"
                },
                {
                    itemHeader: "Travel Documents",
                    itemIcon: "/assets/nav-icons/travel/travel-documents-icon.png",
                    itemLink: "/dashboard/travel/travel-documents"
                },
                {
                    itemHeader: "Health Documents",
                    itemIcon: "/assets/nav-icons/travel/health-documents-icon.png",
                    itemLink: '/dashboard/travel/health-documents'
                },
                {
                    itemHeader: "Travel Insurance",
                    itemIcon: "/assets/nav-icons/travel/travel-insurance-icon.png",
                    itemLink: "/dashboard/travel/travel-insurance"
                },
                {
                    itemHeader: "Landing Fee",
                    itemIcon: "/assets/nav-icons/travel/payment-icon.png",
                    itemLink: "/dashboard/travel/payment"
                },
                {
                    itemHeader: "Government Approval",
                    itemIcon: "/assets/nav-icons/travel/government-approval-icon.png",
                    itemLink: "/dashboard/travel/government-approval"
                }
            ]
        },
        {
            groupHeader: "GROUND TRANSPORTATION, MARKETPLACE AND AIRPORT INFORMATION",
            groupItems: [
                {
                    itemHeader: "Ground Transportation",
                    itemIcon: "/assets/nav-icons/market-place/taxi-icon.png",
                    itemLink: "/dashboard/market-place/transportation"
                },
                {
                    itemHeader: "Airport Dine & Shop",
                    itemIcon: "/assets/nav-icons/market-place/payment-icon.png",
                    itemLink: "/dashboard/market-place/airport-dine-and-shop"
                },
                {
                    itemHeader: "Plan Your Excursions",
                    itemIcon: "/assets/nav-icons/market-place/plan-your-trip-icon.png",
                    itemLink: "/dashboard/market-place/plan-your-excursions"
                },
                {
                    itemHeader: "Airport Map",
                    itemIcon: "/assets/nav-icons/market-place/airport-info-icon.png",
                    itemLink: "/dashboard/market-place/airport-map"
                }
            ]
        },
        {
            groupHeader: "USER SETTINGS",
            groupItems: [
                {
                    itemHeader: "Family Profile(s)",
                    itemIcon: "/assets/nav-icons/settings/profiles-icon.png",
                    itemLink: "/dashboard/user-settings/family-profiles"
                },
                {
                    itemHeader: "My Dashboard",
                    itemIcon: "/assets/nav-icons/settings/user-dashboard-icon.png",
                    itemLink: "/dashboard/user-settings/user-dashboard"
                },
                {
                    itemHeader: "Settings",
                    itemIcon: "/assets/nav-icons/settings/settings-icon.png",
                    itemLink: "/dashboard/user-settings/settings"
                }
            ]
        }
    ]

    const navigate = useNavigate();

    const [selectedTrip, setSelectedTrip] = React.useState(GLOBAL_DATA.SELECTED_TRIP);
    const [travelDestination, setTravelDestination] = React.useState(GLOBAL_DATA.SELECTED_DESTINATION);

    const updateSelectedTrip = (val) => {
        GLOBAL_DATA.SELECTED_TRIP = val;
        setSelectedTrip(val);
    }

    const updateSelectedDestination = (val) => {
        GLOBAL_DATA.SELECTED_DESTINATION = val;
        setTravelDestination(val);
    }

    return (
        <div className="home-component">
            <div className="input-options-container">
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="select-travel-destination">Select Travel Destination *</InputLabel>
                        <Select
                            id="select-travel-destination"
                            value={travelDestination}
                            onChange={(e) => { updateSelectedDestination(e.target.value) }}
                            label="Select Travel Destination"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">India</MenuItem>
                            <MenuItem value="2">United States</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="select-trip">Select Trip *</InputLabel>
                        <Select
                            id="select-trip"
                            value={selectedTrip}
                            onChange={(e) => { updateSelectedTrip(e.target.value) }}
                            label="Select Trip"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">Las Vegas (03/12/2022 - 03/22/2022)</MenuItem>
                            <MenuItem value="2">New York (04/05/2022 - 04/24/2022)</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="home-tabs-container">
                {homeTabItems.map((eachGroup, groupIndex) => {
                    return (
                        <div className="each-nav-items-block" key={groupIndex}>
                            <h2 className="nav-items-block-header">{eachGroup.groupHeader}</h2>
                            <div className="home-tab-items" >
                                {eachGroup.groupItems.map((eachTabItem, tabItemIndex) => {
                                    return (
                                        <div className="each-tab-item" key={tabItemIndex} onClick={() => { navigate(eachTabItem.itemLink) }}>
                                            <img src={eachTabItem.itemIcon} alt={eachTabItem.itemHeader} />
                                            <h3>{eachTabItem.itemHeader}</h3>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Home;
