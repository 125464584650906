import React from 'react';
import './government-approval.css';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import AllVaccineTakenList from './all-vaccines-taken-list/all-vaccines-taken-list';

const GovernmentApproval = () => {
    const [showVaccineListComp, setShowVaccineListComp] = React.useState(false);
    const [showItemImage, setShowItemImage] = React.useState(false);
    const [selectedMember, setSelectedMember] = React.useState("");
    const [travelDestination, setTravelDestination] = React.useState("");
    const [contentImgSrc, setContentImgSrc] = React.useState("");

    const toggleVaccineListComp = () => {
        setShowVaccineListComp(!showVaccineListComp);
    }

    const toggleItemImage = (imgSrc) => {
        setContentImgSrc(imgSrc);
        setShowItemImage(!showItemImage);
    }

    return (
        <div className="government-approval-component">
            <div className="government-approval-header-section">
                <p className='government-approval-header'>GOVERNMENT APPROVAL</p>
            </div>
            <div className="government-approval-contents">
                <div className="government-approval-column slide-right">
                    <div className="mui-form-control">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="select-travel-destination">Select Travel Destination *</InputLabel>
                            <Select
                                id="select-travel-destination"
                                value={travelDestination}
                                onChange={(e) => { setTravelDestination(e.target.value) }}
                                label="Select Family Member"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="1">India</MenuItem>
                                <MenuItem value="2">United States</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="mui-form-control">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel id="select-family-member">Select Family Member *</InputLabel>
                            <Select
                                id="select-family-member"
                                value={selectedMember}
                                onChange={(e) => { setSelectedMember(e.target.value) }}
                                label="Select Family Member"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="1">John Smith</MenuItem>
                                <MenuItem value="2">Rose Smith</MenuItem>
                                <MenuItem value="3">Kelsey Smith</MenuItem>
                                <MenuItem value="4">Aiden Smith</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="two-col-row">
                        <div className="grid-image-container">
                            <img src="/assets/images/users/user-0.png" alt="User"></img>
                        </div>
                        <div className="grid-image-container">
                            <img src="/assets/images/qr-codes/default.png" alt="User"></img>
                        </div>
                    </div>
                    <div className="user-approval-details">
                        <p><b>Name:</b> John Smith</p>
                        <p><b>Age:</b> 31</p>
                        <p><b>Country of Residence:</b> Canada</p>
                        <p><b>Dates:</b> 12/21/2023 - 01/05/2024</p>
                    </div>
                </div>
                <div className="government-approval-column slide-left">
                    <ul className="approval-data-list">
                        <li>
                            <p><b>Identity Verification:</b> Verified</p>
                        </li>
                        <li>
                            <p><b>Visa:</b> Approved</p>
                            <img src="/assets/icons/gallery.png" alt="icon" onClick={() => { toggleItemImage("/assets/images/government-approval/John Smith - Visa Approval.png") }}/>
                        </li>
                        <li>
                            <p><b>Immigration Card:</b> Completed</p>
                            <img src="/assets/icons/edit-note.png" alt="icon" onClick={ () => { toggleItemImage("/assets/images/government-approval/John Smith - Immigration Card.png") } }/>
                        </li>
                        <li>
                            <p><b>Customs Card:</b> Completed</p>
                            <img src="/assets/icons/edit-note.png" alt="icon" onClick={() => { toggleItemImage("/assets/images/government-approval/John Smith - Customs Card.png") }}/>
                        </li>
                        <li>
                            <p><b>Vaccination:</b> Fully Vaccinated</p>
                            <img src="/assets/icons/gallery.png" alt="icon" onClick={() => { toggleVaccineListComp() }} />
                        </li>
                        <li>
                            <p><b>PCR Test:</b> Negative</p>
                            <img src="/assets/icons/gallery.png" alt="icon" onClick={() => { toggleItemImage("/assets/images/government-approval/John Smith - PCR Test.png") }}/>
                        </li>
                        {/* <li>
                            <p className="danger"><b>Temperature:</b> 100.2</p>
                        </li> */}                        

                    </ul>
                    {showVaccineListComp ? <AllVaccineTakenList onCloseBtnClick={toggleVaccineListComp} /> : ""}
                    {
                    showItemImage ? 
                    <>
                        <div className='content-container'>
                            <img src="/assets/icons/close-icon.png" className="close-icon" alt="Close" onClick={() => { toggleItemImage("") }} />
                            <div className='img-container'>
                                <img src={contentImgSrc} className="content-img" alt="Content" />
                            </div>
                        </div>
                    </>
                    : <></>}
                </div>

            </div>
        </div>

    )
}

export default GovernmentApproval
