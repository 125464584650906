import React from 'react';
import './health-documents.css';
import HealthSurvey from './health-survey/health-survey';
import TestsUploadForm from './tests-upload-form/tests-upload-form';
import VaccinationUploadForm from './vaccination-upload-form/vaccination-upload-form';


const HealthDocuments = (props) => {

    const [index, setIndex] = React.useState(0);

    const handleChange = (newValue) => {
        setIndex(newValue);
    };

    return (
        <div className="health-documents-container">
            <div className="health-documents-header-section">
                <p className='health-documents-header'>HEALTH DOCUMENTS</p>
                <section className="tab-btns">
                    <button className={index === 0 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(0) }}>VACCINES</button>
                    <button className={index === 1 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(1) }}>TESTS</button>
                    <button className={index === 2 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(2) }}>SURVEY</button>
                </section>

            </div>
            <div className="document-form-container">
                { index === 0 ? <VaccinationUploadForm /> : <></>}
                { index === 1 ? <TestsUploadForm /> : <></>}
                { index === 2 ? <HealthSurvey /> : <></>}
            </div>
        </div>
    )
}

export default HealthDocuments;