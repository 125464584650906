import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';

import './landing-page.css';
import Navbar from '../navbar/navbar';

const LandingPage = (props) => {
    let navigate = useNavigate();

    return (
        <div className="landing-page-component">
            <Navbar />
            <div className="main-screen">
                <Container maxWidth="lg">
                    <div className="landing-page-content">
                        <div className="app-intro">
                            <h1 className="header-1">MagellanGO</h1>
                            <h2 className="header-2">Travel Reimagined</h2>
                            <div className="divider"></div>
                            <p className="description">
                                MagellanGO, a secure, single-app travel wallet platform, is a personal document management system for 
                                travelers to store all their travel documents for border entry including Identity Verification, Visa Approval, 
                                Immigration and Customs Forms, Health Data, Payment Approval, Travel Insurance and much, much more.   <br />
                            </p>
                            <div className="intro-button">
                                <button onClick={() => { navigate("/auth/register") }}>Get Started</button>
                            </div>
                        </div>
                        <div className="landing-image-container bg-box-clip">
                            <img className="landing-image" src="/assets/design-clips/around_the_world.svg" alt="Around the world" />
                        </div>
                    </div>
                </Container>
            </div>
            <div className="video-container" id="get-started">
                <Container maxWidth="lg">
                    <div className="landing-page-content">
                        <video src="/assets/videos/landing-page-video-trim.mp4" autoPlay={true} loop={true} controls={true}></video>
                        <div className="instructions">
                            <div className="instruction-head">
                                <h2>It's simple...</h2>
                                <div className="stepping-img">
                                    <img src="/assets/design-clips/steps.png" alt="Steps" />
                                </div>
                            </div>
                            <ol className="steps-list">
                                <li>Download the App and create a profile</li>
                                <li>Add family</li>
                                <li>Fill-in Immigration Card, upload travel documents, and select insurance plan</li>
                                <li>Pay tourist visa fee and you are ready to begin your journey</li>
                            </ol>
                        </div>
                    </div>
                </Container>
            </div>
        </div >
    )
}

export default LandingPage;
