import React from 'react';
import './view-profile.css';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

const ViewProfiles = (props) => {
    
    const navigate = useNavigate();

    return (
        <div className="view-profiles-component">
            <h2 className="component-header">
                FAMILY PROFILE(S) 
                <button className="header-action-btn" onClick={() => { navigate('/dashboard/user-settings/family-profiles/add') }}>ADD</button>
            </h2>
            <div className="family-profiles-container">
                {props.familyMembers.map((eachMember, memberIndex) => {
                    return (
                        <div className="each-profile-block" key={memberIndex}>
                            <div className="picture-container">
                                <img src={eachMember.image} alt={eachMember.name} />
                            </div>
                            <div className="member-content">
                                <h3 className="name">{eachMember.name}</h3>
                                <h4 className="relation">{eachMember.relationship}</h4>
                            </div>
                            <ul className="action-btns">
                                {/* <li onClick={() => { navigate('/dashboard/user-settings/family-profiles/view') }}><VisibilityIcon /></li> */}
                                <li onClick={() => { navigate('/dashboard/user-settings/family-profiles/edit') }}><EditIcon /></li>
                                <li onClick={() => { navigate('/dashboard/user-settings/family-profiles/delete') }}><DeleteIcon /></li>
                            </ul>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default ViewProfiles