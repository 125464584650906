import React from 'react';
import './add-profile.css';

import { Checkbox, Container, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DEFAULT_USER_PIC, ACCEPTED_IMAGE_EXTENSIONS } from '../../../../../config/constants';
import { useNavigate } from 'react-router-dom';
// import { LOGGED_USER } from '../../../../../config/user-data';

const AddProfile = () => {
    const navigate = useNavigate();
    const addProfileSteps = ["Personal Information", "Address Information", "Confirmation"];
    const uploadBtnRef = React.createRef();
    const [activeStep, setActiveStep] = React.useState(0);
    const [profileValues, setProfileValues] = React.useState({
        firstName: '',
        middleInitial: '',
        lastName: '',
        suffix: '',
        dob: null,
        gender: '',
        profilePicSrc: DEFAULT_USER_PIC,
        profilePicName: '',
        country: '',
        street1: '',
        street2: '',
        city: '',
        zip: '',
        state: '',
        province: '',
        addressSameAsAdminFlag: false
    });

    const handleProfileDataChange = (key) => (event) => {
        setProfileValues({ ...profileValues, [key]: event.target.value });
    };

    const handleProfileDOBChange = (e) => {
        setProfileValues({ ...profileValues, dob: e });
        // console.log(e)
        // console.log(e.format('MM/DD/YYYY'))
    }

    const toggleFlagChanges = (key) => (event) => {
        setProfileValues({ ...profileValues, addressSameAsAdminFlag: !profileValues.addressSameAsAdminFlag });
    }

    const loadProfilePic = (event) => {
        var input = event.target;
        if (input.files && input.files[0]) {
            let fName = input.files[0].name;
            if (fName.length <= 30) {
                let fNameSplitted = fName.split(".");
                if (ACCEPTED_IMAGE_EXTENSIONS.includes(fNameSplitted[fNameSplitted.length - 1].toLowerCase())) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        setProfileValues({ ...profileValues, profilePicName: fName, profilePicSrc: e.target.result });
                    };
                    reader.readAsDataURL(input.files[0]);
                }
                else {

                }
            }
            else {

            }
        }
    }

    const addUserProfile = () => {
        navigate('/dashboard/user-settings/family-profiles/all-members')
    }

    return (
        <div className="add-profile-component">
            <Container maxWidth="lg">
                <div className="add-profile-container">
                    <div className="add-profile-form">
                        <Typography variant="h4">
                            Add Member's Profile
                        </Typography>
                        <div className="stepper-container">
                            <Stepper activeStep={activeStep}>
                                {addProfileSteps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </div>
                        {
                            activeStep === 0
                                ? <div id="personal-details" className="stepper-sections two-col-grid">
                                    <div className="profile-pic-container">
                                        <img className="profile-img" src={profileValues.profilePicSrc} alt="Upload Pic" onClick={() => { uploadBtnRef.current.click() }} />
                                        <img className="upload-icon-img" src="/assets/icons/edit-upload.png" alt="Upload Pic" onClick={() => { uploadBtnRef.current.click() }} />
                                        <input type="file" ref={uploadBtnRef} className="hide-obj p-abs" onChange={(event) => { loadProfilePic(event) }} />
                                    </div>
                                    <div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="add-profile-firstname">First Name (as in passport) *</InputLabel>
                                                <Input
                                                    id="add-profile-firstname"
                                                    type="text"
                                                    autoFocus={true}
                                                    value={profileValues.firstName}
                                                    onChange={handleProfileDataChange('firstName')}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="add-profile-middleinitial">Middle Initails</InputLabel>
                                                <Input
                                                    id="add-profile-middleinitial"
                                                    type="text"
                                                    value={profileValues.middleInitial}
                                                    onChange={handleProfileDataChange('middleInitial')}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="add-profile-lastname">Surname (as in passport) *</InputLabel>
                                                <Input
                                                    id="add-profile-lastname"
                                                    type="text"
                                                    value={profileValues.lastName}
                                                    onChange={handleProfileDataChange('lastName')}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="add-profile-suffix">Suffix</InputLabel>
                                                <Select
                                                    id="add-profile-suffix"
                                                    value={profileValues.suffix}
                                                    onChange={handleProfileDataChange('suffix')}
                                                    label="Suffix"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value="1">I</MenuItem>
                                                    <MenuItem value="2">II</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel id="add-profile-gender">Gender *</InputLabel>
                                                <Select
                                                    id="add-profile-gender"
                                                    value={profileValues.gender}
                                                    onChange={handleProfileDataChange('gender')}
                                                    label="Gender"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value="1">Male</MenuItem>
                                                    <MenuItem value="2">Female</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control mui-date-control">
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <MobileDatePicker
                                                    label="Date Of Birth (MM/DD/YYYY)*"
                                                    value={profileValues.dob}
                                                    onChange={handleProfileDOBChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    closeOnSelect={true}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            activeStep === 1
                                ? <div id="address-details" className="stepper-sections">
                                    <div className="mui-form-control">
                                        <FormControlLabel control={<Checkbox checked={profileValues.addressSameAsAdminFlag} onChange={toggleFlagChanges('addressSameAsAdminFlag')} />} label="Address same as Admin?" />
                                    </div>
                                    <div className="mui-form-control">
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="add-profile-country">Country of Residence *</InputLabel>
                                            <Select
                                                id="add-profile-country"
                                                value={profileValues.country}
                                                onChange={handleProfileDataChange('country')}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="1">India</MenuItem>
                                                <MenuItem value="2">United States of America</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="two-col-grid">
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="add-profile-street1">Street1 *</InputLabel>
                                                <Input
                                                    id="add-profile-street1"
                                                    type="text"
                                                    value={profileValues.street1}
                                                    onChange={handleProfileDataChange('street1')}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="add-profile-street2">Street 2 (Apt. / Unit Number )</InputLabel>
                                                <Input
                                                    id="add-profile-street2"
                                                    type="text"
                                                    value={profileValues.street2}
                                                    onChange={handleProfileDataChange('street2')}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="add-profile-city">City *</InputLabel>
                                                <Input
                                                    id="add-profile-city"
                                                    type="text"
                                                    value={profileValues.city}
                                                    onChange={handleProfileDataChange('city')}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="add-profile-zip">Zip *</InputLabel>
                                                <Input
                                                    id="add-profile-zip"
                                                    type="text"
                                                    value={profileValues.zip}
                                                    onChange={handleProfileDataChange('zip')}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="add-profile-state">State *</InputLabel>
                                                <Input
                                                    id="add-profile-state"
                                                    type="text"
                                                    value={profileValues.state}
                                                    onChange={handleProfileDataChange('state')}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="mui-form-control">
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="add-profile-province">Province *</InputLabel>
                                                <Input
                                                    id="add-profile-province"
                                                    type="text"
                                                    value={profileValues.province}
                                                    onChange={handleProfileDataChange('province')}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                : <></>
                        }
                        {
                            activeStep === 2
                                ? <div id="confirm-details" className="stepper-sections">
                                    <div className="confirmation-content">
                                        <img className="profile-img" src={profileValues.profilePicSrc} alt="Upload Pic" onClick={() => { uploadBtnRef.current.click() }} />
                                        <div className="details two-col-grid">
                                            <div>
                                                <p className='field-label'>Full Name: </p>
                                                <p>{profileValues.firstName + " " + profileValues.middleInitial + " " + profileValues.lastName}</p>
                                            </div>
                                            <div>
                                                <p className='field-label'>Suffix: </p>
                                                <p>{profileValues.suffix}</p>
                                            </div>
                                            <div>
                                                <p className='field-label'>Gender: </p>
                                                <p>{profileValues.gender}</p>
                                            </div>
                                            <div>
                                                <p className='field-label'>DOB: </p>
                                                <p>{profileValues.dob ? profileValues.dob.format('MM/DD/YYYY') : "N/A"}</p>
                                            </div>
                                            <div>
                                                <p className='field-label'>Street: </p>
                                                <p>{profileValues.street1 + " " + profileValues.street2}</p>
                                            </div>
                                            <div>
                                                <p className='field-label'>City: </p>
                                                <p>{profileValues.city}</p>
                                            </div>
                                            <div>
                                                <p className='field-label'>State: </p>
                                                <p>{profileValues.state}</p>
                                            </div>
                                            <div>
                                                <p className='field-label'>Province: </p>
                                                <p>{profileValues.province}</p>
                                            </div>
                                            <div>
                                                <p className='field-label'>Zip: </p>
                                                <p>{profileValues.zip}</p>
                                            </div>
                                            <div>
                                                <p className='field-label'>Country: </p>
                                                <p>{profileValues.country}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                : <></>
                        }

                        <div className="stepper-buttons">
                            <button className={activeStep === 0 ? "prev-btn hide-obj" : "prev-btn"} onClick={() => { setActiveStep(activeStep > 0 ? activeStep - 1 : activeStep) }}>PREV</button>
                            {
                                activeStep === 2 ? <button className="next-btn" onClick={() => { addUserProfile() }}>ADD PROFILE</button>
                                    : <button className="next-btn" onClick={() => { setActiveStep(activeStep < addProfileSteps.length - 1 ? activeStep + 1 : activeStep) }}>NEXT</button>
                            }
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default AddProfile;