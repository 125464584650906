export const validateRegex = (regex, textToMatch) => {
    if (typeof (regex) === typeof ("")) {
        regex = new RegExp(regex);
    }
    return textToMatch ? regex.test(textToMatch) : true;
}

export const getFileType = (filePath) => {
    let dataMap = {
        "png": {
            "type": "image/png"
        },
        "svg": {
            "type": "image/svg"
        },
        "jpg": {
            "type": "image/jpeg"
        },
        "jpeg": {
            "type": "image/jpeg"
        },
        "pdf": {
            "type": "application/pdf"
        }
    }
    let extenseion = filePath.split(".")[filePath.split(".").length - 1];
    return dataMap[String(extenseion).toLowerCase()];
}

export const getFileFromBytes = (byteStr, filePath) => {
    var byteCharacters = atob(byteStr);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    let type = getFileType(filePath);
    var file = new Blob([byteArray], { type: type['type'] });
    return file;
}

export const filterFromOptions = (value, allOptions) => {
    let result = allOptions.filter(
        (val) => {
            if (value === val.value) {
                return val
            }
            else {
                return false
            }
        }
    );

    return result.length > 0 ? result[0] : null;
}