import React, { useEffect } from 'react';
import './departure-form.css';

import { Checkbox, FormControlLabel, TextField} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';

const DepartureForm = (props) => {
    const [ratingItems, setRatingItemsValue] = React.useState([]);
    const [recommendationValue, setRecommendationValue] = React.useState(0);

    useEffect(() => {
        setRatingItemsValue([
            {
                item: 'Hotel Rooms',
                rating: 0
            },
            {
                item: 'Hotel Food',
                rating: 0
            },
            {
                item: 'Hotel Service',
                rating: 0
            },
            {
                item: 'Hotel Staff Attitude',
                rating: 0
            },
            {
                item: 'Hotel Value For Money',
                rating: 0
            },
            {
                item: 'Food Away From Hotel',
                rating: 0
            },
            {
                item: 'Service Away From Hotel',
                rating: 0
            },
            {
                item: 'Attitude Away From Hotel',
                rating: 0
            },
            {
                item: 'Feeling of Personal Safety',
                rating: 0
            },
            {
                item: 'Cleanliness of Country',
                rating: 0
            },
            {
                item: 'Overall Value For Money',
                rating: 0
            }
        ])
    }, [])

    const [departureValues, setDepartureValue] = React.useState({
        departureDate: null
    })

    const handleDepartueDateChange = (e) => {
        setDepartureValue({ ...departureValues, departureDate: e });
    }

    const setRatingForIndex = (val, i) => {
        let tempRatingItems = [...ratingItems]
        tempRatingItems[i].rating = val
        setRatingItemsValue(tempRatingItems);
    }

    const updateRecommendationValue = (e, i) => {
        e.preventDefault();
        setRecommendationValue(i);
    }

    return (
        <div className='departure-form-container'>
            <div className='form-header'>
                <p>IMMIGRATION FORM - DEPARTURE</p>
            </div>
            <form className='departure-form'>
                <div className='two-col-grid'>
                    <section>
                        <div className="mui-form-control mui-date-control">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDatePicker
                                    label="Date Of Departure (MM/DD/YYYY)*"
                                    value={departureValues.departureDate}
                                    onChange={handleDepartueDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                    closeOnSelect={true}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='query-section'>
                            <p className='query-section-header'>
                                Trip Reason:
                            </p>
                            <div className='two-col-grid'>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Beach / Pool /Relax" />
                                </div>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Deep Sea Fishing" />
                                </div>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Diving" />
                                </div>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Sailing" />
                                </div>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Honeymoon" />
                                </div>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Conference / Convention" />
                                </div>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Wedding" />
                                </div>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Visiting Friends / Family" />
                                </div>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Golfing" />
                                </div>
                                <div className="mui-form-control">
                                    <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Other" />
                                </div>
                            </div>
                        </div>
                        <div className='query-section'>
                            <p className='query-section-header'>
                                Would you recommend XYZ to friends and family?
                            </p>
                            <div className='option-btns'>
                                <button className={recommendationValue === 4 ? 'selected': ''} onClick={(e) => { updateRecommendationValue(e,4)}}>Very Likely</button>
                                <button className={recommendationValue === 3 ? 'selected': ''} onClick={(e) => { updateRecommendationValue(e,3)}}>Somewhat Likely</button>
                                <button className={recommendationValue === 2 ? 'selected': ''} onClick={(e) => { updateRecommendationValue(e,2)}}>Somewhat Unlikely</button>
                                <button className={recommendationValue === 1 ? 'selected': ''} onClick={(e) => { updateRecommendationValue(e,1)}}>Not at all Likely</button>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className='query-section'>
                            <p className='query-section-header'>
                                Based on your expectations, Rate your experience:
                            </p>
                            {
                                ratingItems.map(
                                    (eachItem, i) => {
                                        return (
                                            <div className='rating-item' key={i}>
                                                <span className='item-text'>{eachItem.item}</span>
                                                <Rating
                                                    value={eachItem.rating}
                                                    onChange={(event, newValue) => {
                                                        setRatingForIndex(newValue, i);
                                                    }}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                />

                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </section>
                </div>
            </form >
        </div >
    )
}

export default DepartureForm