import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './travel.css';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { GLOBAL_DATA } from '../../../config/global-data';

const Travel = (props) => {
    const [selectedTrip, setSelectedTrip] = React.useState(GLOBAL_DATA.SELECTED_TRIP);
    const [travelDestination, setTravelDestination] = React.useState(GLOBAL_DATA.SELECTED_DESTINATION);

    const updateSelectedTrip = (val) => {
        GLOBAL_DATA.SELECTED_TRIP = val;
        setSelectedTrip(val);
    }

    const updateSelectedDestination = (val) => {
        GLOBAL_DATA.SELECTED_DESTINATION = val;
        setTravelDestination(val);
    }

    return (

        <div className="travel-component">
            <div className="input-options-container">
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="select-travel-destination">Select Travel Destination *</InputLabel>
                        <Select
                            id="select-travel-destination"
                            value={travelDestination}
                            onChange={(e) => { updateSelectedDestination(e.target.value) }}
                            label="Select Travel Destination"
                            readOnly={true}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">India</MenuItem>
                            <MenuItem value="2">United States</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="select-trip">Select Trip *</InputLabel>
                        <Select
                            id="select-trip"
                            value={selectedTrip}
                            onChange={(e) => { updateSelectedTrip(e.target.value) }}
                            label="Select Trip"
                            readOnly={true}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">Las Vegas (03/12/2022 - 03/22/2022)</MenuItem>
                            <MenuItem value="2">New York (04/05/2022 - 04/24/2022)</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='travel-container'>
                <Routes>
                    {props.routes.map((route, i) => (
                        <Route
                            key={i}
                            path={route.path}
                            element={<route.component {...props} routes={route.routes} />}
                        />
                    ))}
                    <Route
                        path="*"
                        element={<Navigate to="/dashboard/home" />}
                    />
                </Routes>
            </div>
        </div>
    )
}

export default Travel;