export const SELECT_OPTIONS = {
    RESIDENCE_COUNTRY: [
        {
            value: "1",
            displayValue: "United States Of America"
        },
        {
            value: "2",
            displayValue: "India"
        }
    ],
    SUFFIX: [
        {
            value: "1",
            displayValue: "I"
        },
        {
            value: "2",
            displayValue: "II"
        }
    ],
    GENDER: [
        {
            value: "1",
            displayValue: "Male"
        },
        {
            value: "2",
            displayValue: "Female"
        }
    ]
}