import React from 'react';
import './help-chat.css';

const HelpChat = (props) => {

    return (
        <div className="help-chat-component" >
            <div className="chat-bot-icon-container">
                <img src="/assets/icons/chatbot-icon.png" alt="Chatbot" />
            </div>
            <img src="/assets/icons/close-icon.png" className="close-icon" alt="Close" onClick={() => { props.onCloseBtnClick() }} />
            <h2 className="help-chat-title">EVE</h2>
            <p>Hello, my name is Eve!  How can I help you today?</p>

        </div>
    )
}

export default HelpChat
