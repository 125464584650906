// import { Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Navbar from '../basic/navbar/navbar';
import './dashboard.css';

const Dashboard = (props) => {
    const [allBannerImages, updateAllBannerImages] = React.useState([
        "/assets/images/advertisements/image-1.jpg",
        "/assets/images/advertisements/image-2.jpg",
        "/assets/images/advertisements/image-3.jfif"
    ]);
    const [bannerImages, updateBannerImages] = React.useState([
        "/assets/images/advertisements/image-1.jpg",
        "/assets/images/advertisements/image-2.jpg"
    ]);
    const [bannerInterval, updateBannerInterval] = React.useState(0);
    const [bannerImageIndex, updateBannerImageIndex] = React.useState(0);

    useEffect(() => {
        let tempIntervalId = setInterval(
            () => {
                updateBannerImageIndex(bannerImageIndex >= allBannerImages.length - 1 ? 0 : bannerImageIndex + 1);
                let tempBannerImages = allBannerImages.slice(bannerImageIndex, bannerImageIndex + 2);
                updateBannerImages(tempBannerImages)
            }, 5000
        )
        updateBannerInterval(tempIntervalId);
    }, [allBannerImages, bannerImageIndex]);

    useEffect(() => {
        return () => {
            clearInterval(bannerInterval)
        }
    }, [bannerInterval])


    return (
        <div className="dashboard-component">
            <Navbar />
            {/* <Container maxWidth="lg"> */}
            <div className="dashboard-container">
                <Routes>
                    {props.routes.map((route, i) => (
                        <Route
                            key={i}
                            path={route.path}
                            element={<route.component {...props} routes={route.routes} />}
                        />
                    ))}
                    <Route
                        path="*"
                        element={<Navigate to="/dashboard/home" />}
                    />
                </Routes>
            </div>
            {/* </Container> */}
            <div className="advertisement-banner">
                {
                    bannerImages.map(
                        (imageUrl, index) => {
                            return (
                                <img key={index} src={imageUrl} alt="Ad-Img" className="advertisement-image" />
                            )
                        }
                    )
                }
            </div>
        </div>
    )
}

export default Dashboard;