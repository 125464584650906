import React from 'react';
import './identity-verification.css';

const IdentityVerification = () => {
    return (
        <div className="identity-verification-component">
            <div className="identity-verification-header-section">
                <p className='identity-verification-header'>IDENTITY VERIFICATION</p>
            </div>
            <div className="identity-verification-contents">
                <div className="identity-verification-column">
                    <div className="upload-icon-img-container" >
                        <div className="corner-borders corner-borders--left"></div>
                        <div className="corner-borders corner-borders--right"></div>
                        <img src="/assets/icons/upload-passport-icon.png" alt="Upload Passport Icon" />
                    </div>
                    <h2 className="column-title">Upload Passport Data Page</h2>
                    <p className="center-align">Note: Must upload picture of entire page with picture and all other information on the page</p>
                </div>

                <div className="identity-verification-column">
                    <div className="upload-icon-img-container">
                        <div className="corner-borders corner-borders--left"></div>
                        <div className="corner-borders corner-borders--right"></div>
                        <img src="/assets/icons/upload-selfie-icon.png" alt="Upload Passport Icon" />
                    </div>
                    <h2 className="column-title">Take a Selfie</h2>
                    <p className="center-align">Note: Stand straight and look directly at the camera lens. Take a picture against a solid color wall with good lighting.</p>
                </div>
            </div>
        </div>
    )
}

export default IdentityVerification;