import React from 'react';
import './arrival-form.css';

import { Checkbox, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useNavigate } from 'react-router-dom';



const ArrivalForm = (props) => {
    const navigate = useNavigate();

    const arrivalFormSteps = ["Personal Information", "Security & Address Information", "Trip Details", "Confirmation"];

    const [activeStep, setActiveStep] = React.useState(0);

    const [travelDestination, setTravelDestination] = React.useState("");
    const [familyMember, setFamilyMember] = React.useState("");

    const [profileValues, setProfileValues] = React.useState({
        username: '',
        countryISDCode: '',
        phoneNo: '',
        firstName: '',
        middleInitial: '',
        lastName: '',
        suffix: '',
        dob: null,
        gender: '',
        profilePicName: '',
        country: '',
        passportNo: '',
        countryOfBirth: '',
        countryOfCitizenship: '',
        street1: '',
        street2: '',
        city: '',
        zip: '',
        state: '',
        province: '',
        addMemberFlag: false
    });

    const [tripValues, setTripValues] = React.useState({
        arrivingBy: '',
        airlineOrCruiseline: '',
        vehicleNo: '',
        portOfEntry: '',
        boardedCity: '',
        nightsOfStay: '',
        accomodationType: '',
        intendedAddress: '',
        tripPurpose: '',
        noOfVisits: '',
        arrivalDate: null
    })

    const handleTripValuesChange = (key) => (event) => {
        setTripValues({ ...tripValues, [key]: event.target.value });
    }

    const handleProfileDataChange = (key) => (event) => {
        setProfileValues({ ...profileValues, [key]: event.target.value });
    };

    const handleProfileDOBChange = (e) => {
        setProfileValues({ ...profileValues, dob: e });
    }

    const handleArrivalDateChange = (e) => {
        setTripValues({ ...tripValues, arrivalDate: e });
    }

    const toggleFlagChanges = (key) => (event) => {
        setProfileValues({ ...profileValues, addressSameAsAdminFlag: !profileValues.addressSameAsAdminFlag });
    }

    return (
        <div className='arrival-form-container'>
            <div className='form-header'>
                <p>IMMIGRATION FORM - ARRIVAL</p>
            </div>

            <form className='arrival-form'>
                <div className="stepper-container">
                    <Stepper activeStep={activeStep}>
                        {arrivalFormSteps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <section className={activeStep === 0 ? "form-section slide-left" : "form-section slide-left section-content-hidden"}>
                    <div className='section-content'>
                        <div className='two-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="create-profile-gender">Select Travel Destination *</InputLabel>
                                    <Select
                                        id="travel-destination"
                                        value={travelDestination}
                                        onChange={(e) => { setTravelDestination(e.target.value) }}
                                        label="Select Travel Destination *"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="IN">India</MenuItem>
                                        <MenuItem value="US">USA</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="family-member">Select Family Member *</InputLabel>
                                    <Select
                                        id="family-member"
                                        value={familyMember}
                                        onChange={(e) => { setFamilyMember(e.target.value) }}
                                        label="Select Family Member *"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">John Smith</MenuItem>
                                        <MenuItem value="2">Rose Smith</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='three-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="create-profile-firstname">First Name (as in passport) *</InputLabel>
                                    <Input
                                        id="create-profile-firstname"
                                        type="text"
                                        autoFocus={true}
                                        value={profileValues.firstName}
                                        onChange={handleProfileDataChange('firstName')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="create-profile-middleinitial">Middle Initails</InputLabel>
                                    <Input
                                        id="create-profile-middleinitial"
                                        type="text"
                                        value={profileValues.middleInitial}
                                        onChange={handleProfileDataChange('middleInitial')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="create-profile-lastname">Surname (as in passport) *</InputLabel>
                                    <Input
                                        id="create-profile-lastname"
                                        type="text"
                                        value={profileValues.lastName}
                                        onChange={handleProfileDataChange('lastName')}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='two-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="create-profile-gender">Gender *</InputLabel>
                                    <Select
                                        id="create-profile-gender"
                                        value={profileValues.gender}
                                        onChange={handleProfileDataChange('gender')}
                                        label="Gender"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Male</MenuItem>
                                        <MenuItem value="2">Female</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control mui-date-control">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="Date Of Birth (MM/DD/YYYY)*"
                                        value={profileValues.dob}
                                        onChange={handleProfileDOBChange}
                                        renderInput={(params) => <TextField {...params} />}
                                        closeOnSelect={true}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className='form-action-btn'>
                            <button onClick={(e) => { e.preventDefault(); setActiveStep(1) }}>NEXT</button>
                        </div>
                    </div>
                </section>
                <section className={activeStep === 1 ? "form-section slide-left" : "form-section slide-left section-content-hidden"}>
                    <div className='section-content' >
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="arrival-form-passport-no">Passport No *</InputLabel>
                                <Input
                                    id="arrival-form-passport-no"
                                    type="text"
                                    value={profileValues.passportNo}
                                    onChange={handleProfileDataChange('passportNo')}
                                />
                            </FormControl>
                        </div>
                        <div className="two-col-grid">
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="arrival-form-country-of-birth">Country of Birth *</InputLabel>
                                    <Select
                                        id="arrival-form-country-of-birth"
                                        value={profileValues.countryOfBirth}
                                        onChange={handleProfileDataChange('countryOfBirth')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">India</MenuItem>
                                        <MenuItem value="2">United States of America</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="arrival-form-country-of-citizenship">Country of Citizensip *</InputLabel>
                                    <Select
                                        id="arrival-form-country-of-citizenship"
                                        value={profileValues.countryOfCitizenship}
                                        onChange={handleProfileDataChange('countryOfCitizenship')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">India</MenuItem>
                                        <MenuItem value="2">United States of America</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControlLabel control={<Checkbox checked={profileValues.addressSameAsAdminFlag} onChange={toggleFlagChanges('addressSameAsAdminFlag')} />} label="Address same as Admin?" />
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="arrival-form-country">Country of Residence *</InputLabel>
                                    <Select
                                        id="arrival-form-country"
                                        value={profileValues.country}
                                        onChange={handleProfileDataChange('country')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">India</MenuItem>
                                        <MenuItem value="2">United States of America</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-street1">Street1 *</InputLabel>
                                    <Input
                                        id="arrival-form-street1"
                                        type="text"
                                        value={profileValues.street1}
                                        onChange={handleProfileDataChange('street1')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-street2">Street 2 (Apt. / Unit Number )</InputLabel>
                                    <Input
                                        id="arrival-form-street2"
                                        type="text"
                                        value={profileValues.street2}
                                        onChange={handleProfileDataChange('street2')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-city">City *</InputLabel>
                                    <Input
                                        id="arrival-form-city"
                                        type="text"
                                        value={profileValues.city}
                                        onChange={handleProfileDataChange('city')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-zip">Zip *</InputLabel>
                                    <Input
                                        id="arrival-form-zip"
                                        type="text"
                                        value={profileValues.zip}
                                        onChange={handleProfileDataChange('zip')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-state">State *</InputLabel>
                                    <Input
                                        id="arrival-form-state"
                                        type="text"
                                        value={profileValues.state}
                                        onChange={handleProfileDataChange('state')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-province">Province *</InputLabel>
                                    <Input
                                        id="arrival-form-province"
                                        type="text"
                                        value={profileValues.province}
                                        onChange={handleProfileDataChange('province')}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='form-action-btn'>
                            <button className='prev-btn' onClick={(e) => { e.preventDefault(); setActiveStep(0) }}>PREV</button>
                            <button onClick={(e) => { e.preventDefault(); setActiveStep(2) }}>NEXT</button>
                        </div>
                    </div>
                </section>
                <section className={activeStep === 2 ? "form-section slide-left" : "form-section slide-left section-content-hidden"}>
                    <div className='section-content' >
                        <div className="two-col-grid">
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="arrival-form-arriving-by">Arriving By *</InputLabel>
                                    <Select
                                        id="arrival-form-arriving-by"
                                        value={tripValues.arrivingBy}
                                        onChange={handleTripValuesChange('arrivingBy')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Flight</MenuItem>
                                        <MenuItem value="2">Ship</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="arrival-form-airline-or-cruiseline">Airline / Cruise Line *</InputLabel>
                                    <Select
                                        id="arrival-form-airline-or-cruiseline"
                                        value={tripValues.airlineOrCruiseline}
                                        onChange={handleTripValuesChange('airlineOrCruiseline')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Airline</MenuItem>
                                        <MenuItem value="2">Cruise Line</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-vehicle-no">Flight No / Ship Name *</InputLabel>
                                    <Input
                                        id="arrival-form-vehicle-no"
                                        type="text"
                                        value={tripValues.vehicleNo}
                                        onChange={handleTripValuesChange('vehicleNo')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="arrival-form-port-of-entry">Port of Entry *</InputLabel>
                                    <Select
                                        id="arrival-form-port-of-entry"
                                        value={tripValues.portOfEntry}
                                        onChange={handleTripValuesChange('portOfEntry')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Port 1</MenuItem>
                                        <MenuItem value="2">Port 2</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-boarded-city">City Where you boarded *</InputLabel>
                                    <Input
                                        id="arrival-form-boarded-city"
                                        type="text"
                                        value={tripValues.boardedCity}
                                        onChange={handleTripValuesChange('boardedCity')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-nights-of-stay">Intended Stay (Nights) *</InputLabel>
                                    <Input
                                        id="arrival-form-nights-of-stay"
                                        type="text"
                                        value={tripValues.nightsOfStay}
                                        onChange={handleTripValuesChange('nightsOfStay')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="arrival-form-type-of-accomodation">Type of Accomodation *</InputLabel>
                                    <Select
                                        id="arrival-form-type-of-accomodation"
                                        value={tripValues.accomodationType}
                                        onChange={handleTripValuesChange('accomodationType')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="arrival-form-intended-address">Hotel Name / Intended Address *</InputLabel>
                                    <Input
                                        id="arrival-form-intended-address"
                                        type="text"
                                        value={tripValues.intendedAddress}
                                        onChange={handleTripValuesChange('intendedAddress')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="arrival-form-trip-purpose">Trip Purpose *</InputLabel>
                                    <Select
                                        id="arrival-form-trip-purpose"
                                        value={tripValues.tripPurpose}
                                        onChange={handleTripValuesChange('tripPurpose')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Business Trip</MenuItem>
                                        <MenuItem value="2">Honeymoon</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="arrival-form-no-of-visits">No. of visits in last year (including this) *</InputLabel>
                                    <Select
                                        id="arrival-form-no-of-visits"
                                        value={tripValues.noOfVisits}
                                        onChange={handleTripValuesChange('noOfVisits')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">1</MenuItem>
                                        <MenuItem value="2">2</MenuItem>
                                        <MenuItem value="3">3</MenuItem>
                                        <MenuItem value="4">4</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control mui-date-control">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="Date Of Arrival (MM/DD/YYYY)*"
                                        value={tripValues.arrivalDate}
                                        onChange={handleArrivalDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                        closeOnSelect={true}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className='form-action-btn'>
                            <button className='prev-btn' onClick={(e) => { e.preventDefault(); setActiveStep(1) }}>PREV</button>
                            <button onClick={(e) => { e.preventDefault(); setActiveStep(3) }}>NEXT</button>
                        </div>
                    </div>
                </section>
                <section className={activeStep === 3 ? "form-section slide-left" : "form-section slide-left section-content-hidden"}>
                    <div className='section-content' >
                        <div className="confirmation-details-container">
                            <div className="each-item">
                                <p className="label-tag">Travel Destination:</p>
                                <p>India</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">First Name:</p>
                                <p>{profileValues.firstName}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Middle Initials:</p>
                                <p>{profileValues.middleInitial}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Surname:</p>
                                <p>{profileValues.lastName}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Gender:</p>
                                <p>{profileValues.gender}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">DOB:</p>
                                <p>{profileValues.dob}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Passport No:</p>
                                <p>{profileValues.passportNo}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Country Of Birth:</p>
                                <p>{profileValues.countryOfBirth}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Country Of Citizensip:</p>
                                <p>{profileValues.countryOfCitizenship}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Country Of Residence:</p>
                                <p>{profileValues.country}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Street 1:</p>
                                <p>{profileValues.street1}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Street 2:</p>
                                <p>{profileValues.street2}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">City:</p>
                                <p>{profileValues.city}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Zip:</p>
                                <p>{profileValues.zip}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">State:</p>
                                <p>{profileValues.state}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Province:</p>
                                <p>{profileValues.province}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Arriving By:</p>
                                <p>{tripValues.arrivingBy}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Airline/Cruise Line:</p>
                                <p>{tripValues.airlineOrCruiseline}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Flight No/Ship Name:</p>
                                <p>{tripValues.vehicleNo}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Port Of Entry:</p>
                                <p>{tripValues.portOfEntry}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Boarderd City:</p>
                                <p>{tripValues.boardedCity}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Nights of Stay:</p>
                                <p>{tripValues.nightsOfStay}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Accomodation Type:</p>
                                <p>{tripValues.accomodationType}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Intended Address:</p>
                                <p>{tripValues.intendedAddress}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Trip Purpose:</p>
                                <p>{tripValues.tripPurpose}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">No. of Visits:</p>
                                <p>{tripValues.noOfVisits}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Arrival Date:</p>
                                <p>{tripValues.arrivalDate}</p>
                            </div>
                        </div>
                        <div className='form-action-btn'>
                            <button className='prev-btn' onClick={(e) => { e.preventDefault(); setActiveStep(2) }}>PREV</button>
                            <button onClick={(e) => { e.preventDefault(); navigate('/dashboard/home'); }}>SUBMIT FORM</button>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    )
}

export default ArrivalForm