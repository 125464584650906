import React, { useEffect, useState } from 'react';
import './family-profiles.css';

import { Navigate, Route, Routes } from 'react-router-dom';

const FamilyProfiles = (props) => {
    const [familyMembers, updateFamilyMembers] = useState([]);

    useEffect(() => {
        console.log("Use effect executed");
        updateFamilyMembers([
            {
                "name": "John Smith",
                "image": "/assets/images/users/user-0.png",
                "relationship": "Self"
            },
            {
                "name": "Rose Smith",
                "image": "/assets/images/users/user-1.png",
                "relationship": "Wife"
            },
            {
                "name": "Kelsey Smith",
                "image": "/assets/images/users/user-2.png",
                "relationship": "Daughter"
            },
            {
                "name": "Aiden Smith",
                "image": "/assets/images/users/user-3.png",
                "relationship": "Son"
            }
        ])
    }, []);

    return (
        <div className="family-profiles-component">
            <Routes>
                {props.routes.map((route, i) => (
                    <Route
                        key={i}
                        path={route.path}
                        element={<route.component {...props} routes={route.routes} familyMembers={familyMembers}/>}
                    />
                ))}
                <Route
                    path="*"
                    element={<Navigate to="/dashboard/user-settings/family-profiles/all-members" />}
                />
            </Routes>
        </div>
    )
}

export default FamilyProfiles;