import React from 'react';
import './navbar.css';

import { Link, useNavigate } from 'react-router-dom';
// import { Container } from '@mui/material';
import { LOGGED_USER } from '../../../config/user-data';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Navbar = (props) => {
    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const signOutUser = () => {
        LOGGED_USER.USER_ID = null;
        LOGGED_USER.USERNAME = null;
        LOGGED_USER.PHONE_NO = null;
        LOGGED_USER.COUNRTRY_ISD_CODE = null;
        LOGGED_USER.SESSION_COUNTER = 0;
        navigate("/");
    }

    return (
        <div className="navbar-container">
            {/* <Container maxWidth="lg"> */}
            <div className="logo-container">
                <img src="/assets/logo/PNG MagellanGO Color with Taglane.png" alt="Logo" />
            </div>
            <nav className="landing-page-nav">
                <ul className="landing-page-nav-items">
                    {
                        LOGGED_USER.USER_ID
                            ?
                            <>
                                <li><Link to="/dashboard/home"> <HomeIcon /> Home</Link></li>
                                <li><Link to="#"> <NotificationsIcon /> Notification</Link></li>
                                <li><Link to="/dashboard/help"> <InfoIcon /> Help</Link></li>
                            </>
                            :
                            <>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/help">Help</Link></li>
                                <li><Link to="/auth/login">Login</Link></li>
                                <li><button onClick={() => { navigate("/auth/register") }}>Sign Up</button></li>
                            </>
                    }

                </ul>
            </nav>
            {
                LOGGED_USER.USER_ID
                    ?
                    <div className='profile-menu-icon'>
                        <AccountCircleIcon onClick={handleClick} fontSize="large" />
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => { handleClose(); navigate("/dashboard/user-settings/family-profiles/all-members") }}>Profile (s)</MenuItem>
                            <MenuItem onClick={() => { handleClose(); navigate("/dashboard/user-settings/user-dashboard") }}>My Dashboard</MenuItem>
                            <MenuItem onClick={() => { handleClose(); navigate("/dashboard/user-settings/settings") }}>Settings</MenuItem>
                            <MenuItem onClick={() => { handleClose(); navigate("/dashboard/terms-and-conditions") }}>Terms & Conditions</MenuItem>
                            <MenuItem onClick={() => { handleClose(); navigate("/dashboard/privacy-policies") }}>Privacy Policies</MenuItem>
                            <MenuItem onClick={() => { handleClose(); navigate("/dashboard/refund-policies") }}>Refund Policies</MenuItem>
                            <MenuItem onClick={() => { handleClose(); navigate("/dashboard/help") }}>Help</MenuItem>
                            <MenuItem onClick={() => { handleClose(); signOutUser(); }}>Logout</MenuItem>
                        </Menu>
                    </div>
                    : <></>
            }
            {/* </Container> */}
        </div>
    )
}

export default Navbar;