import React from 'react';
import './settings.css';

import { FormControl, IconButton, Input, InputLabel, InputAdornment, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { PASSWORD_REGEX } from '../../../../config/constants';
import { validateRegex } from '../../../../services/utilities';


const Settings = (props) => {

    const [authValues, setAuthValues] = React.useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const [componentFlags, setFlags] = React.useState({
        showOldPassword: false,
        showNewPassword: false,
        showConfirmPassword: false
    })

    const handleAuthDataChange = (key) => (event) => {
        setAuthValues({ ...authValues, [key]: event.target.value });
    };

    const toggleComponentFlags = (key) => (event) => {
        setFlags({ ...componentFlags, [key]: !componentFlags[key] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className="settings-component">
            <div className="settings-header-section">
                <p className='settings-header'>SETTINGS</p>
            </div>
            <div className="settings-container">
                <div className="change-password-content">
                    <section className="description-section">
                        <p className="form-section-header">Change Password</p>
                        <p>Password must contain:</p>
                        <ul>
                            <li>
                                <p>Atleast 8 characters</p>
                            </li>
                            <li>
                                <p>Atleast 1 upper-case letter [A-Z]</p>
                            </li>
                            <li>
                                <p>Atleast 1 lower-case letter [a-z]</p>
                            </li>
                            <li>
                                <p>Atleast 1 numeric character [0-9]</p>
                            </li>
                            <li>
                                <p>Atleast 1 special character [@$!%*?&]</p>
                            </li>
                        </ul>
                    </section>
                    <form className="change-password-form">
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="old-password">Old Password</InputLabel>
                                <Input
                                    id="old-password"
                                    type={componentFlags.showOldPassword ? 'text' : 'password'}
                                    value={authValues.oldPassword}
                                    onChange={handleAuthDataChange('oldPassword')}
                                    error={!validateRegex(PASSWORD_REGEX, authValues.oldPassword)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleComponentFlags('showOldPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {componentFlags.showOldPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            {!validateRegex(PASSWORD_REGEX, authValues.oldPassword) ? <FormHelperText>Password must have atleast 8 characters and contain atleast 1 capital, 1 small and 1 special character.</FormHelperText> : <></>}
                        </div>
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="new-password">New Password</InputLabel>
                                <Input
                                    id="new-password"
                                    type={componentFlags.showNewPassword ? 'text' : 'password'}
                                    value={authValues.newPassword}
                                    onChange={handleAuthDataChange('newPassword')}
                                    error={!validateRegex(PASSWORD_REGEX, authValues.newPassword)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleComponentFlags('showNewPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {componentFlags.showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            {!validateRegex(PASSWORD_REGEX, authValues.newPassword) ? <FormHelperText>Password must have atleast 8 characters and contain atleast 1 capital, 1 small and 1 special character.</FormHelperText> : <></>}
                        </div>
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                                <Input
                                    id="confirm-password"
                                    type={componentFlags.showConfirmPassword ? 'text' : 'password'}
                                    value={authValues.confirmPassword}
                                    onChange={handleAuthDataChange('confirmPassword')}
                                    error={!validateRegex(PASSWORD_REGEX, authValues.confirmPassword)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleComponentFlags('showConfirmPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {componentFlags.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            {
                                (authValues.confirmPassword && authValues.newPassword !== authValues.confirmPassword)
                                    ? <FormHelperText>Passwords do not match</FormHelperText>
                                    : <></>
                            }
                        </div>
                        <div className="mui-form-control">
                            <button className="submit-btn">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Settings;