import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import './auth.css';

const Auth = (props) => {
    return (
        <div className="auth-container">
            <Routes>
                {props.routes.map((route, i) => (
                    <Route
                        key={i}
                        path={route.path}
                        element={<route.component {...props} routes={route.routes} />}
                    />
                ))}
                <Route
                    path="*"
                    element={<Navigate to="/auth/login" />}
                />
            </Routes>
        </div>

    )
}

export default Auth;