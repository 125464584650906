import React from 'react';
import './footer.css';

export const Footer = (props) => {
    return (
        <div className="footer-component">
            <p>© Copyright MagellanGO LLC. All Rights Reserved.</p>
        </div>
    )
}

export default Footer;