import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import './admin-home.css';
import { GLOBAL_DATA } from '../../../config/global-data';

const AdminHome = (props) => {
    const [homeTabItems, setHomeTabItems] = React.useState([
        {
            groupHeader: "APPLY FOR VISA",
            groupItems: [
                {
                    itemHeader: "Apply for Visa",
                    itemIcon: "/assets/nav-icons/evisa/apply-evisa-icon.png",
                    itemLink: "/dashboard/evisa/application-form",
                    selected: false
                },
                {
                    itemHeader: "Proof of Vaccination",
                    itemIcon: "/assets/nav-icons/evisa/vaccination-proof-icon.png",
                    itemLink: "/dashboard/evisa/vaccination-proof",
                    selected: false
                },
                {
                    itemHeader: "Identity Verification",
                    itemIcon: "/assets/nav-icons/evisa/identity-verification-icon.png",
                    itemLink: "/dashboard/evisa/identity-verification",
                    selected: false
                },
                {
                    itemHeader: "Visa Payment",
                    itemIcon: "/assets/nav-icons/evisa/payment-icon.png",
                    itemLink: "/dashboard/evisa/shopping-cart",
                    selected: false
                }
            ]
        },
        {
            groupHeader: "GET YOUR TRAVEL PASS",
            groupItems: [
                {
                    itemHeader: "Create Trip",
                    itemIcon: "/assets/nav-icons/travel/create-trip-icon.png",
                    itemLink: "/dashboard/travel/create-trip",
                    selected: false
                },
                {
                    itemHeader: "Travel Checklist",
                    itemIcon: "/assets/nav-icons/travel/travel-checklist-icon.png",
                    itemLink: "/dashboard/travel/travel-checklist",
                    selected: false
                },
                {
                    itemHeader: "Travel Documents",
                    itemIcon: "/assets/nav-icons/travel/travel-documents-icon.png",
                    itemLink: "/dashboard/travel/travel-documents",
                    selected: false
                },
                {
                    itemHeader: "Health Documents",
                    itemIcon: "/assets/nav-icons/travel/health-documents-icon.png",
                    itemLink: '/dashboard/travel/health-documents',
                    selected: false
                },
                {
                    itemHeader: "Travel Insurance",
                    itemIcon: "/assets/nav-icons/travel/travel-insurance-icon.png",
                    itemLink: "/dashboard/travel/travel-insurance",
                    selected: false
                },
                {
                    itemHeader: "TravelPass Payment",
                    itemIcon: "/assets/nav-icons/travel/payment-icon.png",
                    itemLink: "/dashboard/travel/payment",
                    selected: false
                },
                {
                    itemHeader: "Government Approval",
                    itemIcon: "/assets/nav-icons/travel/government-approval-icon.png",
                    itemLink: "/dashboard/travel/government-approval",
                    selected: false
                }
            ]
        },
        {
            groupHeader: "MARKETPLACE & AIRPORT INFORMATION",
            groupItems: [
                {
                    itemHeader: "Plan Your Excursions",
                    itemIcon: "/assets/nav-icons/market-place/plan-your-trip-icon.png",
                    itemLink: "/dashboard/market-place/plan-your-excursions",
                    selected: false
                },
                {
                    itemHeader: "Airport Dine & Shop",
                    itemIcon: "/assets/nav-icons/market-place/payment-icon.png",
                    itemLink: "/dashboard/market-place/airport-dine-and-shop",
                    selected: false
                },
                {
                    itemHeader: "Airport Information",
                    itemIcon: "/assets/nav-icons/market-place/airport-maps-icon.png",
                    itemLink: "/dashboard/market-place/airport-info",
                    selected: false
                },
                {
                    itemHeader: "Airport Map",
                    itemIcon: "/assets/nav-icons/market-place/airport-info-icon.png",
                    itemLink: "/dashboard/market-place/airport-map",
                    selected: false
                }
            ]
        },
        {
            groupHeader: "USER SETTINGS",
            groupItems: [
                {
                    itemHeader: "Family Profile(s)",
                    itemIcon: "/assets/nav-icons/settings/profiles-icon.png",
                    itemLink: "/dashboard/user-settings/family-profiles",
                    selected: false
                },
                {
                    itemHeader: "My Dashboard",
                    itemIcon: "/assets/nav-icons/settings/user-dashboard-icon.png",
                    itemLink: "/dashboard/user-settings/user-dashboard",
                    selected: false
                },
                {
                    itemHeader: "Settings",
                    itemIcon: "/assets/nav-icons/settings/settings-icon.png",
                    itemLink: "/dashboard/user-settings/settings",
                    selected: false
                }
            ]
        }
    ])

    const [travelDestination, setTravelDestination] = React.useState(GLOBAL_DATA.SELECTED_DESTINATION);

    const updateHomeTabItem = (groupIndex, itemIndex) => {
        let tempGroups = [...homeTabItems];
        tempGroups[groupIndex]['groupItems'][itemIndex]['selected'] = !tempGroups[groupIndex]['groupItems'][itemIndex]['selected'];
        setHomeTabItems(tempGroups);
    }

    return (
        <div className="admin-home-component">
            <div className="input-options-container">
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="select-travel-destination">Select Country *</InputLabel>
                        <Select
                            id="select-travel-destination"
                            value={travelDestination}
                            onChange={(e) => { setTravelDestination(e.target.value) }}
                            label="Select Family Member"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="1">India</MenuItem>
                            <MenuItem value="2">United States</MenuItem>
                        </Select>
                    </FormControl>
                </div>

            </div>
            <div className="admin-home-tabs-container">
                {homeTabItems.map((eachGroup, groupIndex) => {
                    return (
                        <div className="each-nav-items-block" key={groupIndex}>
                            <h2 className="nav-items-block-header">{eachGroup.groupHeader}</h2>
                            <div className="admin-home-tab-items" >
                                {eachGroup.groupItems.map((eachTabItem, tabItemIndex) => {
                                    return (
                                        <div
                                            className={eachTabItem.selected ? "each-tab-item selected-item" : "each-tab-item"}
                                            key={tabItemIndex}
                                            // onClick={() => { navigate(eachTabItem.itemLink) }}
                                            onClick={() => { updateHomeTabItem(groupIndex, tabItemIndex) }}>
                                            {eachTabItem.selected ?
                                                <div className="selected-tick-container">
                                                    <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />
                                                </div>
                                                : <></>
                                            }
                                            <img src={eachTabItem.itemIcon} alt={eachTabItem.itemHeader} />
                                            <h3>{eachTabItem.itemHeader}</h3>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="action-btns-container right-align">
                <button className="update-btns">UPDATE TABS</button>
            </div>
        </div>
    )
}

export default AdminHome;
