import React from 'react';
import './create-trip.css';
import SingleCityTrip from './single-city-trip/single-city-trip';

const CreateTrip = (props) => {
    const [index, setIndex] = React.useState(0);

    const handleChange = (newValue) => {
        setIndex(newValue);
    };

    return (
        <div className="create-trip-container">
            <div className="create-trip-header-section">
                <p className='create-trip-header'>CREATE TRIP</p>
                <section className="tab-btns">
                    <button className={index === 0 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(0) }}>Single City</button>
                    <button className={index === 1 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(1) }}>Multi-City</button>
                </section>
            </div>
            <div className="create-trip-form-container">
                <SingleCityTrip />
            </div>
        </div>
    )
}

export default CreateTrip;