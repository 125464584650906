import React from 'react';
import './ground-transportation.css';

import { FormControl, IconButton, Input, InputLabel, InputAdornment } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';


const GroundTransportation = (props) => {
    const [pickupAddr, setPickupAddr] = React.useState("");
    const [dropAddr, setDropAddr] = React.useState("");

    return (
        <div className="ground-transportation-component">
            <div className="ground-transportation-header-section">
                <p className='ground-transportation-header'>GROUND TRANSPORTATION</p>
            </div>
            <div className="ground-transportation-content">
                <div className="transport-form-container">
                    <div className="mui-form-control">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel htmlFor="pickup-point">Enter Pickup Address *</InputLabel>
                            <Input
                                id="pickup-point"
                                type="text"
                                autoFocus={true}
                                value={pickupAddr}
                                onChange={(e) => {setPickupAddr(e.target.value)}}
                                endAdornment={
                                    <InputAdornment position="start">
                                        <IconButton onMouseDown={(e) => {e.preventDefault();}}>
                                            <LocationOnIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className="mui-form-control">
                        <FormControl variant="standard" fullWidth>
                            <InputLabel htmlFor="drop-address">Enter Drop Address *</InputLabel>
                            <Input
                                id="drop-address"
                                type="text"
                                value={dropAddr}
                                onChange={(e) => { setDropAddr(e.target.value) }}
                                endAdornment={
                                    <InputAdornment position="start">
                                        <IconButton onMouseDown={(e) => {e.preventDefault();}}>
                                            <LocationOnIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className="mui-form-control">
                        <button className="submit-btn" >SUBMIT</button>
                    </div>
                </div>
                <div className="map-container">
                    <img src="/assets/images/market-place/transportation/map.png" alt="Map" className="map-img"/>
                </div>
            </div>
        </div>
    )

}

export default GroundTransportation;
