import React from 'react';
import './evisa-application.css';

import { Checkbox, Chip, FormControl, FormControlLabel, Input, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useNavigate } from 'react-router-dom';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';


const EVisaApplication = (props) => {
    const navigate = useNavigate();

    const [index, setIndex] = React.useState(0);

    const handleChange = (newValue) => {
        setIndex(newValue);
    };

    const arrivalFormSteps = ["Personal Information", "Security Information", "Travel Details", "Address Information", "Confirmation"];

    const [activeStep, setActiveStep] = React.useState(0);
    const [travelPurpose, setTravelPurpose] = React.useState("");
    const [familyMember, setFamilyMember] = React.useState("");

    const [profileValues, setProfileValues] = React.useState({
        username: '',
        countryISDCode: '',
        phoneNo: '',
        firstName: '',
        middleInitial: '',
        lastName: '',
        suffix: '',
        dob: null,
        gender: '',
        occupation: '',
        profilePicName: '',
        country: '',
        passportNo: '',
        countryOfBirth: '',
        countryOfCitizenship: '',
        street1: '',
        street2: '',
        city: '',
        zip: '',
        state: '',
        province: '',
        addMemberFlag: false,
        issueDate: null,
        expiryDate: null,
        addressInDestination: ''
    });

    const [tripValues, setTripValues] = React.useState({
        visaRequiredDays: '',
        arrivingBy: '',
        airlineOrCruiseline: '',
        vehicleNo: '',
        portOfEntry: '',
        boardedCity: '',
        nightsOfStay: '',
        accomodationType: '',
        intendedAddress: '',
        tripPurpose: '',
        noOfVisits: '',
        arrivalDate: null,
        destination: ''
    })

    const handleTripValuesChange = (key) => (event) => {
        setTripValues({ ...tripValues, [key]: event.target.value });
    }

    const handleProfileDataChange = (key) => (event) => {
        setProfileValues({ ...profileValues, [key]: event.target.value });
    };

    const handleProfileDOBChange = (e) => {
        setProfileValues({ ...profileValues, dob: e });
    }

    const handleArrivalDateChange = (e) => {
        setTripValues({ ...tripValues, arrivalDate: e });
    }

    return (
        <div className='evisa-application-form-container'>
            <div className="evisa-application-form-header-section">
                <p className='evisa-application-form-header'>VISA APPLICATION</p>
                <section className="tab-btns">
                    <button className={index === 0 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(0) }}>INDIVIDUAL</button>
                    <button className={index === 1 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(1) }}>GROUP</button>
                    <button className={index === 2 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(2) }}>THIRD PARTY</button>
                </section>
            </div>

            <form className='evisa-application-form'>
                <div className="stepper-container">
                    <Stepper activeStep={activeStep}>
                        {arrivalFormSteps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <section className={activeStep === 0 ? "form-section slide-left" : "form-section slide-left section-content-hidden"}>
                    <div className='section-content'>
                        <div className='two-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="create-profile-gender">Select Travel Purpose *</InputLabel>
                                    <Select
                                        id="travel-destination"
                                        value={travelPurpose}
                                        onChange={(e) => { setTravelPurpose(e.target.value) }}
                                        label="Select Travel Destination *"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Business</MenuItem>
                                        <MenuItem value="2">Tourism</MenuItem>
                                        <MenuItem value="3">Transit</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="family-member">Select Family Member *</InputLabel>
                                    <Select
                                        id="family-member"
                                        value={familyMember}
                                        onChange={(e) => { setFamilyMember(e.target.value) }}
                                        label="Select Family Member *"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">John Smith</MenuItem>
                                        <MenuItem value="2">Rose Smith</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='three-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="create-profile-firstname">First Name (as in passport) *</InputLabel>
                                    <Input
                                        id="create-profile-firstname"
                                        type="text"
                                        autoFocus={true}
                                        value={profileValues.firstName}
                                        onChange={handleProfileDataChange('firstName')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="create-profile-middleinitial">Middle Initails</InputLabel>
                                    <Input
                                        id="create-profile-middleinitial"
                                        type="text"
                                        value={profileValues.middleInitial}
                                        onChange={handleProfileDataChange('middleInitial')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="create-profile-lastname">Surname (as in passport) *</InputLabel>
                                    <Input
                                        id="create-profile-lastname"
                                        type="text"
                                        value={profileValues.lastName}
                                        onChange={handleProfileDataChange('lastName')}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='three-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="create-profile-occupation">Occupation *</InputLabel>
                                    <Input
                                        id="create-profile-occupation"
                                        type="text"
                                        value={profileValues.occupation}
                                        onChange={handleProfileDataChange('occupation')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="create-profile-gender">Gender *</InputLabel>
                                    <Select
                                        id="create-profile-gender"
                                        value={profileValues.gender}
                                        onChange={handleProfileDataChange('gender')}
                                        label="Gender"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Male</MenuItem>
                                        <MenuItem value="2">Female</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control mui-date-control">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="Date Of Birth (MM/DD/YYYY)*"
                                        value={profileValues.dob}
                                        onChange={handleProfileDOBChange}
                                        renderInput={(params) => <TextField {...params} />}
                                        closeOnSelect={true}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className='form-action-btn'>
                            <button onClick={(e) => { e.preventDefault(); setActiveStep(1) }}>NEXT</button>
                        </div>
                    </div>
                </section>
                <section className={activeStep === 1 ? "form-section slide-left" : "form-section slide-left section-content-hidden"}>
                    <div className='section-content' >
                        <div className="two-col-grid">
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="evisa-application-form-passport-no">Passport No *</InputLabel>
                                    <Input
                                        id="evisa-application-form-passport-no"
                                        type="text"
                                        value={profileValues.passportNo}
                                        onChange={handleProfileDataChange('passportNo')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="evisa-application-form-country-of-citizenship">Country of Citizensip *</InputLabel>
                                    <Select
                                        id="evisa-application-form-country-of-citizenship"
                                        value={profileValues.countryOfCitizenship}
                                        onChange={handleProfileDataChange('countryOfCitizenship')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">India</MenuItem>
                                        <MenuItem value="2">United States of America</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control mui-date-control">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="Date Of Issue (MM/DD/YYYY)*"
                                        value={profileValues.issueDate}
                                        onChange={(e) => { setProfileValues({ ...profileValues, issueDate: e }); }}
                                        renderInput={(params) => <TextField {...params} />}
                                        closeOnSelect={true}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="mui-form-control mui-date-control">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="Date Of Expiry (MM/DD/YYYY)*"
                                        value={profileValues.expiryDate}
                                        onChange={(e) => { setProfileValues({ ...profileValues, expiryDate: e }); }}
                                        renderInput={(params) => <TextField {...params} />}
                                        closeOnSelect={true}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="evisa-application-form-country-of-birth">Country of Birth *</InputLabel>
                                    <Select
                                        id="evisa-application-form-country-of-birth"
                                        value={profileValues.countryOfBirth}
                                        onChange={handleProfileDataChange('countryOfBirth')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">India</MenuItem>
                                        <MenuItem value="2">United States of America</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>


                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="evisa-application-form-country">Country of Residence *</InputLabel>
                                    <Select
                                        id="evisa-application-form-country"
                                        value={profileValues.country}
                                        onChange={handleProfileDataChange('country')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">India</MenuItem>
                                        <MenuItem value="2">United States of America</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className='form-action-btn'>
                            <button className='prev-btn' onClick={(e) => { e.preventDefault(); setActiveStep(0) }}>PREV</button>
                            <button onClick={(e) => { e.preventDefault(); setActiveStep(2) }}>NEXT</button>
                        </div>
                    </div>
                </section>
                <section className={activeStep === 2 ? "form-section slide-left" : "form-section slide-left section-content-hidden"}>
                    <div className='section-content' >
                        <div className="two-col-grid">
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="evisa-application-form-visa-required-days">VISA Required Days *</InputLabel>
                                    <Select
                                        id="evisa-application-form-visa-required-days"
                                        value={tripValues.visaRequiredDays}
                                        onChange={handleTripValuesChange('visaRequiredDays')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="10">10</MenuItem>
                                        <MenuItem value="20">20</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="evisa-application-form-arriving-by">Arriving By *</InputLabel>
                                    <Select
                                        id="evisa-application-form-arriving-by"
                                        value={tripValues.arrivingBy}
                                        onChange={handleTripValuesChange('arrivingBy')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Flight</MenuItem>
                                        <MenuItem value="2">Ship</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="evisa-application-form-airline-or-cruiseline">Airline / Cruise Line *</InputLabel>
                                    <Select
                                        id="evisa-application-form-airline-or-cruiseline"
                                        value={tripValues.airlineOrCruiseline}
                                        onChange={handleTripValuesChange('airlineOrCruiseline')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Airline</MenuItem>
                                        <MenuItem value="2">Cruise Line</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="evisa-application-form-vehicle-no">Flight No / Ship Name *</InputLabel>
                                    <Input
                                        id="evisa-application-form-vehicle-no"
                                        type="text"
                                        value={tripValues.vehicleNo}
                                        onChange={handleTripValuesChange('vehicleNo')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="evisa-application-form-boarded-city">City Where you boarded from / Port of Departure *</InputLabel>
                                    <Input
                                        id="evisa-application-form-boarded-city"
                                        type="text"
                                        value={tripValues.boardedCity}
                                        onChange={handleTripValuesChange('boardedCity')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="evisa-application-form-trip-purpose">Purpose of visit *</InputLabel>
                                    <Select
                                        id="evisa-application-form-trip-purpose"
                                        value={tripValues.tripPurpose}
                                        onChange={handleTripValuesChange('tripPurpose')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Business Trip</MenuItem>
                                        <MenuItem value="2">Tourist</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="evisa-application-form-no-of-visits">No. of visits in last year (including this) *</InputLabel>
                                    <Select
                                        id="evisa-application-form-no-of-visits"
                                        value={tripValues.noOfVisits}
                                        onChange={handleTripValuesChange('noOfVisits')}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">1</MenuItem>
                                        <MenuItem value="2">2</MenuItem>
                                        <MenuItem value="3">3</MenuItem>
                                        <MenuItem value="4">4</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control mui-date-control">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="Date Of Arrival (MM/DD/YYYY)*"
                                        value={tripValues.arrivalDate}
                                        onChange={handleArrivalDateChange}
                                        renderInput={(params) => <TextField {...params} />}
                                        closeOnSelect={true}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="evisa-application-form-location-input">Provide Names of Destinations</InputLabel>
                                <Input
                                    id="evisa-application-form-location-input"
                                    type="text"
                                    value={tripValues.destination}
                                    onChange={handleTripValuesChange('destination')}
                                />
                            </FormControl>
                        </div>
                        <div className="mui-form-control">
                            <Chip label="Location 1" onDelete={() => { }} />
                            <Chip label="Location 2" onDelete={() => { }} />
                        </div>
                        <div className='form-action-btn'>
                            <button className='prev-btn' onClick={(e) => { e.preventDefault(); setActiveStep(1) }}>PREV</button>
                            <button onClick={(e) => { e.preventDefault(); setActiveStep(3) }}>NEXT</button>
                        </div>
                    </div>
                </section>
                <section className={activeStep === 3 ? "form-section slide-left" : "form-section slide-left section-content-hidden"}>
                    <div className='section-content' >
                        <div className="two-col-grid">
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="evisa-application-form-street1">Street1 *</InputLabel>
                                    <Input
                                        id="evisa-application-form-street1"
                                        type="text"
                                        value={profileValues.street1}
                                        onChange={handleProfileDataChange('street1')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="evisa-application-form-street2">Street 2 (Apt. / Unit Number )</InputLabel>
                                    <Input
                                        id="evisa-application-form-street2"
                                        type="text"
                                        value={profileValues.street2}
                                        onChange={handleProfileDataChange('street2')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="evisa-application-form-city">City *</InputLabel>
                                    <Input
                                        id="evisa-application-form-city"
                                        type="text"
                                        value={profileValues.city}
                                        onChange={handleProfileDataChange('city')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="evisa-application-form-zip">Zip *</InputLabel>
                                    <Input
                                        id="evisa-application-form-zip"
                                        type="text"
                                        value={profileValues.zip}
                                        onChange={handleProfileDataChange('zip')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="evisa-application-form-state">State *</InputLabel>
                                    <Input
                                        id="evisa-application-form-state"
                                        type="text"
                                        value={profileValues.state}
                                        onChange={handleProfileDataChange('state')}
                                    />
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="evisa-application-form-province">Province *</InputLabel>
                                    <Input
                                        id="evisa-application-form-province"
                                        type="text"
                                        value={profileValues.province}
                                        onChange={handleProfileDataChange('province')}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel htmlFor="evisa-application-form-address-in-destination">Full Address in Destination *</InputLabel>
                                <Input
                                    id="evisa-application-form-address-in-destination"
                                    type="text"
                                    value={profileValues.addressInDestination}
                                    onChange={handleProfileDataChange('addressInDestination')}
                                />
                            </FormControl>
                        </div>

                        <div className='declaration-checkbox-input'>
                            <p className='checkbox-section-header'>
                                Documentation *:
                            </p>
                            <div className="mui-form-control">
                                <div className="each-upload-item">
                                    <CloudUploadOutlinedIcon /> <p>Upload 3 months of banks statements</p>
                                </div>
                                <div className="each-upload-item">
                                    <CloudUploadOutlinedIcon /> <p>Upload previous visas to destination country</p>
                                </div>
                            </div>
                        </div>
                        <div className='form-action-btn'>
                            <button className='prev-btn' onClick={(e) => { e.preventDefault(); setActiveStep(2) }}>PREV</button>
                            <button onClick={(e) => { e.preventDefault(); setActiveStep(4) }}>NEXT</button>
                        </div>
                    </div>
                </section>
                <section className={activeStep === 4 ? "form-section slide-left" : "form-section slide-left section-content-hidden"}>
                    <div className='section-content' >
                        <div className="confirmation-details-container">
                            <div className="each-item">
                                <p className="label-tag">Travel Purpose:</p>
                                <p>{travelPurpose}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">First Name:</p>
                                <p>{profileValues.firstName}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Middle Initials:</p>
                                <p>{profileValues.middleInitial}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Surname:</p>
                                <p>{profileValues.lastName}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Occupation:</p>
                                <p>{profileValues.occupation}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Gender:</p>
                                <p>{profileValues.gender}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">DOB:</p>
                                <p>{profileValues.dob}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Passport No:</p>
                                <p>{profileValues.passportNo}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Country Of Citizensip:</p>
                                <p>{profileValues.countryOfCitizenship}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Date Of Issue:</p>
                                <p>{profileValues.issueDate}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Date Of Expiry:</p>
                                <p>{profileValues.expiryDate}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Country Of Birth:</p>
                                <p>{profileValues.countryOfBirth}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Country Of Residence:</p>
                                <p>{profileValues.country}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Street 1:</p>
                                <p>{profileValues.street1}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Street 2:</p>
                                <p>{profileValues.street2}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">City:</p>
                                <p>{profileValues.city}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Zip:</p>
                                <p>{profileValues.zip}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">State:</p>
                                <p>{profileValues.state}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Province:</p>
                                <p>{profileValues.province}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Address in Sri Lanka:</p>
                                <p>{profileValues.addressInDestination}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Visa Required Days:</p>
                                <p>{tripValues.visaRequiredDays}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Arriving By:</p>
                                <p>{tripValues.arrivingBy}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Airline/Cruise Line:</p>
                                <p>{tripValues.airlineOrCruiseline}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Flight No/Ship Name:</p>
                                <p>{tripValues.vehicleNo}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Boarderd City:</p>
                                <p>{tripValues.boardedCity}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Purpose of Visit:</p>
                                <p>{tripValues.tripPurpose}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">No. of Visits:</p>
                                <p>{tripValues.noOfVisits}</p>
                            </div>
                            <div className="each-item">
                                <p className="label-tag">Arrival Date:</p>
                                <p>{tripValues.arrivalDate}</p>
                            </div>
                        </div>
                        <div className='form-action-btn'>
                            <button className='prev-btn' onClick={(e) => { e.preventDefault(); setActiveStep(3) }}>PREV</button>
                            <button onClick={(e) => { e.preventDefault(); navigate('/dashboard/user-settings/family-profiles/add'); }}>ADD MEMBER</button>
                            <button onClick={(e) => { e.preventDefault(); navigate('/dashboard/home'); }}>SUBMIT FORM</button>
                        </div>
                    </div>
                </section>

            </form>
        </div>
    )
}

export default EVisaApplication
