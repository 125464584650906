export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const MOBILE_NO_REGEX = /^[0-9]{10}$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const ACCEPTED_FILE_EXTENSIONS = ["jpg", "jpeg", "png", "pdf"];
export const ACCEPTED_IMAGE_EXTENSIONS = ["jpg", "jpeg", "png"];
export const DEFAULT_USER_PIC = "/assets/images/users/default-user.png";
export const RATING_VALUES = {
    1: 'Not as Good',
    2: 'As I expected',
    3: 'Better',
    4: 'Much Better',
    5: 'Excellent+',
};