import React, { useEffect } from 'react';
import './restaurant-results.css';
import ShoppingItemResults from './shopping-items-results/shopping-items-results';

const RestaurantResults = (props) => {
    const [showShoppingSearchResultsComp, setShowShoppingSearchResultsComp] = React.useState(false);
    const [sponsoredResults, setSponsoredResults] = React.useState([
        {
            name: "Five Guys",
            image: "/assets/images/market-place/shopping-app-images/Five Guys.jpg"
        },
        {
            name: "Apple Bee's Grill + Bar",
            image: "/assets/images/market-place/shopping-app-images/pexels-valeria-boltneva-1639557.jpg"
        },
        {
            name: "FLIPP Burger",
            image: "/assets/images/market-place/shopping-app-images/Flip Burger.jpg",
            enableClick: true
        }
    ])

    const [searchResults, setSearchResults] = React.useState([
        {
            name: "FLIPP Burger",
            time: "11AM - 11PM",
            tags: ["Burgers", "Sports Bar", "American"],
            price: 5,
            count: 0,
            image: "/assets/images/market-place/shopping-app-images/Flip Burger.jpg",
            desc: "Here at FLIPP, we're not just flippin' burgers. We're flippin' taste buds, minds and possibilities.  We're flippin' experiences, perceptions and flippin' the entire flippin' burger-verse on its head. That's because at FLIPP, we're making the most lip smacking, arm licking, beard picking, finger dripping gourmet burger combinations ever combinationed."
        },
        {
            name: "Five Guys",
            time: "10AM - 11PM",
            tags: ["Burgers", "Restaurant", "American"],
            price: 12,
            count: 0,
            image: "/assets/images/market-place/shopping-app-images/Five Guys.jpg",
            desc: "Family owned and operated, Five Guys is one of the leading fast casual burger restaurants in the world, offering a classic take on burgers, hot dogs, fries, milkshakes and more."
        },
        {
            name: "In-N-Out Burger",
            time: "6AM - 2AM",
            tags: ["Burgers", "Fast Food", "American"],
            price: 15,
            count: 0,
            image: "/assets/images/market-place/shopping-app-images/In-N-Out Burger.jpg",
            desc: "At In-N-Out Burger, we use only the freshest ingredients with no heat lamps, freezers or microwaves to deliver on our promise of Quality You Can Taste®."
        },
        {
            name: "Fat Burger",
            time: "10AM - Midnight",
            tags: ["Burgers", "Fast Food", "American"],
            price: 15,
            count: 0,
            image: "/assets/images/market-place/shopping-app-images/Fat Burger.jpg",
            desc: "Welcome to The Last Great Hamburger Stand. For over 70 years we have kept it simple: using the highest quality ingredients & handcrafting every burger to perfection."
        }
    ]
    )

    const toggleShowShoppingSearchResultsFlag = () => { setShowShoppingSearchResultsComp(!showShoppingSearchResultsComp) }

    // useEffect(() => {
    //     if ("name" in props.selectedFavoriteItem) {
    //         toggleShowShoppingSearchResultsFlag();
    //     }
    // }, [toggleShowShoppingSearchResultsFlag])

    const increamentCount = (item, index) => {
        var tempSearchResults = [...searchResults];
        tempSearchResults[index]["count"] = tempSearchResults[index]["count"] + 1;
        setSearchResults(tempSearchResults);
    }

    const decreamentCount = (item, index) => {
        var tempSearchResults = [...searchResults];
        if (tempSearchResults[index]["count"] > 0) {
            tempSearchResults[index]["count"] = tempSearchResults[index]["count"] - 1;
            setSearchResults(tempSearchResults);
        }
    }

    const openSearchShoppingItem = (item) => {
        toggleShowShoppingSearchResultsFlag();
    }


    return (
        <div className="restaurant-results-component">
            {!showShoppingSearchResultsComp ?
                <div className="restaurant-results-contents">
                    <div className="restaurant-results-tabs restaurant-types-tabs slide-right">
                        <h3 className="nav-tab-header">TYPES OF {props.selectedStore}</h3>
                        {
                            props.storeTypes.map((eachItem, index) => {
                                return (
                                    <div className="each-navigation-tab" key={index}>
                                        <img src={eachItem.image} alt={eachItem.name} />
                                        <h3>{eachItem.name}</h3>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="restaurant-results-tabs sponsored-results-tabs slide-right">
                        <h3 className="nav-tab-header">SPONSORED RESULTS</h3>
                        {
                            sponsoredResults.map((eachItem, index) => {
                                return (
                                    <div className="each-navigation-tab" key={index} onClick={() => { if (eachItem.enableClick) { toggleShowShoppingSearchResultsFlag(); } }}>
                                        <img src={eachItem.image} alt={eachItem.name} />
                                        <h4>{eachItem.name}</h4>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="restaurant-results-tabs all-results-tabs slide-right">
                        <h3 className="nav-tab-header left-align">SEARCH RESULTS</h3>
                        {
                            searchResults.map((eachItem, index) => {
                                return (
                                    <div className="each-menu-item" onClick={() => { openSearchShoppingItem(eachItem) }} key={index}>
                                        <div className="menu-img-container">
                                            <img src={eachItem.image} alt="menu-item" className="menu-img" />
                                        </div>
                                        <div className="menu-item-contents">
                                            <h3 className="menu-item-title">
                                                {eachItem.name} {/* - ${eachItem.price} */}
                                            </h3>
                                            <div className="item-tags">
                                                {
                                                    eachItem.tags.map((eachTag, tagIndex) => { return (<p key={tagIndex}>{eachTag}</p>) })
                                                }
                                            </div>
                                            <p className="item-shop-time">{eachItem.time}</p>
                                            <p className="menu-item-desc">
                                                {eachItem.desc}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="action-btn-container">
                            <button className="default-btn bg-theme-color-2" onClick={() => props.onCloseBtnClick()}> &lt;&lt; GO BACK TO MENU</button>
                        </div>
                    </div>
                </div>
                :
                <>
                    <ShoppingItemResults
                        onCloseBtnClick={toggleShowShoppingSearchResultsFlag} />
                </>
            }
        </div>
    )

}

export default RestaurantResults;