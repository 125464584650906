import React from 'react';
import './travel-insurance.css';
import { FormControl, InputLabel, TextField, Chip, Input } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const TravelInsurance = (props) => {
    const [tripValue, setTripValues] = React.useState({
        travelDestinations: [],
        travelStartDate: null,
        travelEndDate: null
    })

    const [destination, setDestination] = React.useState("");

    const handleDateInputChange = (e, key) => {
        setTripValues({ ...tripValue, [key]: e });
    }
    return (
        <div className="travel-insurance-container">
            <div className="travel-insurance-header-section">
                <p className='travel-insurance-header'>TRAVEL INSURANCE</p>
            </div>
            <div className="travel-insurance-form-contents">
                <div className="travel-insurance-form-column">
                    <h2 className="column-title">COVID TRAVEL INSURANCE</h2>
                    <form>
                        <div className="form-section">
                            <h3 className="section-title">Travel Dates</h3>
                            <div className="mui-form-control mui-date-control">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="Start Date *"
                                        value={tripValue.travelStartDate}
                                        onChange={(e) => { handleDateInputChange(e, 'travelStartDate') }}
                                        renderInput={(params) => <TextField {...params} />}
                                        closeOnSelect={true}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="mui-form-control mui-date-control">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker
                                        label="End Date *"
                                        value={tripValue.travelEndDate}
                                        onChange={(e) => { handleDateInputChange(e, 'travelEndDate') }}
                                        renderInput={(params) => <TextField {...params} />}
                                        closeOnSelect={true}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                    </form>
                    <div className="form-section">
                        <h3 className="section-title">Travel Details: </h3>
                        <p className="muted-text">Prior to this trip, have you or anyone in your travel party traveled to another international destination? </p>
                        <div className="mui-form-control">
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel htmlFor="add-location-input">Provide Names of Destinations</InputLabel>
                                    <Input
                                        id="add-location-input"
                                        type="text"
                                        value={destination}
                                        onChange={(e) => { setDestination(e.target.value) }}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <Chip label="Location 1" onDelete={() => { }} />
                        <Chip label="Location 2" onDelete={() => { }} />
                    </div>
                </div>
                <div className="travel-insurance-form-column">
                    <div className="form-section">
                        <h3 className="section-title">Coverage: </h3>
                        <table className="coverage-details-table">
                            <tbody>
                                <tr>
                                    <td>Deductible (per person):</td>
                                    <td>$500</td>
                                </tr>
                                <tr>
                                    <td>Coverage Dates:</td>
                                    <td>$500</td>
                                </tr>
                                <tr>
                                    <td>Policy Maximum:</td>
                                    <td>6/18/2021 – 6/22/2021</td>
                                </tr>
                                <tr>
                                    <td>Medical:</td>
                                    <td>To Policy Maximum</td>
                                </tr>
                                <tr>
                                    <td>Repatriation:</td>
                                    <td>To Policy Maximum</td>
                                </tr>
                                <tr>
                                    <td>Evacuation:</td>
                                    <td>$1,000,000 USD</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <table className="update-policy-member-table">
                        <thead>
                            <tr>
                                <th>Itemized</th>
                                <th>Cost</th>
                                <th># of People</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Primary</td>
                                <td>$20</td>
                                <td className="toggle-counter">
                                    <img src="/assets/icons/decreament.png" alt="Inc" />
                                    <span>1</span>
                                    <img src="/assets/icons/increament.png" alt="Dec" />
                                </td>
                                <td className="right-align">$20</td>
                            </tr>
                            <tr>
                                <td>Spouse</td>
                                <td>$10</td>
                                <td className="toggle-counter">
                                    <img src="/assets/icons/decreament.png" alt="Inc" />
                                    <span>1</span>
                                    <img src="/assets/icons/increament.png" alt="Dec" />
                                </td>
                                <td className="right-align">$10</td>
                            </tr>
                            <tr>
                                <td>Dependents</td>
                                <td>$10</td>
                                <td className="toggle-counter">
                                    <img src="/assets/icons/decreament.png" alt="Inc" />
                                    <span>2</span>
                                    <img src="/assets/icons/increament.png" alt="Dec" />
                                </td>
                                <td className="right-align">$20</td>
                            </tr>
                            <tr>
                                <td colSpan="3"></td>
                                <td className="total-amt">Total: $50</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="right-align">
                        <button className="add-policy-btn default-btn" >ADD POLICY</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TravelInsurance;