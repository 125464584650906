import React from 'react';

import './login.css';
import { FormControl, FormControlLabel, IconButton, Input, InputLabel, InputAdornment, Checkbox, FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../config/constants';
import { validateRegex } from '../../../services/utilities';
import { Link, useNavigate } from 'react-router-dom';
import { LOGGED_USER } from '../../../config/user-data';

const Login = (props) => {
    let navigate = useNavigate();

    const [authValues, setAuthValues] = React.useState({
        username: '',
        password: ''
    });

    const [componentFlags, setFlags] = React.useState({
        showPassword: false,
        rememberMe: true
    })

    const handleAuthDataChange = (key) => (event) => {
        setAuthValues({ ...authValues, [key]: event.target.value });
    };

    const toggleComponentFlags = (key) => (event) => {
        setFlags({ ...componentFlags, [key]: !componentFlags[key] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const logUserIn = () => {
        LOGGED_USER.USER_ID = "abc";
        if (authValues.username === "newuser@gmail.com"){
            navigate("/auth/create-profile");
        }
        else{
            if ((authValues.username === "Jo@magellango.com") || (authValues.username === "James@magellango.com") || (authValues.username === "guest@magellango.com")){
                if (authValues.password === "Test@123") {
                    navigate("/dashboard/home");
                }
                else {
                    alert ("Invalid Username & Passwod !!!");
                }
            }
            else {
                alert ("Invalid Username & Passwod !!!");
            }
        }
       
    }

    return (
        <div className="login-container">
            <div className="login-form-container">
                <div className="form-header">
                    <div className="header-img">
                        <img src="/assets/icons/default-user.png" alt="" />
                    </div>
                    <div className="header-text">
                        <h2>LOGIN</h2>
                    </div>
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel htmlFor="login-username">Username</InputLabel>
                        <Input
                            id="login-username"
                            type="text"
                            autoFocus={true}
                            value={authValues.username}
                            onChange={handleAuthDataChange('username')}
                            error={!validateRegex(EMAIL_REGEX, authValues.username)}
                        />
                    </FormControl>
                    {!validateRegex(EMAIL_REGEX, authValues.username) ? <FormHelperText>Please provide a valid username.</FormHelperText> : <></>}
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel htmlFor="login-password">Password</InputLabel>
                        <Input
                            id="login-password"
                            type={componentFlags.showPassword ? 'text' : 'password'}
                            value={authValues.password}
                            onChange={handleAuthDataChange('password')}
                            error={!validateRegex(PASSWORD_REGEX, authValues.password)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={toggleComponentFlags('showPassword')}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {componentFlags.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {!validateRegex(PASSWORD_REGEX, authValues.password) ? <FormHelperText>Password must have atleast 8 characters and contain atleast 1 capital, 1 small and 1 special character.</FormHelperText> : <></>}
                </div>
                <div className="mui-form-control">
                    <FormControlLabel control={<Checkbox checked={componentFlags.rememberMe} onChange={toggleComponentFlags('rememberMe')} />} label="Remember Me?" />
                </div>
                <div className="mui-form-control">
                    <button className="submit-btn" onClick={() => { logUserIn(); }}>SUBMIT</button>
                </div>
                <div className="link-texts">
                    <Link to="/auth/forgot-password">Forgot Password? Need help</Link>
                    <Link to="/auth/register" >New User? Register Now</Link>
                </div>
            </div>
        </div>
    )
}

export default Login;
