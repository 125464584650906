import LandingPage from "../components/basic/landing-page/landing-page";

// Authentication Components
import Auth from "../components/auth/auth";
import Login from "../components/auth/login/login";
import Register from "../components/auth/register/register";
import ForgotPassword from "../components/auth/forgot-password/forgot-password";
import CreateProfile from "../components/auth/create-profile/create-profile";

// Dashboard Components
import Dashboard from "../components/dashboard/dashboard";

import Home from "../components/dashboard/home/home";

import EVisa from "../components/dashboard/evisa/evisa";
import EVisaApplication from "../components/dashboard/evisa/evisa-application/evisa-application";
import VaccinationProof from "../components/dashboard/evisa/vaccination-proof/vaccination-proof";
import IdentityVerification from "../components/dashboard/evisa/identity-verification/identity-verification";
import EVisaPayment from "../components/dashboard/evisa/evisa-payment/evisa-payment";


import UserSettings from "../components/dashboard/user-settings/user-settings";
import FamilyProfiles from "../components/dashboard/user-settings/family-profiles/family-profiles";
import ViewProfiles from "../components/dashboard/user-settings/family-profiles/view-profiles/view-profiles";
import AddProfile from "../components/dashboard/user-settings/family-profiles/add-profile/add-profile";
import EditProfile from "../components/dashboard/user-settings/family-profiles/edit-profile/edit-profile";
import DeleteProfile from "../components/dashboard/user-settings/family-profiles/delete-profile/delete-profile";
import UserDashboard from "../components/dashboard/user-settings/user-dashboard/user-dashboard";
import Settings from "../components/dashboard/user-settings/settings/settings";

import Travel from "../components/dashboard/travel/travel";
import CreateTrip from "../components/dashboard/travel/create-trip/create-trip";
import TravelChecklist from "../components/dashboard/travel/travel-checklist/travel-checklist";
import TravelDocuments from "../components/dashboard/travel/travel-documents/travel-documents";
import HealthDocuments from "../components/dashboard/travel/health-documents/health-documents";
import TravelInsurance from "../components/dashboard/travel/travel-insurance/travel-insurance";
import TravelPassPayment from "../components/dashboard/travel/travel-pass-payment/travel-pass-payment";
import GovernmentApproval from "../components/dashboard/travel/government-approval/government-approval";

import MarketPlace from "../components/dashboard/market-place/market-place";
import PlanExcursions from "../components/dashboard/market-place/plan-excursions/plan-excursions";
import DineAndShop from "../components/dashboard/market-place/airport-dine-and-shop/dine-and-shop";
import AirtelMapInfo from "../components/dashboard/market-place/airport-map/airport-map";
import Help from "../components/dashboard/others/help/help";
import TermsAndConditions from "../components/dashboard/others/terms-and-conditions/terms-and-conditions";
import PrivacyPolicies from "../components/dashboard/others/privacy-policies/privacy-policies";
import RefundPolicies from "../components/dashboard/others/refund-policies/refund-policies";
import AdminHome from "../components/dashboard/admin-home/admin-home";
import ShoppingPayment from "../components/dashboard/market-place/shopping-payment/shopping-payment";
import GroundTransportation from "../components/dashboard/market-place/ground-transportation/ground-transportation";



let routes = [
    {
        path: "/",
        is_exact: false,
        component: LandingPage
    },
    {
        path: "/help",
        is_exact: true,
        component: Help
    },
    {
        path: "/auth/*",
        component: Auth,
        routes: [
            {
                path: "/login",
                is_exact: true,
                component: Login
            },
            {
                path: "/register",
                is_exact: true,
                component: Register
            },
            {
                path: "/forgot-password",
                is_exact: true,
                component: ForgotPassword
            },
            {
                path: "/create-profile",
                is_exact: true,
                component: CreateProfile
            }
        ]
    },
    {
        path: "/dashboard/*",
        component: Dashboard,
        routes: [
            {
                path: "/admin-home",
                is_exact: true,
                component: AdminHome
            },
            {
                path: "/home",
                is_exact: true,
                component: Home
            },
            {
                path: "/help",
                is_exact: true,
                component: Help
            },
            {
                path: "/terms-and-conditions",
                is_exact: true,
                component: TermsAndConditions
            },
            {
                path: "/privacy-policies",
                is_exact: true,
                component: PrivacyPolicies
            },
            {
                path: "/refund-policies",
                is_exact: true,
                component: RefundPolicies
            },
            {
                path: "/evisa/*",
                is_exact: true,
                component: EVisa,
                routes: [
                    {
                        path: "/application-form",
                        is_exact: true,
                        component: EVisaApplication
                    },
                    {
                        path: "/vaccination-proof",
                        is_exact: true,
                        component: VaccinationProof
                    },
                    {
                        path: "/identity-verification",
                        is_exact: true,
                        component: IdentityVerification
                    },
                    {
                        path: "/shopping-cart",
                        is_exact: true,
                        component: EVisaPayment
                    }
                ]
            },
            {
                path: "/travel/*",
                is_exact: true,
                component: Travel,
                routes: [
                    {
                        path: "/create-trip",
                        is_exact: true,
                        component: CreateTrip
                    },
                    {
                        path: "/travel-checklist",
                        is_exact: true,
                        component: TravelChecklist
                    },
                    {
                        path: "/travel-documents",
                        is_exact: true,
                        component: TravelDocuments
                    },
                    {
                        path: "/health-documents",
                        is_exact: true,
                        component: HealthDocuments
                    },
                    {
                        path: "/travel-insurance",
                        is_exact: true,
                        component: TravelInsurance
                    },
                    {
                        path: "/payment",
                        is_exact: true,
                        component: TravelPassPayment
                    },
                    {
                        path: "/government-approval",
                        is_exact: true,
                        component: GovernmentApproval
                    }
                ]
            },
            {
                path: "/market-place/*",
                is_exact: true,
                component: MarketPlace,
                routes: [
                    {
                        path: "/plan-your-excursions",
                        is_exact: true,
                        component: PlanExcursions
                    },
                    {
                        path: "/airport-dine-and-shop",
                        is_exact: true,
                        component: DineAndShop
                    },
                    {
                        path: "/airport-info",
                        is_exact: true,
                        component: AirtelMapInfo
                    },
                    {
                        path: "/airport-map",
                        is_exact: true,
                        component: AirtelMapInfo
                    },
                    {
                        path: "/payment",
                        is_exact: true,
                        component: ShoppingPayment
                    },
                    {
                        path: "/transportation",
                        is_exact: true,
                        component: GroundTransportation
                    }
                ]
            },
            {
                path: "/user-settings/*",
                is_exact: true,
                component: UserSettings,
                routes: [
                    {
                        path: "/family-profiles/*",
                        is_exact: true,
                        component: FamilyProfiles,
                        routes: [
                            {
                                path: "/all-members",
                                is_exact: true,
                                component: ViewProfiles
                            },
                            {
                                path: "/add",
                                is_exact: true,
                                component: AddProfile
                            },
                            {
                                path: "/edit",
                                is_exact: true,
                                component: EditProfile
                            },
                            {
                                path: "/delete",
                                is_exact: true,
                                component: DeleteProfile
                            }
                        ]
                    },
                    {
                        path: "/user-dashboard",
                        is_exact: true,
                        component: UserDashboard
                    },
                    {
                        path: "/settings",
                        is_exact: true,
                        component: Settings
                    }
                ]
            }
        ]
    }
];
export default routes;