import React from 'react';
import './all-vaccines-taken-list.css';

const AllVaccineTakenList = (props) => {

    const [showVaccineDataFlag, toggleVaccinationData] = React.useState();

    return (
        <div className="all-vaccines-list-component">
            {
                !showVaccineDataFlag ?
                    <div className="all-vaccines-list-content">
                        <img src="/assets/icons/close-icon.png" className="close-icon" alt="Close" onClick={() => { props.onCloseBtnClick() }} />
                        <h3>Vaccination</h3>
                        <ul className="vaccines-list">
                            <li>
                                <p onClick={() => { toggleVaccinationData(!showVaccineDataFlag) }}>COVID-19</p>
                            </li>
                            <li>
                                <p>MALARIA</p>
                            </li>
                            <li>
                                <p>ZIKA</p>
                            </li>
                            <li>
                                <p>YELLOW FEVER</p>
                            </li>
                        </ul>
                    </div>
                    :
                    <div className="vaccine-taken-img-container">
                        <img src="/assets/icons/close-icon.png" className="close-icon" alt="Close" onClick={() => { toggleVaccinationData() }} />
                        <img src="/assets/images/vaccination-data/John Smith - Vaccination Card.png" className="vaccination-img" alt="Vaccine Taken" />
                    </div>
            }



        </div>
    )

}

export default AllVaccineTakenList;
