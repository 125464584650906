import React from 'react';
import './user-dashboard.css';

const UserDashboard = () => {
    return (
        <div className="user-dashboard-component">
            <div className="user-dashboard-header-section">
                <p className='user-dashboard-header'>MY DASHBOARD</p>
            </div>
            <div className="user-dashboard-container">
                <div className="user-dashboard-content bg-theme-color-1">
                    <div className="trip-images-list">
                        <div className="each-trip-img">
                            <img src="/assets/images/trips/sri-lanka.png" alt="Sri Lanka" />
                            <h3>Sri Lanka</h3>
                        </div>
                        <div className="each-trip-img">
                            <img src="/assets/images/trips/maldives.png" alt="Maldives" />
                            <h3>Maldives</h3>
                        </div>
                        <div className="each-trip-img">
                            <img src="/assets/images/trips/nepal.png" alt="Nepal" />
                            <h3>Nepal</h3>
                        </div>
                        <div className="each-trip-img">
                            <img src="/assets/images/trips/turks-n-caicos.png" alt="Turks Caicos" />
                            <h3>Turks &amp; Caicos</h3>
                        </div>
                    </div>
                    <div className="trips-data-container">
                        <div className="trips-data-table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Country</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Trip Purpose</th>
                                        <th>Hotel</th>
                                        <th>Party Size</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Sri Lanka</td>
                                        <td>10-12-2021</td>
                                        <td>10-15-2021</td>
                                        <td>Business</td>
                                        <td>Taj Samudra</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td>Sri Lanka</td>
                                        <td>06-05-2021</td>
                                        <td>06-07-2021</td>
                                        <td>Business</td>
                                        <td>Taj Samudra</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td>Sri Lanka</td>
                                        <td>07-23-2021</td>
                                        <td>07-30-2021</td>
                                        <td>Vacation</td>
                                        <td>Jetwing Lighthouse</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td>Sri Lanka</td>
                                        <td>04-09-2021</td>
                                        <td>04-11-2021</td>
                                        <td>Business</td>
                                        <td>Taj Samudra</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td>Sri Lanka</td>
                                        <td>08-16-2021</td>
                                        <td>08-21-2021</td>
                                        <td>Business</td>
                                        <td>Taj Samudra</td>
                                        <td>1</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDashboard;