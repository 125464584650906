import React from 'react';
import ShoppingItemResults from '../airport-dine-and-shop/restaurant-results/shopping-items-results/shopping-items-results';
import './airport-map.css';

const AirtelMapInfo = (props) => {

    const mapImageRef = React.createRef();
    const compRef = React.createRef();
    const [selectedShop, setSelectedShop] = React.useState("");
    const [shoppingSearchResultsFlag, setShoppingSearchResultsFlag] = React.useState(false);
    const mapCoordinates = {
        "43": { x1: 82.4, x2: 83.9, y1: 34.0, y2: 35.5 },
        "48": { x1: 66.5, x2: 68.0, y1: 13.2, y2: 15.2 },
        "49": { x1: 67.5, x2: 69.0, y1: 22.5, y2: 24.5 }
    };
    const shopDetails = {
        "43": {
            name: "FLIPP Burger",
            time: "11AM - 11PM",
            tags: ["Burgers", "Sports Bar", "American"],
            image: "/assets/images/market-place/shopping-app-images/Flip Burger.jpg",
            desc: "Here at FLIPP, we're not just flippin' burgers. We're flippin' taste buds, minds and possibilities.  We're flippin' experiences, perceptions and flippin' the entire flippin' burger-verse on its head. That's because at FLIPP, we're making the most lip smacking, arm licking, beard picking, finger dripping gourmet burger combinations ever combinationed."
        },
        "48": {
            name: "FLIPP Burger",
            time: "11AM - 11PM",
            tags: ["Burgers", "Sports Bar", "American"],
            image: "/assets/images/market-place/shopping-app-images/Flip Burger.jpg",
            desc: "Here at FLIPP, we're not just flippin' burgers. We're flippin' taste buds, minds and possibilities.  We're flippin' experiences, perceptions and flippin' the entire flippin' burger-verse on its head. That's because at FLIPP, we're making the most lip smacking, arm licking, beard picking, finger dripping gourmet burger combinations ever combinationed."
        },
        "49": {
            name: "FLIPP Burger",
            time: "11AM - 11PM",
            tags: ["Burgers", "Sports Bar", "American"],
            image: "/assets/images/market-place/shopping-app-images/Flip Burger.jpg",
            desc: "Here at FLIPP, we're not just flippin' burgers. We're flippin' taste buds, minds and possibilities.  We're flippin' experiences, perceptions and flippin' the entire flippin' burger-verse on its head. That's because at FLIPP, we're making the most lip smacking, arm licking, beard picking, finger dripping gourmet burger combinations ever combinationed."
        }
    }

    const updateSelectedShop = (val) => {
        if (val !== "") {
            compRef.current.classList.add("overflow-hidden")
        }
        else {
            compRef.current.classList.remove("overflow-hidden")
        }

        setSelectedShop(val)
    }
    const toggleShowShoppingSearchResultsFlag = () => { compRef.current.classList.remove("overflow-hidden"); setShoppingSearchResultsFlag(!shoppingSearchResultsFlag) }

    const getCoordinates = (e) => {
        var offset = mapImageRef.current.getBoundingClientRect();
        var x = Math.floor((e.pageX - offset.left) / offset.width * 10000) / 100;;
        var y = Math.floor((e.pageY - offset.top) / offset.height * 10000) / 100;;
        return { "x": x, "y": y };
    }

    const catchMapClick = (e) => {
        let coord = getCoordinates(e);
        let x = coord["x"];
        let y = coord["y"];
        console.log(coord);
        for (var eachMarker in mapCoordinates) {
            if (x >= mapCoordinates[eachMarker]["x1"] && x <= mapCoordinates[eachMarker]["x2"] &&
                y >= mapCoordinates[eachMarker]["y1"] && y <= mapCoordinates[eachMarker]["y2"]) {
                updateSelectedShop(eachMarker);
                break;
            }
            else {
                updateSelectedShop("");
            }
        }

        console.log(selectedShop)
    }

    const showCursorOnImage = (e) => {
        let coord = getCoordinates(e);
        let x = coord["x"];
        let y = coord["y"];
        for (var eachMarker in mapCoordinates) {
            if (x >= mapCoordinates[eachMarker]["x1"] && x <= mapCoordinates[eachMarker]["x2"] &&
                y >= mapCoordinates[eachMarker]["y1"] && y <= mapCoordinates[eachMarker]["y2"]) {
                mapImageRef.current.classList.add("pointer");
                break;
            }
            else {
                mapImageRef.current.classList.remove("pointer");
            }
        }
    }




    return (
        <div className="airport-map-info-component" ref={compRef}>
            {
                !shoppingSearchResultsFlag ?
                    <>
                        <div className="airport-map-info-header-section">
                            <p className='airport-map-info-header'>AIRPORT MAP &amp; INFO.</p>
                        </div>
                        {/* <div className="airport-map-info-component-header">
                            <h2>AIRPORT MAP &amp; INFO.</h2>
                        </div> */}
                        <div className="airport-map-info-contents">
                            <div className="airport-map-info-column map-container">
                                <img ref={mapImageRef} src="/assets/images/market-place/airport-map/airport-map-new.jfif" alt="Map" className="map-img" onClick={(e) => { catchMapClick(e) }} onMouseMove={(e) => { showCursorOnImage(e) }} />
                                <p className="helper-text">Click on the numbers on the map to know more.</p>
                            </div>

                            {
                                selectedShop ?
                                    <div className="shop-details-container shopping-details-popup">
                                        <div className="each-menu-item slide-up" onClick={() => { toggleShowShoppingSearchResultsFlag() }} >
                                            <img src="/assets/icons/close-icon.png" className="close-icon" alt="Close" onClick={(e) => { e.stopPropagation(); updateSelectedShop("") }} />
                                            <div className="menu-img-container">
                                                <img src={shopDetails[selectedShop].image} alt="menu-item" className="menu-img" />
                                            </div>
                                            <div className="menu-item-contents">
                                                <h3 className="menu-item-title">
                                                    {shopDetails[selectedShop].name}
                                                </h3>
                                                <div className="item-tags">
                                                    {
                                                        shopDetails[selectedShop].tags.map((eachTag, tagIndex) => { return (<p key={tagIndex}>{eachTag}</p>) })
                                                    }
                                                </div>
                                                <p className="item-shop-time">{shopDetails[selectedShop].time}</p>
                                                <p className="menu-item-desc">
                                                    {shopDetails[selectedShop].desc}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }
                        </div>
                    </>
                    :
                    <div className="item-search-results-container">
                        <ShoppingItemResults
                            onCloseBtnClick={toggleShowShoppingSearchResultsFlag}
                            history={props.history} />
                    </div>

            }
        </div>
    )

}

export default AirtelMapInfo;
