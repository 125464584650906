import React from 'react';

import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material';
import { Link } from 'react-router-dom';

import './forgot-password.css';
import { validateRegex } from '../../../services/utilities';
import { EMAIL_REGEX } from '../../../config/constants';

const ForgotPassword = (props) => {
    const [authValues, setAuthValues] = React.useState({
        username: ""
    });

    const handleAuthDataChange = (key) => (event) => {
        setAuthValues({ ...authValues, [key]: event.target.value });
    };


    return (
        <div className="forgot-password-container">
            <div className="forgot-password-form-container">
                <div className="form-header">
                    <div className="header-img">
                        <img src="/assets/icons/key.png" alt="" />
                    </div>
                    <div className="header-text">
                        <h2>FORGOT PASSWORD</h2>
                    </div>
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel htmlFor="forgot-password-username">Username</InputLabel>
                        <Input
                            id="forgot-password-username"
                            type="text"
                            autoFocus={true}
                            value={authValues.username}
                            onChange={handleAuthDataChange('username')}
                            error={!validateRegex(EMAIL_REGEX, authValues.username)}
                        />
                    </FormControl>
                    {!validateRegex(EMAIL_REGEX, authValues.username) ? <FormHelperText>Please provide a valid username.</FormHelperText> : <></>}
                </div>
                <div className="mui-form-control">
                    <button className="submit-btn">SUBMIT</button>
                </div>
                <div className="link-texts">
                    <Link to="/auth/register" >New User? Register Now</Link>
                    <Link to="/auth/login">Go to Login</Link>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;
