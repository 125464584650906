import React from 'react';
import './health-survey.css';
// import SurveyConfirmation from './survey-confirmation/survey-confirmation';

const HealthSurvey = (props) => {
    const [showConfirmation, setShowConfirmationFlag] = React.useState(false);
    const [surveyQuestion1State, setSurveyQuestion1State] = React.useState(null);
    const [surveyQuestion2State, setSurveyQuestion2State] = React.useState(null);
    const [surveyQuestion3State, setSurveyQuestion3State] = React.useState(null);

    const toggleConfirmationFlag = () => {
        setShowConfirmationFlag(!showConfirmation);
    }


    return (
        <div className="health-survey-component">
            <div className="health-survey-contents">
                <div className="health-survey-column">
                    <h2 className="column-title">HEALTH SURVEY</h2>
                    <div className="survey-questions">
                        <div className="each-question">
                            <p className="question-text">1. Have you experienced any of the following symptoms in the past 48 hours:</p>
                            <ul className="question-items-list">
                                <li><p>Fever or chills</p></li>
                                <li><p>Shortness of Breath</p></li>
                                <li><p>Cough</p></li>
                                <li><p>Difficulty Breathing</p></li>
                                <li><p>Sore Throat</p></li>
                                <li><p>Congestion or Runny Nose</p></li>
                                <li><p>Fatigue</p></li>
                                <li><p>Headaches</p></li>
                                <li><p>Muscle/Body Aches</p></li>
                                <li><p>Loss of Taste or Smell</p></li>
                                <li><p>Nausea or Vomiting</p></li>
                                <li><p>Diarrhea</p></li>
                            </ul>
                            <div className="option-btn-row right-align">
                                <label>Please select one:</label>
                                <button className={surveyQuestion1State === true ? "yes-btn selected" : "yes-btn"} onClick={() => { setSurveyQuestion1State(true) }}>YES</button>
                                <button className={surveyQuestion1State === false ? "no-btn selected" : "no-btn"} onClick={() => { setSurveyQuestion1State(false) }}>NO</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="health-survey-column">
                    <div className="survey-questions">
                        <div className="each-question">
                            <p className="question-text">2. Have you tested positive for COVID-19 or been exposed to COVID-19 within the last 14 days?</p>
                            <div className="option-btn-row right-align">
                                <label>Please select one:</label>
                                <button className={surveyQuestion2State === true ? "yes-btn selected" : "yes-btn"} onClick={() => { setSurveyQuestion2State(true) }}>YES</button>
                                <button className={surveyQuestion2State === false ? "no-btn selected" : "no-btn"} onClick={() => { setSurveyQuestion2State(false) }}>NO</button>
                            </div>
                        </div>
                        <div className="each-question">
                            <p className="question-text">3. Have you been fully vaccinated or have you fully recovered from COVID-19 more than 14 days ago?</p>
                            <div className="option-btn-row right-align">
                                <label>Please select one:</label>
                                <button className={surveyQuestion3State === true ? "yes-btn selected" : "yes-btn"} onClick={() => { setSurveyQuestion3State(true) }}>YES</button>
                                <button className={surveyQuestion3State === false ? "no-btn selected" : "no-btn"} onClick={() => { setSurveyQuestion3State(false) }}>NO</button>
                            </div>
                        </div>
                    </div>
                    <div className="right-align">
                        <button className="submit-btn bg-theme-color-2" onClick={() => { toggleConfirmationFlag() }}>SUBMIT</button>
                    </div>
                </div>
            </div>
            {/* {showConfirmation ? <SurveyConfirmation onCloseBtnClick={toggleConfirmationFlag} /> : ""} */}
        </div>
    )

}

export default HealthSurvey;
