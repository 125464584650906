import React from 'react';
import './register.css';
import { FormControl, IconButton, Input, InputLabel, InputAdornment, Checkbox, FormHelperText, MenuItem, Select } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { EMAIL_REGEX, MOBILE_NO_REGEX, PASSWORD_REGEX } from '../../../config/constants';
import { validateRegex } from '../../../services/utilities';
import { Link, useNavigate } from 'react-router-dom';
import { LOGGED_USER } from '../../../config/user-data';

const Register = (props) => {
    const navigate = useNavigate();
    const [authValues, setAuthValues] = React.useState({
        username: '',
        password: '',
        confirmPassword: '',
        countryISDCode: '',
        phoneNo: ''
    });

    const [componentFlags, setFlags] = React.useState({
        showPassword: false,
        showConfirmPassword: false,
        rememberMe: true
    })

    const handleAuthDataChange = (key) => (event) => {
        setAuthValues({ ...authValues, [key]: event.target.value });
    };

    const toggleComponentFlags = (key) => (event) => {
        setFlags({ ...componentFlags, [key]: !componentFlags[key] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const registerUser = () => {
        LOGGED_USER.USER_ID = "abc";
        navigate('/dashboard/home');
    }

    return (
        <div className="register-container">
            <div className="register-form-container">
                <div className="form-header">
                    <div className="header-img">
                        <img src="/assets/icons/default-user.png" alt="" />
                    </div>
                    <div className="header-text">
                        <h2>REGISTER</h2>
                    </div>
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel htmlFor="register-username">Username</InputLabel>
                        <Input
                            id="register-username"
                            type="text"
                            autoFocus={true}
                            value={authValues.username}
                            onChange={handleAuthDataChange('username')}
                            error={!validateRegex(EMAIL_REGEX, authValues.username)}
                        />
                    </FormControl>
                    {!validateRegex(EMAIL_REGEX, authValues.username) ? <FormHelperText>Please provide a valid username.</FormHelperText> : <></>}
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel htmlFor="register-password">Password</InputLabel>
                        <Input
                            id="register-password"
                            type={componentFlags.showPassword ? 'text' : 'password'}
                            value={authValues.password}
                            onChange={handleAuthDataChange('password')}
                            error={!validateRegex(PASSWORD_REGEX, authValues.password)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={toggleComponentFlags('showPassword')}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {componentFlags.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {!validateRegex(PASSWORD_REGEX, authValues.password) ? <FormHelperText>Password must have atleast 8 characters and contain atleast 1 capital, 1 small and 1 special character.</FormHelperText> : <></>}
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel htmlFor="register-confirm-password">Confirm Password</InputLabel>
                        <Input
                            id="register-confirm-password"
                            type={componentFlags.showConfirmPassword ? 'text' : 'password'}
                            value={authValues.confirmPassword}
                            onChange={handleAuthDataChange('confirmPassword')}
                            error={!validateRegex(PASSWORD_REGEX, authValues.confirmPassword)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={toggleComponentFlags('showConfirmPassword')}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {componentFlags.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {
                        (authValues.confirmPassword && authValues.password !== authValues.confirmPassword)
                            ? <FormHelperText>Passwords do not match</FormHelperText>
                            : <></>
                    }
                </div>
                <div className="mui-form-control">
                    <FormControl variant="standard" sx={{ s: 1, minWidth: 2/10, mr: '10px' }}>
                        <InputLabel id="register-select-isd-code">ISD</InputLabel>
                        <Select
                            labelId="register-select-isd-code"
                            id="demo-simple-select-standard"
                            value={authValues.countryISDCode}
                            onChange={handleAuthDataChange('countryISDCode')}
                            label="ISD"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>+1</MenuItem>
                            <MenuItem value={91}>+91</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ s: 1, width: 'calc(80% - 10px)' }}>
                        <InputLabel htmlFor="register-phone-no">Contact No.</InputLabel>
                        <Input
                            id="register-phone-no"
                            type="text"
                            value={authValues.phoneNo}
                            onChange={handleAuthDataChange('phoneNo')}
                            error={!validateRegex(MOBILE_NO_REGEX, authValues.phoneNo)}
                        />
                    </FormControl>
                    {!validateRegex(MOBILE_NO_REGEX, authValues.phoneNo) ? <FormHelperText>Please provide a valid contact no.</FormHelperText> : <></>}
                </div>
                <div className="mui-form-control authorization-text-row">
                    I agree to <Checkbox  /> <Link to="#" >Privacy Policies</Link> and <Checkbox /> <Link to="#" >Terms and conditions</Link>.
                </div>
                <div className="mui-form-control">
                    <button className="submit-btn" onClick={() => {registerUser()}}>SUBMIT</button>
                </div>
                <div className="link-texts">
                    <Link to="/auth/login" >Already a user? Sign In</Link>
                </div>
            </div>
        </div>
    )
}

export default Register;