import React from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes
} from "react-router-dom";

import routes from './config/route-config';
import './app.css';
import Footer from './components/basic/footer/footer';

const App = (props) => {
    return (
        <Router>
            <div className="app-component">
                <Routes>
                    {routes.map((route, i) => (
                        <Route
                            key={i}
                            path={route.path}
                            element={<route.component {...props} routes={route.routes} />}
                        />
                    ))}
                    <Route
                        path="*"
                        element={<Navigate to="/" />}
                    />
                </Routes>


            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </Router>
    )
}

export default App;