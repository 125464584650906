import React from 'react';
import './vaccination-proof.css';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, FormControlLabel, IconButton, TextField } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const VaccinationProof = () => {
    const [selectedMember, setSelectedMember] = React.useState("");
    const [vaccineType, setVaccineType] = React.useState("");
    const [vaccineManufacturer, setVaccineManufacturer] = React.useState("");
    const [vaccinationDate, setVaccinationDate] = React.useState(null);

    const handleVaccineDateChange = (e) => {
        setVaccinationDate(e);
    }

    return (
        <div className="vaccination-proof-container">
            <div className="vaccination-proof-header-section">
                <p className='vaccination-proof-header'>VACCINATION PROOF</p>
            </div>
            <div className="evisa-vaccine-upload-form-container">
                <div className='evisa-vaccine-upload-form-content'>
                    <section className="evisa-vaccine-upload-form column-section">
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="select-family-member">Select Family Member *</InputLabel>
                                <Select
                                    id="select-family-member"
                                    value={selectedMember}
                                    onChange={(e) => { setSelectedMember(e.target.value) }}
                                    label="Select Family Member"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="1">John Smith</MenuItem>
                                    <MenuItem value="2">Rose Smith</MenuItem>
                                    <MenuItem value="3">Kelsey Smith</MenuItem>
                                    <MenuItem value="4">Aiden Smith</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='two-col-grid'>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="select-vaccine-type">Type Of Vaccine *</InputLabel>
                                    <Select
                                        id="select-vaccine-type"
                                        value={vaccineType}
                                        onChange={(e) => { setVaccineType(e.target.value) }}
                                        label="Select Vaccine Type"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">Cholera</MenuItem>
                                        <MenuItem value="2">Covid - 19</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="mui-form-control">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="select-manufacturer">Manufacturer *</InputLabel>
                                    <Select
                                        id="select-manufacturer"
                                        value={vaccineManufacturer}
                                        onChange={(e) => { setVaccineManufacturer(e.target.value) }}
                                        label="Select manufacturer"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="1">M1</MenuItem>
                                        <MenuItem value="2">M2</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <p className='checkbox-section-header'>
                            Type of Vaccination Shot:
                        </p>
                        <div className='two-col-grid vacine-type-checkbox-input'>
                            <div className="mui-form-control">
                                <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Shot 1" />
                            </div>
                            <div className="mui-form-control">
                                <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Shot 2" />
                            </div>
                            <div className="mui-form-control">
                                <FormControlLabel control={<Checkbox checked={false} onChange={() => { }} />} label="Shot 3" />
                            </div>
                        </div>
                        <div className='evisa-vaccine-upload-section'>
                            <span>Upload proof of vaccination: </span>
                            <IconButton color="primary" aria-label="upload picture" component="label">
                                <input hidden accept="*/*" type="file" />
                                <PhotoCamera />
                            </IconButton>
                        </div>
                        <div className="mui-form-control mui-date-control">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDatePicker
                                    label="Vaccination Date (MM/DD/YYYY)*"
                                    value={vaccinationDate}
                                    onChange={handleVaccineDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                    closeOnSelect={true}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='action-btn right-align'>
                            <button className='default-btn'>Upload</button>
                        </div>
                    </section>
                    <section className="evisa-vaccine-uploaded-data">
                        <table className='vaccination-data-table'>
                            <thead>
                                <tr>
                                    <th>Type of Vacciner</th>
                                    <th>Manufacturer</th>
                                    <th>Date (MM/DD/YYYY)</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Cholera</td>
                                    <td></td>
                                    <td>01/01/2022</td>
                                    <td>
                                        <div className="icons-cell">
                                            <img src="/assets/icons/gallery.png" alt="view" />
                                            <img src="/assets/icons/edit-note.png" alt="edit" />
                                            <img src="/assets/icons/close-icon.png" alt="close" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Covid - 19</td>
                                    <td>M1</td>
                                    <td>01/01/2022</td>
                                    <td>
                                        <div className="icons-cell">
                                            <img src="/assets/icons/gallery.png" alt="view" />
                                            <img src="/assets/icons/edit-note.png" alt="edit" />
                                            <img src="/assets/icons/close-icon.png" alt="close" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default VaccinationProof