import React from 'react';
import './travel-documents.css';
import DepartureForm from './departure-form/departure-form';
import ArrivalForm from './arrival-form/arrival-form';


const TravelDocuments = (props) => {

    const [index, setIndex] = React.useState(0);

    const handleChange = (newValue) => {
        setIndex(newValue);
    };

    return (
        <div className="travel-documents-container">
            <div className="travel-documents-header-section">
                <p className='travel-documents-header'>TRAVEL DOCUMENTS</p>
                <section className="tab-btns">
                    <button className={index === 0 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(0) }}>Immigration</button>
                    <button className={index === 1 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(1) }}>Customs</button>
                    <button className={index === 2 ? 'tab-btn selected' : 'tab-btn'} onClick={() => { handleChange(2) }}>Surveys</button>
                </section>

            </div>
            <div className="document-form-container">
                { index === 0 ? <ArrivalForm /> : <></> }
                { index === 2 ? <DepartureForm /> : <></> }
            </div>
        </div>
    )
}

export default TravelDocuments;