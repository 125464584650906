import React from 'react';
import './plan-excursions.css';
import { useNavigate } from 'react-router-dom';

const PlanExcursions = () => {
    const navigate = useNavigate();
    const tabItems = [
        {
            name: "Deep Sea Fishing",
            icon: "/assets/plan-excursions-icons/deep-sea-fishing.png"
        },
        {
            name: "Diving",
            icon: "/assets/plan-excursions-icons/diving.png"
        },
        {
            name: "Sailing",
            icon: "/assets/plan-excursions-icons/sailing.png"
        },
        {
            name: "Ecotourism",
            icon: "/assets/plan-excursions-icons/ecotourism.png"
        },
        {
            name: "Golfing",
            icon: "/assets/plan-excursions-icons/golfing.png"
        },
        {
            name: "Hiking",
            icon: "/assets/plan-excursions-icons/hiking.png"
        },
        {
            name: "Water Sports",
            icon: "/assets/plan-excursions-icons/water-sports.png"
        },
        {
            name: "Dining",
            icon: "/assets/plan-excursions-icons/dining.png"
        },
        {
            name: "Romance",
            icon: "/assets/plan-excursions-icons/romance.png"
        },
        {
            name: "Family",
            icon: "/assets/plan-excursions-icons/family.png"
        },
        {
            name: "Local Culture & History",
            icon: "/assets/plan-excursions-icons/local-culture-history.png"
        },
        {
            name: "FAQS",
            icon: "/assets/plan-excursions-icons/faqs.png"
        }
    ];
    return (
        <div className="plan-excursions-component">
            <div className="plan-excursions-header-section">
                <p className='plan-excursions-header'>PLAN YOUR TRIP</p>
            </div>
            <div className="plan-excursions-navigation-tabs">
                {
                    tabItems.map((eachItem, index) => {
                        return (
                            <div className="each-navigation-tab" key={index} onClick={() => { navigate('/dashboard/home') }}>
                                <img src={eachItem.icon} alt={eachItem.name} />
                                <h3>{eachItem.name}</h3>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default PlanExcursions