import React, { useEffect } from 'react';
import './travel-checklist.css';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GLOBAL_DATA } from '../../../../config/global-data';

const TravelChecklist = () => {
    const navigate = useNavigate();
    const [selectedTrip, setSelectedTrip] = React.useState("");
    const [collapsibleContent, setCollapsibleContent] = React.useState({
        0: false,
        1: false,
        2: false,
        3: false
    });

    const [overallImmigrationFlag, setOverallImmigrationFlag] = React.useState(0);
    const [overallHealthSurveyFlag, setOverallHealthSurveyFlag] = React.useState(0);
    const [overallInsurancePlanFlag, setOverallInsurancePlanFlag] = React.useState(0);
    const [overallVaccinationFlag, setOverallVaccinationFlag] = React.useState(0);
    const [overallLandingFeeFlag, setOverallLandingFeeFlag] = React.useState(0);

    const [tripMembers, setTripMembers] = React.useState([]);

    useEffect(() => {
        onTripSelection(GLOBAL_DATA.SELECTED_TRIP);
    }, [selectedTrip])

    const updateCollapsibleContent = (key, val) => {
        setCollapsibleContent({ ...collapsibleContent, [key]: val })
    }

    const redirectToComp = (userData, redirectURL) => {
        navigate(redirectURL);
    }

    const onTripSelection = (selectedId) => {
        let tempTripMembers = [
            {
                "FULL_NAME": "John Smith",
                "IMMIGRATION_FLAG": 1,
                "HEALTH_SURVEY": 1,
                "INSURANCE_PLAN": 1,
                "VACCINATION_FLAG": 1,
                "LANDINGFEES_FLAG": 1
            },
            {
                "FULL_NAME": "Rose Smith",
                "IMMIGRATION_FLAG": 0,
                "HEALTH_SURVEY": 1,
                "INSURANCE_PLAN": 1,
                "VACCINATION_FLAG": 1,
                "LANDINGFEES_FLAG": 1
            }
        ]

        setOverallImmigrationFlag(0)
        setOverallHealthSurveyFlag(1);
        setOverallInsurancePlanFlag(1)
        setOverallVaccinationFlag(1);
        setOverallLandingFeeFlag(0);

        if (selectedId === "2") {
            tempTripMembers = [
                {
                    "FULL_NAME": "John Smith",
                    "IMMIGRATION_FLAG": 1,
                    "HEALTH_SURVEY": 1,
                    "INSURANCE_PLAN": 1,
                    "VACCINATION_FLAG": 1,
                    "LANDINGFEES_FLAG": 1
                },
                {
                    "FULL_NAME": "Rose Smith",
                    "IMMIGRATION_FLAG": 0,
                    "HEALTH_SURVEY": 1,
                    "INSURANCE_PLAN": 1,
                    "VACCINATION_FLAG": 1,
                    "LANDINGFEES_FLAG": 1
                },
                {
                    "FULL_NAME": "Aiden Smith",
                    "IMMIGRATION_FLAG": 0,
                    "HEALTH_SURVEY": 1,
                    "INSURANCE_PLAN": 0,
                    "VACCINATION_FLAG": 1,
                    "LANDINGFEES_FLAG": 1
                }
            ]
            setOverallInsurancePlanFlag(0);
        }
        setSelectedTrip(selectedId);
        setTripMembers(tempTripMembers);
    }


    return (
        <div className="travel-checklist-container">
            <div className="travel-checklist-header-section">
                <p className='travel-checklist-header'>TRAVEL CHECKLIST</p>
            </div>
            <div className="travel-checklist-data-container">
                <div className="travel-checklist-content">
                    <div className="checklist-column">
                        <h2 className="checklist-header">Important Note:</h2>
                        <p className="checklist-note">All the items in the list below must be completed in order to get access to the Government Approved QR Code for entry.</p>
                        <p className="checklist-note">You are able to add all family members within your travel party to your App. All non-family members within your travel party will have to download their own App</p>
                        <div className="mui-form-control">
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="select-trip">Trip</InputLabel>
                                <Select
                                    id="select-trip"
                                    value={selectedTrip}
                                    onChange={(e) => { onTripSelection(e.target.value) }}
                                    label="Select Trip"
                                    readOnly={GLOBAL_DATA.SELECTED_TRIP ? true : false}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="1">Las Vegas (03/12/2022 - 03/22/2022)</MenuItem>
                                    <MenuItem value="2">New York (04/05/2022 - 04/24/2022)</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="checklist-column">
                        <div className="checklist-items">
                            <div className="each-checklist-item" onClick={() => { updateCollapsibleContent(0, !collapsibleContent[0]) }}>
                                <div className="img-holder">
                                    {overallImmigrationFlag === 0 ? <img src="/assets/icons/checklist-item-cross.png" className="cross-icon" alt="Tick" />
                                        : <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />}
                                </div>
                                <p>Completed Immigration Cards for all members in your travel party</p>
                            </div>
                            {
                                collapsibleContent[0] ?
                                    <>
                                        {tripMembers.map(
                                            (val, index) => {
                                                return (
                                                    <div className="each-checklist-item" key={index} onClick={() => { redirectToComp(val, '/dashboard/travel-documents') }}>
                                                        <div className="img-holder">
                                                            {val["IMMIGRATION_FLAG"] === 0 ? <img src="/assets/icons/checklist-item-cross.png" className="cross-icon" alt="Tick" />
                                                                : <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />}
                                                        </div>
                                                        <p className="member-name">{val.FULL_NAME}</p>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </>
                                    :
                                    <></>
                            }

                        </div>
                        <div className="checklist-items">
                            <div className="each-checklist-item" onClick={() => { updateCollapsibleContent(1, !collapsibleContent[1]) }}>
                                <div className="img-holder">
                                    {overallHealthSurveyFlag === 0 ? <img src="/assets/icons/checklist-item-cross.png" className="cross-icon" alt="Tick" />
                                        : <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />}
                                </div>
                                <p>Completed Customs Card for all members in your travel party</p>
                            </div>
                            {
                                collapsibleContent[1] ?
                                    <>
                                        {tripMembers.map(
                                            (val, index) => {
                                                return (
                                                    <div className="each-checklist-item" key={index} onClick={() => { redirectToComp(val, '/dashboard/health-documents') }}>
                                                        <div className="img-holder">
                                                            {val["HEALTH_SURVEY"] === 0 ? <img src="/assets/icons/checklist-item-cross.png" className="cross-icon" alt="Tick" />
                                                                : <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />}
                                                        </div>
                                                        <p className="member-name">{val.FULL_NAME}</p>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </>
                                    :
                                    <></>
                            }

                        </div>
                        <div className="checklist-items">
                            <div className="each-checklist-item" onClick={() => { updateCollapsibleContent(2, !collapsibleContent[2]) }}>
                                <div className="img-holder">
                                    {overallInsurancePlanFlag === 0 ? <img src="/assets/icons/checklist-item-cross.png" className="cross-icon" alt="Tick" />
                                        : <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />}
                                </div>
                                <p>Selected insurance plan for all members in your travel party</p>
                            </div>
                            {
                                collapsibleContent[2] ?
                                    <>
                                        {tripMembers.map(
                                            (val, index) => {
                                                return (
                                                    <div className="each-checklist-item" key={index} onClick={() => { redirectToComp(val, '/dashboard/travel-insurance') }}>
                                                        <div className="img-holder">
                                                            {val["INSURANCE_PLAN"] === 0 ? <img src="/assets/icons/checklist-item-cross.png" className="cross-icon" alt="Tick" />
                                                                : <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />}
                                                        </div>
                                                        <p className="member-name">{val.FULL_NAME}</p>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                        <div className="checklist-items">
                            <div className="each-checklist-item" onClick={() => { updateCollapsibleContent(3, !collapsibleContent[3]) }}>
                                <div className="img-holder">
                                    {overallVaccinationFlag === 0 ? <img src="/assets/icons/checklist-item-cross.png" className="cross-icon" alt="Tick" />
                                        : <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />}
                                </div>
                                <p>Uploaded proof of vaccination/PCR Test for all members in your travel party</p>
                            </div>
                            {
                                collapsibleContent[3] ?
                                    <>
                                        {tripMembers.map(
                                            (val, index) => {
                                                return (
                                                    <div className="each-checklist-item" key={index} onClick={() => { redirectToComp(val, '/dashboard/health-documents') }}>
                                                        <div className="img-holder">
                                                            {val["VACCINATION_FLAG"] === 0 ? <img src="/assets/icons/checklist-item-cross.png" className="cross-icon" alt="Tick" />
                                                                : <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />}
                                                        </div>
                                                        <p className="member-name">{val.FULL_NAME}</p>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                        <div className="checklist-items">
                            <div className="each-checklist-item" onClick={() => { redirectToComp(null, '/dashboard/shopping-cart') }}>
                                <div className="img-holder">
                                    {overallLandingFeeFlag === 0 ? <img src="/assets/icons/checklist-item-cross.png" className="cross-icon" alt="Tick" />
                                        : <img src="/assets/icons/checklist-item-tick.png" alt="Tick" />}
                                </div>
                                <p>Paid landing fees for all members in your travel party</p>
                            </div>
                        </div>
                        {overallLandingFeeFlag === 1 ?
                            <div className="right-align">
                                <button className="next-btn default-btn" onClick={() => { }}>NEXT</button>
                            </div>
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TravelChecklist;